import { VictoryTheme } from 'victory';
import { VictoryThemeDefinition } from 'victory-core/lib/index';

const baseText = {
  fontFamily: "'Ubuntu', 'Comfortaa'",
  fontSize: 14,
};

const theme: VictoryThemeDefinition = {
  ...VictoryTheme.grayscale,
  bar: {
    ...VictoryTheme.grayscale.bar,
    style: {
      ...VictoryTheme.grayscale.bar!.style,
      labels: {
        ...VictoryTheme.grayscale.bar!.style!.labels,
        fontFamily: "'Ubuntu-Bold', 'Comfortaa'",
        fontSize: 16,
      },
    },
  },
  axis: {
    ...VictoryTheme.grayscale.axis,
    style: {
      ...VictoryTheme.grayscale.axis!.style,
      tickLabels: {
        ...VictoryTheme.grayscale.axis!.style!.tickLabels,
        ...baseText,
      },
    },
  },
  tooltip: {
    ...VictoryTheme.grayscale.tooltip,
    style: {
      ...VictoryTheme.grayscale.tooltip!.style,
      ...baseText,
    },
  },
};

export default theme;
