import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Header, BottomHeader, Search, Sorts } from '@flexboxapps/flbx-webapp-ui';
import { RootState } from 'reducers/rootReducer';
import { Location } from 'reducers/locations/initialState';
import { logout } from 'actions/userActions';
import { getLocations, setCreateLocationDrawer } from 'actions/locationActions';
import { history } from 'services/config';
import LocationCard from 'components/LocationCard/LocationCard';
import { showNotImplementedAlert } from 'components/NotImplemented/showNotImplementedAlert';
import AddLocationDrawer from 'components/Location/AddLocationDrawer';
import { ReactComponent as LocationsIcon } from 'assets/locations.svg';
import './location-page.scss';

const LocationPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState<string>('');
  const [sortKey, setKey] = useState<string>('createdAt');
  const [sortValue, setValue] = useState<Sorts | null | undefined>(Sorts.asc);

  useEffect(() => {
    dispatch(getLocations(sortValue, sortKey, searchValue));
  }, []);

  const stringSort = (value?: Sorts | null): void => {
    setKey('title');
    setValue(value);
    dispatch(getLocations(value, 'title', searchValue));
  };

  const dateSort = (value?: Sorts | null): void => {
    setKey('createdAt');
    setValue(value);
    dispatch(getLocations(value, 'createdAt', searchValue));
  };

  const { email } = useSelector(({ user }: RootState) => user);
  const { locations } = useSelector(({ location }: RootState) => location);
  const usermenu = [{ label: t('app.common.buttons.logout'), action: () => dispatch(logout()) }];
  return (
    <div className="page page-location">
      <Header
        userinfo={email || ''}
        onHamburgerMenuClick={showNotImplementedAlert}
        onLogoClick={() => { history.push('/dashboard'); }}
        usermenu={usermenu}
        large
      />
      <div className="location-page-body">
        <AddLocationDrawer />
        <BottomHeader
          title={t('pages.locations.title').toUpperCase()}
          subtitle={t('pages.locations.subtitle')}
          buttonTitle={t('pages.locations.buttonTitle')}
          breadcrumbs={[{ title: 'Dashboard', link: '/dashboard' }]}
          onClick={() => { dispatch(setCreateLocationDrawer(true)); }}
          icon={<LocationsIcon />}
        />
        <div className="locations-search-container">
          <Search
            onStringSort={stringSort}
            onDateSort={dateSort}
            defaultString={null}
            defaultDate={Sorts.asc}
            onSearchTermBlur={() => { }}
            onSearchTermChange={(value) => { setSearchValue(value); dispatch(getLocations(sortValue, sortKey, value)); }}
          />
          <div className="locations-container">
            {locations && locations.length ? locations.map((item: Location, index: number) => (
              <LocationCard
                location={item.title}
                onClick={showNotImplementedAlert}
                key={index.toString()}
              />
            )) : null}
            <div className="location-card-placeholder" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationPage;
