import {
  DiscountActionTypes,
  GET_DISCOUNT_S,
  SET_DISCOUNT_DRAWER,
  SET_DISCOUNT_INPUT,
  CREATE_DISCOUNT_R,
  CREATE_DISCOUNT_F,
  CREATE_DISCOUNT_S,
} from 'consts/discountActionTypes';
import { initialState, DiscountState } from './initialState';

const discountReducer = (state = initialState, action: DiscountActionTypes): DiscountState => {
  switch (action.type) {
    case GET_DISCOUNT_S:
      return {
        ...state,
        discounts: action.payload.discounts,
      };

    case SET_DISCOUNT_DRAWER:
      return {
        ...initialState,
        discounts: state.discounts,
        createDrawerIsVisible: action.payload.value,
      };

    case SET_DISCOUNT_INPUT: {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }

    case CREATE_DISCOUNT_R:
      return {
        ...state,
        creatingInProgress: true,
      };

    case CREATE_DISCOUNT_S:
      return {
        ...state,
        creatingInProgress: false,
        createDrawerIsVisible: false,
      };

    case CREATE_DISCOUNT_F:
      return {
        ...state,
        creatingInProgress: false,
      };

    default:
      return state;
  }
};

export default discountReducer;
