import {
  LocationActionTypes,
  GET_LOCATION_S,
  SET_LOCATION_DRAWER,
  SET_LOCATION_INPUT,
  CREATE_LOCATION_R,
  CREATE_LOCATION_S,
  CREATE_LOCATION_F,
} from 'consts/locationActionTypes';
import { initialState, LocationState } from './initialState';

const locationReducer = (state = initialState, action: LocationActionTypes): LocationState => {
  switch (action.type) {
    case GET_LOCATION_S: {
      const { locations } = action.payload;
      return {
        ...state,
        locations,
      };
    }

    case SET_LOCATION_DRAWER:
      return {
        ...initialState,
        locations: state.locations,
        createDrawerIsVisible: action.payload.value,
      };

    case SET_LOCATION_INPUT: {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }

    case CREATE_LOCATION_R:
      return {
        ...state,
        creatingInProgress: true,
      };

    case CREATE_LOCATION_S:
      return {
        ...state,
        creatingInProgress: false,
        createDrawerIsVisible: false,
      };

    case CREATE_LOCATION_F:
      return {
        ...state,
        creatingInProgress: false,
      };

    default:
      return state;
  }
};

export default locationReducer;
