export type TaxRate = {
  createdAt: string;
  deprecated: boolean;
  parent: null;
  root: null;
  title: string;
  updatedAt: string;
  value: number;
  __v: number;
  _id: string;
};

export type TaxRateState = {
  taxrates: TaxRate[],
  createDrawerIsVisible: boolean,
  creatingInProgress: boolean;
};

export const initialState: TaxRateState = {
  taxrates: [],
  createDrawerIsVisible: false,
  creatingInProgress: false,
};
