import {
  POS_CAT_LIST_S,
  POS_CAT_TOGGLE_CREATE_MODAL,
  POS_CAT_TOGGLE_EDIT_MODAL,
  POS_CAT_SELECT_CATEGORY,
  POS_CAT_EDIT_CATEGORY,
  POS_CAT_EDIT_NEW_CATEGORY,
  POS_CAT_UPDATE_CATEGORY_R,
  POS_CAT_UPDATE_CATEGORY_S,
  POS_CAT_UPDATE_CATEGORY_F,
  POS_CAT_CREATE_CATEGORY_R,
  POS_CAT_CREATE_CATEGORY_S,
  POS_CAT_CREATE_CATEGORY_F,
  CategoryActionTypes,
} from 'consts/productCategoriesActionTypes';
import initialState, { ProductCategoryStateType } from './initialState';

const productCategoriesReducer = (state = initialState, action: CategoryActionTypes): ProductCategoryStateType => {
  switch (action.type) {
    case POS_CAT_LIST_S: {
      const { data } = action.payload;
      const categoryObj: any = {};
      data.forEach((category: any) => {
        categoryObj[category._id] = category;
      });

      return {
        ...state,
        data: {
          ...state.data,
          ...categoryObj,
        },
        editableData: {
          ...state.data,
          ...categoryObj,
        },
      };
    }

    case POS_CAT_TOGGLE_CREATE_MODAL: {
      const { createDrawerIsVisible } = action.payload;
      return {
        ...state,
        createDrawerIsVisible,
      };
    }

    case POS_CAT_TOGGLE_EDIT_MODAL: {
      const { editorDrawerIsVisible } = action.payload;
      return {
        ...state,
        editorDrawerIsVisible,
      };
    }

    case POS_CAT_SELECT_CATEGORY: {
      const { key } = action.payload;
      const selectedCategory = key ? state.data[key] : null;

      return {
        ...state,
        selectedCategory,
        newCategory: {
          ...state.newCategory,
          parent: selectedCategory ? selectedCategory._id : null,
        },
      };
    }

    case POS_CAT_EDIT_CATEGORY: {
      const { key, field, value } = action.payload;

      return {
        ...state,
        editableData: {
          ...state.editableData,
          [key]: {
            ...state.editableData[key],
            [field]: value,
          },
        },
      };
    }

    case POS_CAT_EDIT_NEW_CATEGORY: {
      const { field, value } = action.payload;

      return {
        ...state,
        newCategory: {
          ...state.newCategory,
          [field]: value,
        },
      };
    }

    case POS_CAT_UPDATE_CATEGORY_R:
      return {
        ...state,
        editingInProgress: true,
      };

    case POS_CAT_UPDATE_CATEGORY_S: {
      const { key, data } = action.payload;

      return {
        ...state,
        editingInProgress: false,
        editorDrawerIsVisible: false,
        data: {
          ...state.data,
          [key]: {
            ...state.data[key],
            ...data,
          },
        },
        selectedCategory: data,
        editableData: {
          ...state.editableData,
          [key]: {
            ...state.editableData[key],
            ...data,
          },
        },
      };
    }

    case POS_CAT_UPDATE_CATEGORY_F: {
      return {
        ...state,
        editingInProgress: false,
      };
    }

    case POS_CAT_CREATE_CATEGORY_R:
      return {
        ...state,
        creatingInProgress: true,
        newCategory: {
          ...state.newCategory,
        },
      };

    case POS_CAT_CREATE_CATEGORY_S:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.newCategoryItem._id]: {
            ...action.payload.newCategoryItem,
          },
        },
        editableData: {
          ...state.editableData,
          [action.payload.newCategoryItem._id]: {
            ...action.payload.newCategoryItem,
          },
        },
        createDrawerIsVisible: false,
        creatingInProgress: false,
        newCategory: {
          ...initialState.newCategory,
          parent: state.newCategory.parent,
        },
      };

    case POS_CAT_CREATE_CATEGORY_F:
      return {
        ...state,
        creatingInProgress: false,
      };

    default:
      return state;
  }
};

export default productCategoriesReducer;
