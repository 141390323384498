import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select, TreeSelect } from '@flexboxapps/flbx-webapp-ui';
import './category-editor.scss';

const colors = [
  { name: 'green', color: '#27AE60' },
  { name: 'blue', color: '#2980B9' },
  { name: 'purple', color: '#8E44AD' },
  { name: 'yellow', color: '#F1C40F' },
  { name: 'orange', color: '#F39C12' },
  { name: 'red', color: '#E74C3C' },
];

type CategoryEditorProps = {
  titles: { hu: string, en: string },
  categoryTree: any[],
  selectedParent: any,
  color: string,
  updateCategory: (key: string, value: any) => void,
};

const CategoryEditor: FC<CategoryEditorProps> = ({
  titles,
  categoryTree,
  selectedParent = null,
  color,
  updateCategory,
}) => {
  const { t } = useTranslation();
  return (
    <div className="drawer-inner-container">
      <div className="category-editor drawer-input-container">
        <Input
          label={t('pages.categories.labels.titles.hu').toUpperCase()}
          placeholder={t('pages.categories.inputs.titles.hu')}
          value={titles.hu}
          onChange={(e) => updateCategory('titles', {
            ...titles,
            hu: e.target.value,
          })}
        />
        <Select
          label={t('pages.categories.inputs.colorCode')}
          value={color}
          onChange={(value) => updateCategory('color', value)}
          options={colors.map((option) => ({
            key: option.name,
            value: option.color,
            label: t(`app.common.colors.${option.name}`).toUpperCase(),
          }))}
          size="large"
        />
        <TreeSelect
          label={t('pages.categories.inputs.parentCategory')}
          value={selectedParent || 'root'}
          treeData={[{
            title: t('drawers.addProduct.mainCategory'),
            value: 'root',
            children: categoryTree,
          }]}
          onChange={(value) => updateCategory('parent', value)}
          size="large"
        />
      </div>
    </div>
  );
};

export default CategoryEditor;
