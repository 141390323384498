import LoginPage from 'routes/LoginPage/LoginPage';
import DashboardPage from 'routes/DashboardPage/DashboardPage';
import CategoriesPage from 'routes/CategoriesPage/CategoriesPage';
import SalesPage from 'routes/SalesPage/SalesPage';
import LocationPage from 'routes/LocationPage/LocationPage';
import DiscountPage from 'routes/DiscountPage/DiscountPage';
import NewProductPage from 'routes/NewProductPage/NewProductPage';
import EditProductPage from 'routes/EditProductPage/EditProductPage';
import RestorePasswordPage from 'routes/RestorePasswordPage/RestorePasswordPage';

const Routes = {
  LoginPage,
  CategoriesPage,
  DashboardPage,
  SalesPage,
  LocationPage,
  DiscountPage,
  NewProductPage,
  EditProductPage,
  RestorePasswordPage,
};

export default Routes;
