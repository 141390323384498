import React, { FC, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { history } from 'services/config';
import { DatePicker, Header, Select, BreadCrumbNavigator } from '@flexboxapps/flbx-webapp-ui';
import { RootState } from 'reducers/rootReducer';
import { showNotImplementedAlert } from 'components/NotImplemented/showNotImplementedAlert';
import SalesCarousel from 'components/SalesCarousel/SalesCarousel';
import SalesTableContainer from 'components/SalesTableContainer/SalesTableContainer';
import { logout } from 'actions/userActions';
import { setFilters } from 'actions/salesActions';
import './sales-page.scss';
import 'react-multi-carousel/lib/styles.css';

const DailySales: FC = ({ location }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.search) {
      const selectedSalePoint = location.search.split('?salePoint=')[1];
      dispatch(setFilters({
        selectedSalePoint,
      }));
    } else {
      dispatch(setFilters({
        selectedSalePoint: null,
      }));
    }
  }, [location]);
  const { email } = useSelector(({ user }: RootState) => user);
  const { filters, isFetchingDailyTrade, salePoints } = useSelector(({ sales }: RootState) => sales);
  const usermenu = [{ label: t('app.common.buttons.logout'), action: () => dispatch(logout()) }];
  const isCarousel = !location.search;
  const availableSalePoints = salePoints && salePoints.length ? salePoints.map((item: any) => ({
    label: item.title,
    value: item._id,
  })).concat({ label: 'Minden helyszín', value: null }) : [];
  return (
    <div className="page page-product">
      <Header
        userinfo={email || ''}
        onHamburgerMenuClick={showNotImplementedAlert}
        onLogoClick={() => { history.push('/dashboard'); }}
        usermenu={usermenu}
      />
      <div className="sales-header-container">
        <div className="sales-header-inner">
          <BreadCrumbNavigator
            breadcrumbs={[{ title: 'Dashboard', link: '/dashboard' }]}
          />
          <div className="sales-header-title">
            {t('pages.sales.headerTitle').toUpperCase()}
          </div>
        </div>
      </div>
      <div className="sales-search-container">
        <div className="sales-search-inner">
          <Select
            options={availableSalePoints}
            value={filters.selectedSalePoint}
            onChange={(item) => {
              if (item) {
                history.push(`/sales/daily?salePoint=${item}`);
              } else {
                history.push('/sales/daily');
              }
            }}
          />
          <DatePicker
            value={moment(filters.selectedDate)}
            disabledDate={(current) => (current > moment().endOf('day'))}
            onChange={(date) => {
              dispatch(setFilters({
                selectedDate: date ? `${date.format('YYYY-MM-DD')}T00:00:00.0Z` : null,
              }));
            }}
          />
        </div>
      </div>
      <div className="sales-page-body">
        {isCarousel && !isFetchingDailyTrade && <SalesCarousel />}
        <SalesTableContainer />
      </div>
    </div>
  );
};

export default DailySales;
