export type Discount = {
  createdAt: string;
  deprecated: boolean;
  parent: null;
  root: null;
  title: string;
  updatedAt: string;
  userId: string;
  value: number;
  __v: number;
  _id: string;
};

export type DiscountState = {
  discounts: Discount[],
  createDrawerIsVisible: boolean,
  title: string;
  value: number;
  checked: boolean;
  creatingInProgress: boolean;
};

export const initialState: DiscountState = {
  discounts: [],
  createDrawerIsVisible: false,
  title: '',
  value: 0,
  creatingInProgress: false,
  checked: true,
};
