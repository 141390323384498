import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'services/config';
import { Header, BreadCrumbNavigator, Button } from '@flexboxapps/flbx-webapp-ui';
import { RootState } from 'reducers/rootReducer';
import { logout } from 'actions/userActions';
import { getProductCategories } from 'actions/productCategoriesActions';
import { listWhProducts } from 'actions/whProductsActions';
import {
  findPosProducts,
  editProductField,
  setProductUploadDrawer,
  updateEditedProduct,
  getPosProduct,
} from 'actions/posProductsActions';
import { getTaxrates } from 'actions/taxrateActions';
import { getSalePoints } from 'actions/salesActions';
import { showNotImplementedAlert } from 'components/NotImplemented/showNotImplementedAlert';
import { assortProductCategory } from 'services/services';
import UploadProductPictureDrawer from 'components/Product/UploadProductPictureDrawer/UploadProductPictureDrawer';
import ProductDetailsBlock from 'components/Product/Details/ProductDetails';
import TaxValueBlock from 'components/TaxValueBlock/TaxValueBlock';
import AssignWarehouseProductBlock from 'components/AssignWarehouseProductBlock/AssignWarehouseProductBlock';
import './edit-product.scss';
import Spinner from 'components/Spinner/Spinner';

export interface EditProductPageProps {
  match?: {
    params: {
      id: string;
    };
  };
}

const EditProductPage: FC<EditProductPageProps> = (props) => {
  const editedProductId = props.match?.params.id;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useSelector(({ user }: RootState) => user);
  const posProductsStore = useSelector(({ posProducts }: RootState) => posProducts);
  const { data: productCategoriesStore } = useSelector(({ productCategories }: RootState) => productCategories);
  const { lang } = useSelector(({ app }: RootState) => app);
  const { taxrates } = useSelector(({ taxrate }: RootState) => taxrate);
  const { salePoints } = useSelector(({ sales }: RootState) => sales);
  const { productOptions, isFetching: isFetchingWhProducts } = useSelector(({ whProducts }: RootState) => whProducts);

  useEffect(() => {
    dispatch(getProductCategories());
    dispatch(findPosProducts());
    dispatch(getTaxrates());
    dispatch(getSalePoints());
    dispatch(listWhProducts());
  }, []);

  useEffect(() => {
    if (editedProductId) {
      dispatch(getPosProduct(editedProductId));
    }
  }, [editedProductId]);

  const usermenu = [{ label: t('app.common.buttons.logout'), action: () => dispatch(logout()) }];

  const renderCategoryChildren = (categoryLevels: any, categoryParents: any) => {
    const items: any[] = [];
    const titleLanguage = lang === 'en-US' ? 'en' : 'hu';
    const fallbackTitleLanguage = lang === 'en-US' ? 'hu' : 'en';

    Object.keys(categoryLevels).forEach((categoryId) => {
      const childItem: any = {
        title: categoryLevels[categoryId].titles[titleLanguage] || categoryLevels[categoryId].titles[fallbackTitleLanguage],
        key: categoryId,
        value: categoryId,
      };

      if (categoryParents[categoryId]) {
        childItem.children = renderCategoryChildren(categoryParents[categoryId], categoryParents);
      }

      items.push(childItem);
    });

    return items;
  };

  const { categoryLevels, categoryParents } = assortProductCategory(productCategoriesStore);
  const categoryTree = categoryLevels[0] ? renderCategoryChildren(categoryLevels[0], categoryParents) : [];

  return (
    <div className="page page-product">
      <Header
        userinfo={email || ''}
        onHamburgerMenuClick={showNotImplementedAlert}
        onLogoClick={() => { history.push('/dashboard'); }}
        usermenu={usermenu}
      />
      <div className="sales-header-container">
        <div className="sales-header-inner">
          <BreadCrumbNavigator
            breadcrumbs={[
              { title: 'Dashboard', link: '/dashboard' },
              { title: t('pages.categories.title'), link: '/categories' },
            ]}
          />
          <div className="sales-header-title">
            {t('pages.editedProduct.title')}
          </div>
        </div>
      </div>
      <div className="product-page-body">
        {
          !salePoints || !taxrates.length || !posProductsStore.selectedProductForEdit ? (
            <Spinner />
          ) : (
            <>
              <UploadProductPictureDrawer drawerType="selectedProductForEdit" />

              <ProductDetailsBlock
                product={posProductsStore.selectedProductForEdit}
                categoryTree={categoryTree}
                editProductField={(field, value) => {
                  dispatch(editProductField('selectedProductForEdit', field, value));
                }}
                openUploadModal={() => dispatch(setProductUploadDrawer(true))}
              />

              <TaxValueBlock
                taxValueExceptions={posProductsStore.selectedProductForEdit.taxValueExceptions}
                takeaway={posProductsStore.selectedProductForEdit.takeaway}
                dinein={posProductsStore.selectedProductForEdit.dinein}
                editProductField={(field, value) => {
                  dispatch(editProductField('selectedProductForEdit', field, value));
                }}
                openUploadModal={() => dispatch(setProductUploadDrawer(true))}
              />

              {
                !isFetchingWhProducts
                && (
                  <AssignWarehouseProductBlock
                    productOptions={productOptions}
                    editProductField={(field, value) => {
                      dispatch(editProductField('selectedProductForEdit', field, value));
                    }}
                    ingredients={posProductsStore.selectedProductForEdit.ingredients}
                  />
                )
              }
              <Button
                label={t('pages.editedProduct.title')}
                type="ghost"
                disabled={
                  (posProductsStore.selectedProductForEdit.titles.hu.trim() === ''
                    && (posProductsStore.selectedProductForEdit.titles.en === null || posProductsStore.selectedProductForEdit.titles.en.trim() === ''))
                  || !posProductsStore.selectedProductForEdit.takeaway || !posProductsStore.selectedProductForEdit.dinein
                }
                onClick={() => dispatch(updateEditedProduct())}
              />
            </>
          )
        }
      </div>
    </div>
  );
};

export default EditProductPage;
