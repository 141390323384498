import React, { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import ROUTES from 'routes';
import AppRoute from 'components/App/AppRoute';
import PublicRoute from 'components/App/PublicRoute';
import LoginLayout from 'layouts/LoginLayout/LoginLayout';

const App: FC = () => (
  <Switch>
    <Redirect exact from="/" to="/dashboard" />
    <PublicRoute exact path="/login" component={ROUTES.LoginPage} layout={LoginLayout} />
    <AppRoute exact path="/dashboard" component={ROUTES.DashboardPage} />
    <AppRoute exact path="/categories" component={ROUTES.CategoriesPage} />
    <AppRoute exact path="/locations" component={ROUTES.LocationPage} />
    <AppRoute exact path="/discounts" component={ROUTES.DiscountPage} />
    <AppRoute exact path="/sales/daily" component={ROUTES.SalesPage} />
    <AppRoute exact path="/products/new" component={ROUTES.NewProductPage} />
    <AppRoute exact path="/products/:id/edit" component={ROUTES.EditProductPage} />
    <PublicRoute exact path="/restore-password" component={ROUTES.RestorePasswordPage} layout={LoginLayout} />
  </Switch>
);

export default App;
