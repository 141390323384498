export const USER_LOGIN_F = 'USER_LOGIN_F';
export interface UserLoginFailedType {
  type: typeof USER_LOGIN_F,
  payload: { errorMessage: string },
}

export const USER_LOGIN_R = 'USER_LOGIN_R';
export interface UserLoginRequestedType {
  type: typeof USER_LOGIN_R,
  payload: { email: string, password: string, next: string },
}

export const USER_LOGIN_S = 'USER_LOGIN_S';
export interface UserLoginSucceededType {
  type: typeof USER_LOGIN_S,
  payload: { email: string },
}

export const USER_LOGOUT_F = 'USER_LOGOUT_F';

export const USER_LOGOUT_R = 'USER_LOGOUT_R';
export interface UserLogoutRequestedType {
  type: typeof USER_LOGOUT_R,
}

export const USER_LOGOUT_S = 'USER_LOGOUTS';
export interface UserLogoutSucceededType {
  type: typeof USER_LOGOUT_S,
}

export const RESTORE_USER_BY_TOKEN_F = 'RESTORE_USER_BY_TOKEN_F';

export const RESTORE_USER_BY_TOKEN_R = 'RESTORE_USER_BY_TOKEN_R';
export interface RestoreUserByTokenRequestedType {
  type: typeof RESTORE_USER_BY_TOKEN_R,
  payload: { next?: string },
}

export const RESTORE_USER_BY_TOKEN_S = 'RESTORE_USER_BY_TOKEN_S';
export interface RestoreUserByTokenSucceededType {
  type: typeof RESTORE_USER_BY_TOKEN_S,
  payload: { email: string },
}

export const USER_FORGOT_PASSWORD_F = 'USER_FORGOT_PASSWORD_F';
export interface UserForgotPasswordFailedType {
  type: typeof USER_FORGOT_PASSWORD_F,
  payload: { errorMessage: string },
}

export const USER_FORGOT_PASSWORD_R = 'USER_FORGOT_PASSWORD_R';
export interface UserForgotPasswordRequestedType {
  type: typeof USER_FORGOT_PASSWORD_R,
  payload: { password: string, code: string, email: string },
}

export const USER_FORGOT_PASSWORD_S = 'USER_FORGOT_PASSWORD_S';
export interface UserForgotPasswordSucceededType {
  type: typeof USER_FORGOT_PASSWORD_S,
  payload: { email: string },
}

export const USER_SEND_EMAIL_F = 'USER_SEND_EMAIL_F';
export interface UserSendEmailFailedType {
  type: typeof USER_SEND_EMAIL_F,
  payload: { errorMessage: string },
}

export const USER_SEND_EMAIL_R = 'USER_SEND_EMAIL_R';
export interface UserSendEmailRequestedType {
  type: typeof USER_SEND_EMAIL_R,
  payload: { email: string },
}

export const USER_SEND_EMAIL_S = 'USER_SEND_EMAIL_S';
export interface UserSendEmailSucceededType {
  type: typeof USER_SEND_EMAIL_S,
}

export type UserActionTypes =
  | UserLoginRequestedType
  | UserLogoutRequestedType
  | RestoreUserByTokenRequestedType
  | UserLoginSucceededType
  | RestoreUserByTokenSucceededType
  | UserLoginFailedType
  | UserLogoutSucceededType
  | UserForgotPasswordFailedType
  | UserForgotPasswordRequestedType
  | UserForgotPasswordSucceededType
  | UserSendEmailFailedType
  | UserSendEmailRequestedType
  | UserSendEmailSucceededType;
