import { takeEvery, call, put } from 'redux-saga/effects';
import {
  LIST_WH_PRODUCTS_R, LIST_WH_PRODUCTS_S, LIST_WH_PRODUCTS_F,
} from 'consts/whProductActionTypes';
import * as API from 'services/api';

function* listWhProducts(): any {
  try {
    const query = {
      $params: JSON.stringify({
        deprecated: false,
      }),
      $sort: JSON.stringify({
        createdAt: -1,
      }),
    };
    const productOptions = yield call(API.findDataService, 'products', '/products', query);
    yield put({
      type: LIST_WH_PRODUCTS_S,
      payload: {
        productOptions,
      },
    });
  } catch (error) {
    yield put({
      type: LIST_WH_PRODUCTS_F,
    });
    console.log(error); // eslint-disable-line
  }
}

export const whProductsSagas = [
  takeEvery(LIST_WH_PRODUCTS_R, listWhProducts),
];
