import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Drawer, Button } from '@flexboxapps/flbx-webapp-ui';
import { setProductCreateDrawer, editProductField, createPosProduct, setProductUploadDrawer } from 'actions/posProductsActions';
import ProductEditor from 'components/Product/ProductEditForm/ProductEditForm';
import UploadProductPictureDrawer from 'components/Product/UploadProductPictureDrawer/UploadProductPictureDrawer';
import { RootState } from 'reducers/rootReducer';

type AddProductDrawerProps = {
  categoryTree: any[],
};

const AddProductDrawer: FC<AddProductDrawerProps> = ({ categoryTree }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const posProductsStore = useSelector(({ posProducts }: RootState) => posProducts);
  return (
    <Drawer
      className="app-drawer drawer-add-category"
      title={t('drawers.addProduct.createProduct')}
      onClose={() => dispatch(setProductCreateDrawer(false))}
      visible={posProductsStore.createDrawerIsVisible}
    >
      <div className="drawer-inner-container">
        <UploadProductPictureDrawer drawerType="newProduct" />
        <ProductEditor
          product={posProductsStore.newProduct}
          editProductField={(field: string, value: any) => {
            dispatch(editProductField('newProduct', field, value));
          }}
          categoryTree={categoryTree}
          openUploadModal={() => dispatch(setProductUploadDrawer(true))}
        />
      </div>
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={posProductsStore.creatingInProgress}
          onClick={() => dispatch(setProductCreateDrawer(false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.save')}
          type="primary"
          disabled={
            (posProductsStore.newProduct.titles.hu.trim() === ''
              && (posProductsStore.newProduct.titles.en === null || posProductsStore.newProduct.titles.en.trim() === ''))
            || !posProductsStore.newProduct.takeaway || !posProductsStore.newProduct.dinein
          }
          loading={posProductsStore.creatingInProgress}
          onClick={() => dispatch(createPosProduct())}
        />
      </div>
    </Drawer>
  );
};

export default AddProductDrawer;
