import React, { FC } from 'react';
import './column-layout-props.scss';

interface ColumnLayoutBlockProps {
  title?: string,
  children: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[],
}

const ColumnLayoutBlock: FC<ColumnLayoutBlockProps> = ({ title, children }) => (
  <div className="column-layout-block">
    {
      title
      && (
        <div className="column-layout-block-title">{title}</div>
      )
    }

    <div className="column-layout-block-body">{children}</div>
  </div>
);

export default ColumnLayoutBlock;
