import { takeEvery, call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import {
  SALES_LIST_R, SALES_LIST_S, SALES_LIST_F,
  SALES_LIST_MORE_R, SALES_LIST_MORE_S, SALES_LIST_MORE_F,
  SALES_QUERIES_DAILY_TRADE_R, SALES_QUERIES_DAILY_TRADE_S, SALES_QUERIES_DAILY_TRADE_F,
  SALES_GET_SALE_POINTS_R, SALES_GET_SALE_POINTS_S, SALES_GET_SALE_POINTS_F,
  SALES_SET_FILTERS, SalesSetFilters,
} from 'consts/salesActionTypes';
import * as API from 'services/api';
import { RootState } from 'reducers/rootReducer';

const getSalesStore = ({ sales }: RootState) => (sales);

function* findSales() {
  try {
    const { data } = yield call(API.findDataService, 'pos-products-sales', '/pos-products-sales');
    yield put({
      type: SALES_LIST_S,
      payload: {
        data,
      },
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({
      type: SALES_LIST_F,
    });
  }
}

function* findFilteresSales(action: SalesSetFilters): any {
  try {
    if (action.payload.filters.selectedDate === null) {
      throw new Error('Empty selected date');
    }

    const salesStore = yield select(getSalesStore);
    const filters = {
      ...salesStore.filters,
      ...action.payload.filters,
    };

    const queryParams: any = {
      createdAt: {
        $gte: filters.selectedDate,
        $lte: `${moment(filters.selectedDate).add(1, 'd').format('YYYY-MM-DD')}T00:00:00.0Z`,
      },
      deprecated: false,
    };

    if (salesStore.filters.selectedSalePoint !== null) {
      queryParams.salePoint = salesStore.filters.selectedSalePoint;
    }

    const query: any = {
      $populate: 'itemList.posProduct itemList.price itemList.discount',
      $params: JSON.stringify({
        ...queryParams,
      }),
      $sort: JSON.stringify({
        createdAt: -1,
      }),
    };

    const { data } = yield call(API.findDataService, 'pos-products-sales', '/pos-products-sales', query);
    yield put({
      type: SALES_LIST_S,
      payload: {
        data,
      },
    });

    try {
      yield put({
        type: SALES_QUERIES_DAILY_TRADE_R,
      });
      const dailyTradeQuery: any = {
        date: filters.selectedDate,
      };
      if (query.warehouse) {
        dailyTradeQuery.warehouse = query.warehouse;
      }
      const filter = {
        $populate: 'itemList.posProduct itemList.price',
        $params: JSON.stringify({
          ...queryParams,
        }),
      };

      const dailyTradeData = yield call(API.findDataService, 'pos-products-daily-trade', '/pos-products-daily-trade', filter);
      yield put({
        type: SALES_QUERIES_DAILY_TRADE_S,
        payload: {
          data: dailyTradeData.data,
        },
      });
    } catch (error) {
      console.log(error); // eslint-disable-line
      yield put({
        type: SALES_QUERIES_DAILY_TRADE_F,
        payload: {
          dailyTradeData: null,
        },
      });
    }
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({
      type: SALES_LIST_F,
    });
  }
}

function* findMoreSales(): any {
  try {
    const salesStore = yield select(getSalesStore);

    const query: any = {
      $limit: salesStore.limit,
      $skip: salesStore.skip + salesStore.limit,
      $populate: 'itemList.posProduct itemList.price itemList.discount',
      createdAt: {
        $gte: salesStore.filters.selectedDate,
        $lt: `${moment(salesStore.filters.selectedDate).add(1, 'd').format('YYYY-MM-DD')}T00:00:00.0Z`,
      },
      $sort: {
        createdAt: -1,
      },
      warehouse: salesStore.filters.selectedWarehouse,
    };

    Object.keys(query).forEach((key) => {
      if (query[key] === null) {
        delete query[key];
      }
    });

    const { data } = yield call(API.findDataService, 'pos-products-sales', '/pos-products-sales', query);
    yield put({
      type: SALES_LIST_MORE_S,
      payload: {
        data,
      },
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({
      type: SALES_LIST_MORE_F,
    });
  }
}

export function* getSalePoints() {
  try {
    const queryParams = {
      $populate: 'location',
      deprecated: false,
    };
    const { data: salePoints } = yield call(API.findDataService, 'sale-points', '/sale-points', queryParams);
    yield put({
      type: SALES_GET_SALE_POINTS_S,
      payload: {
        salePoints,
      },
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({
      type: SALES_GET_SALE_POINTS_F,
      payload: {
        error,
      },
    });
  }
}

export const salesSagas = [
  takeEvery(SALES_LIST_R, findSales),
  takeEvery(SALES_SET_FILTERS, findFilteresSales),
  takeEvery(SALES_LIST_MORE_R, findMoreSales),
  takeEvery(SALES_GET_SALE_POINTS_R, getSalePoints),
];
