import {
  SALES_GET_SALE_POINTS_R,
  SALES_GET_SALE_POINTS_S,
  SALES_GET_SALE_POINTS_F,
  SALES_SELECT_SALE_POINT,
  SALES_LIST_S,
  SALES_LIST_MORE_R,
  SALES_LIST_MORE_S,
  SALES_SET_FILTERS,
  SALES_QUERIES_DAILY_TRADE_R,
  SALES_QUERIES_DAILY_TRADE_S,
  SALES_QUERIES_DAILY_TRADE_F,
  SalesActionTypes,
} from 'consts/salesActionTypes';
import initialState, { SalesState } from './initialState';

const salesReducer = (state = initialState, action: SalesActionTypes): SalesState => {
  switch (action.type) {
    case SALES_GET_SALE_POINTS_R:
      return {
        ...state,
        isFetchingSalePoints: true,
      };

    case SALES_GET_SALE_POINTS_S: {
      const { salePoints } = action.payload;

      return {
        ...state,
        salePoints,
        isFetchingSalePoints: false,
      };
    }

    case SALES_GET_SALE_POINTS_F:
      return {
        ...state,
        isFetchingSalePoints: false,
      };

    case SALES_SELECT_SALE_POINT: {
      const { selectedSalePoint } = action.payload;
      const salePoint = state.salePoints.find((sp: { _id: string }) => (
        sp._id === selectedSalePoint
      ));

      return {
        ...state,
        selectedSalePoint: salePoint,
      };
    }

    case SALES_LIST_S: {
      const { data } = action.payload;
      return {
        ...state,
        data,
      };
    }

    case SALES_LIST_MORE_R:
      return {
        ...state,
        isFetchingMore: true,
      };

    case SALES_LIST_MORE_S: {
      const { data } = action.payload;
      return {
        ...state,
        data: [...state.data, ...data],
        isFetchingMore: false,
      };
    }

    case SALES_SET_FILTERS: {
      const { filters } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          ...filters,
        },
        isFetchingDailyTrade: true,
      };
    }

    case SALES_QUERIES_DAILY_TRADE_R:
      return {
        ...state,
        isFetchingDailyTrade: true,
      };

    case SALES_QUERIES_DAILY_TRADE_S:
      return {
        ...state,
        dailyTradeData: action.payload.data,
        isFetchingDailyTrade: false,
      };

    case SALES_QUERIES_DAILY_TRADE_F:
      return {
        ...state,
        dailyTradeData: {},
        isFetchingDailyTrade: false,
      };

    default:
      return state;
  }
};

export default salesReducer;
