import { all } from 'redux-saga/effects';
import { appSagas } from './appSaga';
import { productCategoriesSagas } from './productCategoriesSaga';
import { posProductsSagas } from './posProductsSaga';
import { salesSagas } from './salesSaga';
import { discountSagas } from './discountSaga';
import { locationSagas } from './locationSaga';
import { userSagas } from './userSaga';
import { taxrateSagas } from './taxrateSaga';
import { whProductsSagas } from './whProductsSaga';

export default function* rootSaga() {
  yield all([
    ...appSagas,
    ...productCategoriesSagas,
    ...posProductsSagas,
    ...salesSagas,
    ...discountSagas,
    ...locationSagas,
    ...userSagas,
    ...taxrateSagas,
    ...whProductsSagas,
  ]);
}
