import React from 'react';
import { notification } from 'antd';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { DragDrop } from '@uppy/react';
import ReactCrop from 'react-image-crop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCut, faCloudUploadAlt, faUndo, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import { createDataService } from 'services/api';
import { ActionButton } from '@flexboxapps/flbx-webapp-ui';
import { withTranslation } from 'react-i18next';
import '@uppy/core/dist/style.css';
import 'react-image-crop/lib/ReactCrop.scss';
import './product-picture-dnd.scss';

let uploadParameters = {
  key: '',
  url: '',
  uploadId: '',
};

const initialCrop = {
  unit: '%',
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  aspect: 1,
};

type Props = {
  setPictureId: (id: any, file: any) => void,
  closeModal: () => void,
  t: any,
  removeImage: boolean;
};

type State = {
  preview: string | null,
  crop: any,
  isUploading: boolean,
  file?: any,
  needsValidation: boolean,
};

const getImgDimension = async (imgFile: any): Promise<{ width: number, height: number }> => new Promise((resolve) => {
  const url = URL.createObjectURL(imgFile.data);
  const img = new Image();
  img.onload = () => {
    URL.revokeObjectURL(img.src);
    resolve({
      width: img.width,
      height: img.height,
    });
  };
  img.src = url;
});

class ProductPictureDND extends React.Component<Props, State> {
  uppy: any;

  imageRef: any;

  blob: any;

  constructor(props: any) {
    super(props);

    this.imageRef = null;
    this.state = {
      preview: null,
      crop: initialCrop,
      isUploading: false,
      needsValidation: true,
    };

    this.uppy = Uppy({
      autoProceed: false,
      restrictions: {
        maxFileSize: null,
      },
      onBeforeUpload: (files) => {
        const updatedFiles: any = {};
        Object.keys(files).forEach((fileID: any) => {
          updatedFiles[fileID] = {
            ...files[fileID],
            name: uploadParameters.key,
          };
        });
        return updatedFiles;
      },
    });

    this.uppy.use(ThumbnailGenerator, {
      id: 'ThumbnailGenerator',
      thumbnailHeight: 1400,
      thumbnailWidth: 1400,
      waitForThumbnailsBeforeUpload: false,
    });

    this.uppy.use(AwsS3, {
      getUploadParameters: (file: any) => (
        createDataService('uploads', '/uploads', { type: file.meta.type }).then((response) => {
          uploadParameters = response.data;
          return {
            method: 'PUT',
            url: uploadParameters.url,
            name: uploadParameters.key,
            headers: {
              'x-amz-acl': 'public-read',
              'Content-Type': file.meta.type,
            },
          };
        })
      ),
    });
  }

  componentDidMount() {
    const { t } = this.props;
    this.uppy.on('file-added', async (file: any) => {
      let validation = true;
      const imgDimensions = await getImgDimension(file);
      if (this.state.needsValidation && (imgDimensions.width < 400 || imgDimensions.height < 400)) {
        validation = false;
      }
      if (validation) {
        this.updateState({ file, needsValidation: false });
      } else {
        notification.error({
          message: t('drawers.addProduct.imageDimensionErrorMessage'),
          description: t('drawers.addProduct.imageDimensionErrorDescription'),
        });
        this.uppy.reset();
      }
    });

    this.uppy.on('thumbnail:generated', (file: any, preview: any) => {
      this.updateState({ preview });
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.removeImage !== this.props.removeImage) {
      this.removeThings();
    }
  }

  getBlobFile(image: any, crop: any, fileName: any) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blobFile) => {
        this.blob = blobFile;
        if (!this.blob) {
          reject(Error('Canvas is empty'));
        }
        this.blob.name = fileName;
        resolve(this.blob);
      }, 'image/jpeg');
    });
  }

  updateState = (state: any) => {
    this.setState(state);
  };

  setCrop = (crop: any) => {
    this.setState({ crop });
  };

  setImage = async () => {
    const { file, crop } = this.state;
    if (this.imageRef && crop.width && crop.height) {
      const data = await this.getBlobFile(
        this.imageRef,
        crop,
        'newFile.jpeg',
      );
      this.uppy.addFile({
        ...file,
        data,
      });
    }
  };

  dropLastImage = () => {
    const lastFile = this.uppy.getFiles().pop();
    this.uppy.removeFile(lastFile.id);
    const prevFile = this.uppy.getFiles().pop();
    const image = this.imageRef;
    image.src = prevFile.preview;
    this.onImageLoaded(image);
  };

  uploadImage = () => {
    this.setState({
      isUploading: true,
    });
    this.uppy
      .getFiles()
      .filter((value: any, index: number, total: any) => (index < total.length - 1))
      .forEach((file: any) => { this.uppy.removeFile(file.id); });

    const file = this.uppy.getFiles().pop();
    this.setState({
      file,
    });
    this.uppy.upload().then(() => {
      const { setPictureId } = this.props;
      setPictureId(uploadParameters.uploadId, file.preview);
    });
  };

  cancelUpload = () => {
    const { closeModal } = this.props;
    closeModal();
    this.uppy.reset();
    this.imageRef = null;
    this.setState({
      preview: null,
      crop: initialCrop,
      needsValidation: true,
    });
  };

  onImageLoaded = (image: any) => {
    this.imageRef = image;
    const isResizedImage = image.width === 400 && image.height === 400;
    this.setState((prevState) => ({
      ...prevState,
      crop: {
        ...prevState.crop,
        unit: 'px',
        x: image.height < image.width ? (image.width - image.height) / 2 : 0,
        y: image.width < image.height ? (image.height - image.width) / 2 : 0,
        width: isResizedImage ? image.width : 100,
        height: isResizedImage ? image.height : 100,
      },
    }));
    return false;
  };

  removeThings = () => {
    this.setState({
      preview: null,
      crop: initialCrop,
      isUploading: false,
    });
  };

  render() {
    const { preview, crop, isUploading } = this.state;
    const { t } = this.props;

    return (
      <div className="product-picture-dnd">
        <div className="image-editor">
          {
            !preview
            && (
              <DragDrop
                uppy={this.uppy}
                locale={{
                  strings: {
                    dropHereOr: t('drawers.addProduct.dropHere'),
                  },
                }}
              />
            )
          }
          {
            preview
            && (
              <>
                <div className="crop-workspace-container">
                  <ReactCrop
                    src={preview}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onChange={this.setCrop}
                  />
                </div>
                <div className="crop-actions-container">
                  <ActionButton
                    icon={<FontAwesomeIcon key="fa-cut" icon={faCut} color="#34495e" />}
                    label={t('app.common.buttons.cut')}
                    onClick={this.setImage}
                    rules={[
                      { rule: !this.imageRef, tooltip: 'Nincs kiválasztott kép' },
                      { rule: this.imageRef && (this.imageRef.width === crop.width && this.imageRef.height === crop.height), tooltip: 'Nincs vágandó terület' },
                      { rule: isUploading, tooltip: 'A feltöltés folyamatban van' },
                    ]}
                  />
                  <ActionButton
                    icon={<FontAwesomeIcon key="fa-undo" icon={faUndo} color="#f39c12" />}
                    label={t('app.common.buttons.reset')}
                    rules={[
                      { rule: this.uppy.getFiles().length <= 1, tooltip: 'Nincs visszavonható lépés' },
                      { rule: isUploading, tooltip: 'A feltöltés folyamatban van' },
                    ]}
                    onClick={this.dropLastImage}
                  />
                  <ActionButton
                    icon={<FontAwesomeIcon key="fa-trash-alt" icon={faTrashAlt} color="#e74c3c" />}
                    label={t('app.common.buttons.close')}
                    rules={[
                      { rule: isUploading, tooltip: 'A feltöltés folyamatban van' },
                    ]}
                    onClick={this.cancelUpload}
                  />
                  <ActionButton
                    icon={<FontAwesomeIcon key="fa-cloud-upload-alt" icon={faCloudUploadAlt} color="#3498db" />}
                    label={t('app.common.buttons.upload')}
                    rules={[
                      { rule: !this.imageRef, tooltip: 'Nincs kiválasztott kép' },
                      { rule: this.imageRef && (this.imageRef.width !== this.imageRef.height), tooltip: 'A képet négyzetesre kell vágni' },
                      { rule: isUploading, tooltip: 'A feltöltés folyamatban van' },
                    ]}
                    loading={isUploading}
                    onClick={() => {
                      this.uploadImage();
                    }}
                  />
                </div>
              </>
            )
          }
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProductPictureDND);
