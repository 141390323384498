import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import { Location } from 'reducers/locations/initialState';

export const GET_LOCATION_R = 'GET_LOCATION_R';
export interface LocationGetLocationRequested {
  type: typeof GET_LOCATION_R,
  payload: {
    value?: Sorts | null,
    key?: string,
    term?: string,
  },
}

export const GET_LOCATION_S = 'GET_LOCATION_S';
export interface LocationGetLocationSucceeded {
  type: typeof GET_LOCATION_S,
  payload: {
    locations: Location[],
  },
}

export const GET_LOCATION_F = 'GET_LOCATION_F';

export const SET_LOCATION_INPUT = 'SET_LOCATION_INPUT';

export interface LocationSetInput {
  type: typeof SET_LOCATION_INPUT,
  payload: {
    key: string,
    value?: string | number | boolean,
  },
}

export const SET_LOCATION_DRAWER = 'SET_LOCATION_DRAWER';

export interface LocationSetDrawer {
  type: typeof SET_LOCATION_DRAWER,
  payload: {
    value: boolean,
  },
}

export const CREATE_LOCATION_R = 'CREATE_LOCATION_R';
export interface LocationCreateLocationRequested {
  type: typeof CREATE_LOCATION_R,
}

export const CREATE_LOCATION_S = 'CREATE_LOCATION_S';
export interface LocationCreateLocationSucceeded {
  type: typeof CREATE_LOCATION_S,
}

export const CREATE_LOCATION_F = 'CREATE_LOCATION_F';
export interface LocationCreateLocationFailed {
  type: typeof CREATE_LOCATION_F,
}

// ACTIONTYPES
export type LocationActionTypes =
| LocationGetLocationRequested
| LocationGetLocationSucceeded
| LocationSetInput
| LocationSetDrawer
| LocationCreateLocationRequested
| LocationCreateLocationSucceeded
| LocationCreateLocationFailed;
