import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Drawer } from '@flexboxapps/flbx-webapp-ui';
import { setEditCategoryDrawer, editProductCategory, updateCategoryTitles } from 'actions/productCategoriesActions';
import { disableSelectedCategoryInTree } from 'services/services';
import CategoryEditor from 'components/Category/CategoryEditForm/CategoryEditForm';
import { RootState } from 'reducers/rootReducer';

type EditCategoryDrawerProps = {
  categoryTree: any[],
};

const EditCategoryDrawer: FC<EditCategoryDrawerProps> = ({ categoryTree }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productCategoriesStore = useSelector(({ productCategories }: RootState) => productCategories);

  return (
    <Drawer
      className="app-drawer drawer-add-category"
      title={productCategoriesStore.selectedCategory.titles.hu}
      onClose={() => dispatch(setEditCategoryDrawer(false))}
      visible={productCategoriesStore.editorDrawerIsVisible}
    >
      <CategoryEditor
        titles={productCategoriesStore.editableData[productCategoriesStore.selectedCategory._id].titles}
        updateCategory={(field, value) => {
          dispatch(editProductCategory(productCategoriesStore.selectedCategory._id, field, value));
        }}
        categoryTree={categoryTree.map((category) => (
          disableSelectedCategoryInTree(category, productCategoriesStore.selectedCategory._id)
        ))}
        selectedParent={productCategoriesStore.editableData[productCategoriesStore.selectedCategory._id].parent}
        color={productCategoriesStore.editableData[productCategoriesStore.selectedCategory._id].color}
      />
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={productCategoriesStore.editingInProgress}
          onClick={() => dispatch(setEditCategoryDrawer(false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.save')}
          type="primary"
          disabled={
            productCategoriesStore.editableData[productCategoriesStore.selectedCategory._id].titles.hu.trim() === ''
            && productCategoriesStore.editableData[productCategoriesStore.selectedCategory._id].titles.en.trim() === ''
          }
          loading={productCategoriesStore.editingInProgress}
          onClick={() => dispatch(updateCategoryTitles(productCategoriesStore.selectedCategory._id))}
        />
      </div>
    </Drawer>
  );
};

export default EditCategoryDrawer;
