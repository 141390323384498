export const SALES_GET_SALE_POINTS_R = 'SALES_GET_SALE_POINTS_R';
export interface SalesGetSalePointsRequested {
  type: typeof SALES_GET_SALE_POINTS_R,
}

export const SALES_GET_SALE_POINTS_S = 'SALES_GET_SALE_POINTS_S';
export interface SalesGetSalePointsSucceeded {
  type: typeof SALES_GET_SALE_POINTS_S,
  payload: { salePoints: any },
}

export const SALES_GET_SALE_POINTS_F = 'SALES_GET_SALE_POINTS_F';
export interface SalesGetSalePointsFailed {
  type: typeof SALES_GET_SALE_POINTS_F,
}

export const SALES_SELECT_SALE_POINT = 'SALES_SELECT_SALE_POINT';
export interface SalesSelectSalePoint {
  type: typeof SALES_SELECT_SALE_POINT,
  payload: { selectedSalePoint: any },
}

export const SALES_LIST_R = 'SALES_LIST_R';
export interface SalesListRequested {
  type: typeof SALES_LIST_R,
}

export const SALES_LIST_S = 'SALES_LIST_S';
export interface SalesListSucceeded {
  type: typeof SALES_LIST_S,
  payload: { data: any },
}

export const SALES_LIST_F = 'SALES_LIST_F';
export const SALES_SET_FILTERS = 'SALES_SET_FILTERS';
export interface SalesSetFilters {
  type: typeof SALES_SET_FILTERS,
  payload: { filters: any },
}

export const SALES_LIST_MORE_R = 'SALES_LIST_MORE_R';
export interface SalesListMoreRequested {
  type: typeof SALES_LIST_MORE_R,
}

export const SALES_LIST_MORE_S = 'SALES_LIST_MORE_S';
export interface SalesListMoreSucceeded {
  type: typeof SALES_LIST_MORE_S,
  payload: { data: any },
}

export const SALES_LIST_MORE_F = 'SALES_LIST_MORE_F';
export const SALES_QUERIES_DAILY_TRADE_R = 'SALES_QUERIES_DAILY_TRADE_R';
export interface SalesQueriesDailyTradeRequested {
  type: typeof SALES_QUERIES_DAILY_TRADE_R,
}

export const SALES_QUERIES_DAILY_TRADE_S = 'SALES_QUERIES_DAILY_TRADE_S';
export interface SalesQueriesDailyTradeSucceeded {
  type: typeof SALES_QUERIES_DAILY_TRADE_S,
  payload: { data: any },
}

export const SALES_QUERIES_DAILY_TRADE_F = 'SALES_QUERIES_DAILY_TRADE_F';
export interface SalesQueriesDailyTradeFailed {
  type: typeof SALES_QUERIES_DAILY_TRADE_F,
}

export type SalesActionTypes = SalesListRequested | SalesListMoreRequested
| SalesSetFilters | SalesGetSalePointsRequested
| SalesSelectSalePoint | SalesGetSalePointsSucceeded
| SalesGetSalePointsFailed | SalesListSucceeded
| SalesListMoreSucceeded | SalesQueriesDailyTradeRequested
| SalesQueriesDailyTradeSucceeded | SalesQueriesDailyTradeFailed;
