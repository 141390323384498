import { SelectValue } from 'antd/lib/select';
import {
  POS_PROD_LIST_R, POSProductListRequested,
  POS_PROD_CREATE_SET_FIELD, POSProductCreateSetField,
  POS_PROD_CREATE_RESET, POSProductCreateReset,
  POS_PROD_CREATE_SET_DATA_ROW, POSProductCreateSetDataRow,
  POS_PROD_CREATE_ADD_DATA_ROW, POSProductCreateAddDataRow,
  POS_PROD_CREATE_TRIM_TABLE, POSProductCreateTrimTable,
  POS_PROD_CREATE_REMOVE_DATA_ROW, POSProductCreateRemoveDataRow,
  POS_PROD_CREATE_IMPORT_BY_CREATE, POSProductCreateImportByCreate,
  POS_PROD_CREATE_IMPORT_BY_CLIPBOARD, POSProductCreateImportByClipBoard,
  POS_PROD_CREATE_SET_COLUMN_DEFINITIONS, POSProductCreateSetColumnDefinitions,
  POS_PROD_CREATE_SELECT_INGREDIENT, POSProductCreateSelectIngredient,
  POS_PROD_CREATE_SET_INGREDIENT, POSProductCreateSetIngredient,
  POS_PROD_CREATE_REMOVE_INGREDIENT, POSProductCreateRemoveIngredient,
  POS_PROD_CREATE_R, POSProductCreateRequested,
  POS_PROD_TOGGLE_PROFILE_MODAL, POSProductToggleProfileModal,
  POS_PROD_TOGGLE_CREATE_MODAL, POSProductToggleCreateModal,
  POS_PROD_TOGGLE_EDITOR_MODAL, POSProductToggleEditorModal,
  POS_PROD_TOGGLE_UPLOAD_MODAL, POSProductToggleUploadModal,
  POS_PROD_EDIT_FIELD, POSProductEditField,
  POS_PROD_UPDATE_R, POSProductUpdateRequested,
  POS_PRODUCT_REORDER_R, POSProductReorder,
  POSProductGetRequested, POS_PROD_GET_R,
} from 'consts/productActionTypes';

export type FindPosProductsType = (selectedCategory?: any | null, selectedProduct?: any | null) => POSProductListRequested;

export const findPosProducts: FindPosProductsType = (selectedCategory = null, selectedProduct = null) => ({
  type: POS_PROD_LIST_R,
  payload: {
    selectedCategory,
    selectedProduct,
  },
});

export const setField = (key: string, value: any): POSProductCreateSetField => ({
  type: POS_PROD_CREATE_SET_FIELD,
  payload: {
    key,
    value,
  },
});

export const reset = (): POSProductCreateReset => ({
  type: POS_PROD_CREATE_RESET,
});

export const setDataRow = (rowId: string, fieldName: string, fieldData: any): POSProductCreateSetDataRow => ({
  type: POS_PROD_CREATE_SET_DATA_ROW,
  payload: {
    rowId,
    fieldName,
    fieldData,
  },
});

export const trimTable = (): POSProductCreateTrimTable => ({
  type: POS_PROD_CREATE_TRIM_TABLE,
});

export const addDataRow = (): POSProductCreateAddDataRow => ({
  type: POS_PROD_CREATE_ADD_DATA_ROW,
});

export const removeDataRow = (rowId: string): POSProductCreateRemoveDataRow => ({
  type: POS_PROD_CREATE_REMOVE_DATA_ROW,
  payload: {
    rowId,
  },
});

export const importByCreate = (): POSProductCreateImportByCreate => ({
  type: POS_PROD_CREATE_IMPORT_BY_CREATE,
});

export const importByClipboard = (clipboardData: any): POSProductCreateImportByClipBoard => ({
  type: POS_PROD_CREATE_IMPORT_BY_CLIPBOARD,
  payload: {
    clipboardData,
  },
});

export const setColumnDefinitions = (titleIndex: string[] | number[], priceIndex: string[] | number[]): POSProductCreateSetColumnDefinitions => ({
  type: POS_PROD_CREATE_SET_COLUMN_DEFINITIONS,
  payload: {
    titleIndex,
    priceIndex,
  },
});

export const selectIngredient = (rowId: string, selectedOption: any): POSProductCreateSelectIngredient => ({
  type: POS_PROD_CREATE_SELECT_INGREDIENT,
  payload: {
    rowId,
    selectedOption,
  },
});

export const setIngredient = (rowId: string, ingredientId: string, fieldName: string, fieldData: any): POSProductCreateSetIngredient => ({
  type: POS_PROD_CREATE_SET_INGREDIENT,
  payload: {
    rowId,
    ingredientId,
    fieldName,
    fieldData,
  },
});

export const removeIngredient = (rowId: string, ingredientId: string): POSProductCreateRemoveIngredient => ({
  type: POS_PROD_CREATE_REMOVE_INGREDIENT,
  payload: {
    rowId,
    ingredientId,
  },
});

export const createPosProduct = (): POSProductCreateRequested => ({
  type: POS_PROD_CREATE_R,
});

export const setProductProfileDrawer = (profileDrawerIsVisible: boolean, selectedProduct = null): POSProductToggleProfileModal => ({
  type: POS_PROD_TOGGLE_PROFILE_MODAL,
  payload: {
    selectedProduct,
    profileDrawerIsVisible,
  },
});

export const setProductEditorDrawer = (editorDrawerIsVisible: boolean): POSProductToggleEditorModal => ({
  type: POS_PROD_TOGGLE_EDITOR_MODAL,
  payload: {
    editorDrawerIsVisible,
  },
});

export const setProductUploadDrawer = (uploadDrawerIsVisible: boolean): POSProductToggleUploadModal => ({
  type: POS_PROD_TOGGLE_UPLOAD_MODAL,
  payload: {
    uploadDrawerIsVisible,
  },
});

export const setProductCreateDrawer = (createDrawerIsVisible: boolean): POSProductToggleCreateModal => ({
  type: POS_PROD_TOGGLE_CREATE_MODAL,
  payload: {
    createDrawerIsVisible,
  },
});

export const editProductField = (
  drawerType: string,
  field: string,
  value: string | string[] | SelectValue | object | null | boolean,
): POSProductEditField => ({
  type: POS_PROD_EDIT_FIELD,
  payload: {
    drawerType,
    field,
    value,
  },
});

export const updateEditedProduct = (): POSProductUpdateRequested => ({
  type: POS_PROD_UPDATE_R,
});

export const productReorder = (order: string[], out: string | null): POSProductReorder => ({
  type: POS_PRODUCT_REORDER_R,
  payload: {
    order,
    out,
  },
});

export const getPosProduct = (id: string): POSProductGetRequested => ({
  type: POS_PROD_GET_R,
  payload: {
    id,
  },
});
