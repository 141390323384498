import { sortBy, find } from 'lodash';
import { history } from 'services/config';

export const jumpToPage = (location: string): void => {
  history.push(location);
};

export const numberWithCommas = (value?: number | string): string => (
  value !== undefined ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
);

export const mapObjectKeysByIndex = (properties: { [key: string]: { index: number } }) => (
  sortBy(
    Object.keys(properties).map((key) => ({
      key,
      index: properties[key].index,
    })),
    'index',
  ).map((item: { key: string }) => item.key)
);

export const assortProductCategory = (productCategories: any) => {
  const categoryLevels: any = {};
  const categoryParents: any = {};

  if (productCategories) {
    Object.keys(productCategories).forEach((key) => {
      if (categoryLevels[productCategories[key].level]) {
        categoryLevels[productCategories[key].level] = {
          ...categoryLevels[productCategories[key].level],
          [key]: {
            ...productCategories[key],
          },
        };
      } else {
        categoryLevels[productCategories[key].level] = {
          [key]: {
            ...productCategories[key],
          },
        };
      }

      if (productCategories[key].parent) {
        if (categoryParents[productCategories[key].parent]) {
          categoryParents[productCategories[key].parent] = {
            ...categoryParents[productCategories[key].parent],
            [key]: {
              ...productCategories[key],
            },
          };
        } else {
          categoryParents[productCategories[key].parent] = {
            [key]: {
              ...productCategories[key],
            },
          };
        }
      }
    });
  }

  return {
    categoryLevels,
    categoryParents,
  };
};

export const getTotalPrice = (itemList: any[]) => {
  let total = 0;
  itemList.forEach((posProduct) => {
    const dValue = posProduct.discount ? posProduct.discount.value : 0;
    total += (posProduct.price.currencies[0].value * posProduct.quantity * ((100 - dValue) / 100));
  });
  return total;
};

export const disableSelectedCategoryInTree = (treeItem: any, selectedCategory: any) => {
  if (treeItem.children === undefined) {
    return {
      ...treeItem,
      disabled: treeItem.value === selectedCategory,
    };
  }

  const disabled = treeItem.value === selectedCategory;
  const newItem = {
    ...treeItem,
    disabled,
    children: [],
  };

  if (!disabled && treeItem.children) {
    newItem.children = treeItem.children.map((item: any) => (
      disableSelectedCategoryInTree(item, selectedCategory)
    ));
  }

  return newItem;
};

export const createDailyTrade = (dailyTradeData: any, salePoints: any, selectedSalePoint: string) => {
  if (!dailyTradeData) {
    return {
      itemList: [],
      totalPrice: 0,
    };
  }

  const itemList: any[] = [];
  let totalPrice = 0;

  Object.keys(dailyTradeData).forEach((key) => {
    const subTotal = dailyTradeData[key].price;
    totalPrice += subTotal;
    itemList.push({
      id: key,
      productName: dailyTradeData[key].posProduct.titles.hu,
      quantity: dailyTradeData[key].quantity,
      unitPrice: numberWithCommas(dailyTradeData[key].price),
      subTotal,
    });
  });

  return {
    itemList,
    totalPrice,
    warehouse: selectedSalePoint ? find(salePoints, { _id: selectedSalePoint }) : null,
  };
};

export const validatePassword = (password: string) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{8,}$/;
  return !regex.test(String(password));
};

export const validateEmail = (email: string) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return !regex.test(String(email).toLowerCase());
};
