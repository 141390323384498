import React, { FC, useMemo } from 'react';
import Carousel from 'react-multi-carousel';
import { RootStateOrAny, useSelector } from 'react-redux';
import SalesCard from 'components/SalesCard/SalesCard';
import { useTranslation } from 'react-i18next';
import { Button } from '@flexboxapps/flbx-webapp-ui';
import { find } from 'lodash';
import moment from 'moment';
import { history } from 'services/config';
import { ReactComponent as RightArrow } from 'assets/right-arrow.svg';
import useResize from 'services/useWindowResize';
import { createDailyTrade, numberWithCommas } from 'services/services';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DailyTradePDF from 'components/Sales/DailyTradePDF/DailyTradePDF';
import './carousel.scss';

const SalesCarousel = () => {
  const { width } = useResize();
  const { t } = useTranslation();
  const { data, dailyTradeData, salePoints, filters, warehouses } = useSelector(({ sales }: RootStateOrAny) => sales);
  const responsive = {
    big: {
      breakpoint: { max: 4000, min: 768 },
      items: 2,
    },
    small: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  const CustomDot: FC<any> = ({ onClick, ...rest }) => {
    const {
      index,
      active,
    } = rest;
    if (!salePoints) return null;
    const carouselItems = Array.from({ length: salePoints.length }).map((_, arrayIndex) => (
      <div className="sales-dot-item" key={arrayIndex.toString()} />
    ));
    return (
      <button
        type="button"
        className={`sales-dot-container${active ? ' active' : ' inactive'}`}
        onClick={() => onClick()}
      >
        {React.Children.toArray(carouselItems)[index]}
      </button>
    );
  };

  const getSumData = (salesData: any, id: string) => {
    const quantities = salesData.filter((salesDataItem: any) => salesDataItem.salePoint === id).map((item: any) => item.itemList).flat()
      .map((itemListItem: any) => itemListItem.quantity);
    return salesData.filter((salesDataItem: any) => salesDataItem.salePoint === id)
      .map((item: any) => item.itemList).flat()
      .map((itemListItem: any) => itemListItem.price.currencies)
      .flat()
      .reduce((prev: number, b: any, index: number) => prev + b.value * quantities[index], 0);
  };

  const createdDailyTradeData = createDailyTrade(dailyTradeData, salePoints, filters.selectedSalePoint);
  const selectedWarehouseProfile = find(warehouses, { _id: filters.selectedWarehouse });

  return data && dailyTradeData && salePoints ? (
    <div className="daily-sales-carousel-container">
      <div className="daily-sales-title-container">
        <div className="daily-sales-sum">{t('pages.sales.sum').toUpperCase()}</div>
        <div className="daily-sales-money">
          {`${numberWithCommas(Object.values(dailyTradeData).reduce((a: number, b: any) => a + b.price, 0))} Ft`}
        </div>
      </div>
      <div className="daily-sales-purchase">
        {`${data.length} ${t('pages.sales.occasion')}`}
      </div>
      <Carousel
        showDots={width ? width < 768 : false}
        responsive={responsive}
        className="sales-card-container"
        infinite
        removeArrowOnDeviceType="small"
        minimumTouchDrag={70}
        renderDotsOutside
        customDot={<CustomDot />}
        customLeftArrow={(
          <div className="arrow-container left-arrow">
            <RightArrow />
          </div>
        )}
        customRightArrow={(
          <div className="arrow-container right-arrow">
            <RightArrow />
          </div>
        )}
      >
        {
          salePoints.map((item: any) => (
            <SalesCard
              key={item._id}
              title={item.title}
              occasion={data.filter((salesData: any) => salesData.salePoint === item._id).length}
              income={data ? getSumData(data, item._id) : 0}
              onClick={() => history.push(`/sales/daily?salePoint=${item._id}`)}
              currency="Ft"
            />
          ))
        }
      </Carousel>
      <div className="sales-carousel-button-container">
        <Button
          label={useMemo(() => (
            <PDFDownloadLink
              document={<DailyTradePDF {...createdDailyTradeData} />}
              fileName={
                selectedWarehouseProfile
                  ? `${moment(filters.selectedDate).format('YYYY-MM-DD')}_${selectedWarehouseProfile.title}.pdf`
                  : `${moment(filters.selectedDate).format('YYYY-MM-DD')}_${t('pages.sales.sellings')}.pdf`
              }
            >
              {({ loading }) => (loading ? t('pages.sales.loadingDocument').toUpperCase() : t('pages.sales.dailyTradeDownload').toUpperCase())}
            </PDFDownloadLink>
          ), [])}
          onClick={() => { }}
          loading={!createdDailyTradeData}
          type="primary"
        />
      </div>
    </div>
  ) : null;
};

export default SalesCarousel;
