import {
  WhProductActionTypes,
  LIST_WH_PRODUCTS_R,
  LIST_WH_PRODUCTS_S,
  LIST_WH_PRODUCTS_F,
} from 'consts/whProductActionTypes';
import { initialState, WhProductState } from './initialState';

const whProductsReducer = (state = initialState, action: WhProductActionTypes): WhProductState => {
  switch (action.type) {
    case LIST_WH_PRODUCTS_R: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case LIST_WH_PRODUCTS_S: {
      const { productOptions } = action.payload;
      return {
        ...state,
        isFetching: false,
        productOptions,
      };
    }

    case LIST_WH_PRODUCTS_F:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default whProductsReducer;
