export type PosProductStateType = {
  productCategories: any,
  profileDrawerIsVisible: boolean,
  editorDrawerIsVisible: boolean,
  createDrawerIsVisible: boolean,
  uploadDrawerIsVisible: boolean,
  isProcessing: boolean,
  selectedProduct: any | null,
  editedProduct: any | null,
  editingInProgress: boolean,
  creatingInProgress: boolean,
  [drawerType: string]: any,
  newProduct: {
    titles: {
      hu: string,
      en: string,
    },
    printTitles: {
      hu: string,
      en: string,
    },
    categories: any[],
    price: {
      HUF: number,
    },
    image: string | null,
    takeaway: string | undefined,
    dinein: string | undefined,
    taxValueExceptions: any[],
    ingredients: any,
    visible: boolean,
  },
};

const initialState: PosProductStateType = {
  productCategories: {},
  profileDrawerIsVisible: false,
  editorDrawerIsVisible: false,
  createDrawerIsVisible: false,
  uploadDrawerIsVisible: false,
  isProcessing: false,
  selectedProduct: null,
  editedProduct: null,
  editingInProgress: false,
  creatingInProgress: false,
  newProduct: {
    titles: {
      hu: '',
      en: '',
    },
    printTitles: {
      hu: '',
      en: '',
    },
    categories: ['root'],
    price: {
      HUF: 0,
    },
    takeaway: undefined,
    dinein: undefined,
    image: null,
    taxValueExceptions: [],
    ingredients: {},
    visible: true,
  },
};

export default initialState;
