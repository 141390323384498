export default {
  translation: {
    app: {
      common: {
        buttons: {
          logout: 'Sign out',
          back: 'Back',
          restart: 'Restart',
          reset: 'Reset',
          yes: 'Yes',
          no: 'No',
          add: 'Add',
          cancel: 'Cancel',
          close: 'Close',
          edit: 'Edit',
          create: 'Create',
          save: 'Save',
        },
        colors: {
          green: 'green',
          blue: 'blue',
          purple: 'purple',
          yellow: 'yellow',
          orange: 'orange',
          red: 'red',
        },
      },
      languages: {
        hu: 'Hungarian',
        en: 'English',
      },
    },
    pages: {
      update: {
        availableUpdate: 'New update is available!',
        updateApp: 'Update the app',
      },
      login: {
        title: 'Sign in to Flexbox',
        subtitle: 'Enter your details below.',
        inputs: {
          email: 'Email address',
          password: 'Password',
          login: 'Login',
        },
        errors: {
          badCredentials: 'Invalid login credentials',
          emptyEmail: 'Type your email here:',
          emptyPassword: 'Type your password here:',
        },
      },
      dashboard: {
        title: 'Welcome',
        next: 'Next',
        welcomeSubTitle: 'We\'re ready, let\'s begin!',
        infocards: {
          categories: 'Categories and products',
          sales: 'Daily sales',
        },
      },
      categories: {
        title: 'Categories \nand products',
        labels: {
          titles: {
            hu: 'hungarian title',
            en: 'title',
          },
        },
        inputs: {
          titles: {
            hu: 'title',
            en: 'title',
          },
          parentCategory: 'Parent category',
          colorCode: 'color code',
        },
      },
      products: {
        buttons: {
          addProductToCategory: 'add a product \nto this category',
        },
        labels: {
          titles: {
            hu: 'title',
            en: 'title',
          },
          printTitles: {
            hu: 'print title',
            en: 'print title',
          },
          mainCategories: 'Categories',
        },
        inputs: {
          titles: {
            hu: 'product\'s title',
            en: 'product\'s title',
          },
          printTitles: {
            hu: 'print title',
            en: 'print title',
          },
          selectedCategory: 'category',
          price: 'price',
        },
      },
      sales: {
        title: 'Sales',
        emptyPage: {
          title: 'There\'s nothing to display',
        },
      },
    },
    drawers: {
      addCategory: {
        title: 'Create a New Category',
        subtitle: 'Type the name of the category',
      },
    },
  },
};
