import moment from 'moment';

export type SalesState = {
  data: any,
  filters: {
    selectedDate: any,
    selectedWarehouse: any,
    selectedSalePoint: any,
  },
  isFetchingMore: boolean,
  isFetchingDailyTrade: boolean,
  dailyTradeData: any,
  isFetchingSalePoints: boolean,
  salePoints: any,
  selectedSalePoint: any,
};

const initialState: SalesState = {
  data: null,
  filters: {
    selectedDate: `${moment().format('YYYY-MM-DD')}T00:00:00.0Z`,
    selectedWarehouse: null,
    selectedSalePoint: null,
  },
  isFetchingMore: false,
  isFetchingDailyTrade: false,
  dailyTradeData: null,
  isFetchingSalePoints: false,
  salePoints: null,
  selectedSalePoint: null,
};

export default initialState;
