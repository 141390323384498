import React, { useEffect, useState, useRef, FC } from 'react';
import moment from 'moment';
import {
  VictoryAxis, VictoryBar, VictoryChart, VictoryLabel,
} from 'victory';
import customVictoryTheme from 'services/victoryChartTheme';
import { useTranslation } from 'react-i18next';
import './daily-sales-counts.scss';

type DailySalesCountsProps = {
  sales: any[],
};

const DailySalesCounts: FC<DailySalesCountsProps> = ({ sales = [] }) => {
  const { t } = useTranslation();
  const containerRef = useRef<any>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const updateContainerWidth = () => {
    if (containerRef.current.offsetWidth > 500) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);

    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  const hours: { [hour: string]: any } = {
    6: { count: 0 },
    7: { count: 0 },
    8: { count: 0 },
    9: { count: 0 },
    10: { count: 0 },
    11: { count: 0 },
    12: { count: 0 },
    13: { count: 0 },
    14: { count: 0 },
    15: { count: 0 },
    16: { count: 0 },
    17: { count: 0 },
    18: { count: 0 },
    19: { count: 0 },
    20: { count: 0 },
    21: { count: 0 },
  };

  if (sales && sales.length !== 0) {
    sales.forEach(({ createdAt }) => {
      const hour = moment(createdAt).format('H');
      hours[hour].count += 1;
    });
  }

  const data = Object.entries(hours).map(([key, { count }]) => ({ hour: key, count }));
  return (
    <div className="daily-sales-counts" ref={containerRef}>
      <div className="daily-sales-counts-title">{t('pages.sales.numberOfSalesPerHour')}</div>
      <VictoryChart
        domainPadding={10}
        height={300}
        width={containerWidth}
        padding={{ top: 30, right: 40, bottom: 60, left: 40 }}
        theme={customVictoryTheme}
        animate={{
          onLoad: { duration: 1000 },
          duration: 1000,
        }}
      >
        <VictoryAxis
          tickValues={Object.keys(hours)}
          label={t('pages.sales.hour')}
          animate={false}
        />
        <VictoryBar
          data={data.map((item) => ({ ...item, label: item.count }))}
          labelComponent={(
            <VictoryLabel
              text={({ datum }) => (datum.label === 0 ? '' : Math.round(datum.label))}
            />
          )}
          barWidth={25}
          x="hour"
          y="count"
          style={{ data: { fill: '#6645E0' } }}
        />
      </VictoryChart>
    </div>
  );
};

export default DailySalesCounts;
