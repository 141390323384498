export type ProductCategoryStateType = {
  data: any,
  editableData: any,
  editingInProgress: boolean,
  creatingInProgress: boolean,
  editorDrawerIsVisible: boolean,
  createDrawerIsVisible: boolean,
  newCategory: {
    titles: {
      hu: string,
      en: string,
    },
    parent: string,
    color: string,
  },
  units: null,
  selectedCategory: any | null,
};

const initialState: ProductCategoryStateType = {
  data: null,
  editableData: {},
  editingInProgress: false,
  creatingInProgress: false,
  editorDrawerIsVisible: false,
  createDrawerIsVisible: false,
  newCategory: {
    titles: {
      hu: '',
      en: '',
    },
    parent: 'root',
    color: '#2980B9',
  },
  units: null,
  selectedCategory: null,
};

export default initialState;
