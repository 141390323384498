import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { logout } from 'actions/userActions';
import { ReactComponent as Products } from 'assets/products.svg';
import { Header, DashboardHeader, DashboardCard } from '@flexboxapps/flbx-webapp-ui';
import { showNotImplementedAlert } from 'components/NotImplemented/showNotImplementedAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister } from '@fortawesome/pro-regular-svg-icons';
import { faPercentage, faAnalytics } from '@fortawesome/pro-light-svg-icons';
import './dashboard.scss';

const DashboardPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useSelector(({ user }: RootState) => user);
  const usermenu = [{ label: t('app.common.buttons.logout'), action: () => dispatch(logout()) }];
  return (
    <div className="page page-dashboard">
      <Header
        userinfo={email || ''}
        onHamburgerMenuClick={showNotImplementedAlert}
        onLogoClick={() => { }}
        usermenu={usermenu}
      />
      <div className="header-container">
        <DashboardHeader
          title={t('pages.dashboard.title')}
          subtitle={t('pages.dashboard.welcomeSubtitle')}
          iconName={faCashRegister}
        />
      </div>
      <div className="dashboard-cards">
        <DashboardCard
          route="/categories"
          title={t('pages.dashboard.infocards.categories')}
          subtitle={t('pages.dashboard.infocards.categorysub')}
          logo={<Products />}
        />
        <DashboardCard
          route="/sales/daily"
          title={t('pages.dashboard.infocards.sales')}
          subtitle={t('pages.dashboard.infocards.salessub')}
          logo={<FontAwesomeIcon icon={faAnalytics} color="#FFFFFF" size="2x" />}
        />
        <DashboardCard
          route="/discounts"
          title={t('pages.dashboard.infocards.discounts')}
          subtitle={t('pages.dashboard.infocards.discountssub')}
          logo={<FontAwesomeIcon icon={faPercentage} color="#FFFFFF" size="3x" />}
        />
      </div>
    </div>
  );
};

export default DashboardPage;
