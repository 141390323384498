import {
  SALES_LIST_R, SalesListRequested,
  SALES_SET_FILTERS, SalesSetFilters,
  SALES_LIST_MORE_R, SalesListMoreRequested,
  SALES_GET_SALE_POINTS_R, SalesGetSalePointsRequested,
  SALES_SELECT_SALE_POINT, SalesSelectSalePoint,
} from 'consts/salesActionTypes';

export const getSales = (): SalesListRequested => ({
  type: SALES_LIST_R,
});

export const getMoreSales = (): SalesListMoreRequested => ({
  type: SALES_LIST_MORE_R,
});

export const setFilters = (filters: any): SalesSetFilters => ({
  type: SALES_SET_FILTERS,
  payload: {
    filters,
  },
});

export const getSalePoints = (): SalesGetSalePointsRequested => ({
  type: SALES_GET_SALE_POINTS_R,
});

export const selectSalePoint = (selectedSalePoint: any): SalesSelectSalePoint => ({
  type: SALES_SELECT_SALE_POINT,
  payload: {
    selectedSalePoint,
  },
});
