import React, { FC } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

const BORDER_COLOR = '#bfbfbf';
const BORDER_STYLE = 'solid';
const COL1_WIDTH = 40;
const COLN_WIDTH = (100 - COL1_WIDTH) / 3;

const styles = StyleSheet.create({
  body: {
    padding: 40,
  },
  header: {
    textAlign: 'center',
    marginBottom: 24,
  },
  headerTitle: {
    fontSize: 18,
    marginBottom: 6,
  },
  headerSubtitle: {
    fontSize: 14,
  },
  summaryBlockContainer: {
    flexDirection: 'row',
    marginBottom: 24,
  },
  summaryBlock: {
    flex: 1,
  },
  summaryRow: {
    flexDirection: 'row',
    paddingBottom: 12,
  },
  summaryLabel: {
    fontSize: 12,
    paddingRight: 6,
  },
  summaryValue: {
    fontSize: 12,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol1Header: {
    width: `${COL1_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: `${COLN_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol1: {
    width: `${COL1_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: `${COLN_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  total: {
    textAlign: 'right',
    marginTop: 18,
    marginRight: 48,
    padding: 6,
    borderTop: '2px solid black',
  },
  totalText: {
    fontSize: 16,
  },
});

type TableRowProps = {
  id: string | number,
  productName: string | number,
  quantity: string | number,
  unitPrice: string | number,
  subTotal: string | number,
};

const TableRow: FC<TableRowProps> = ({ id = '', productName = '', quantity = '', unitPrice = '', subTotal = '' }) => (
  <View key={id} style={styles.tableRow}>
    <View style={styles.tableCol1}>
      <Text style={styles.tableCell}>{productName}</Text>
    </View>
    <View style={styles.tableCol}>
      <Text style={styles.tableCell}>{quantity}</Text>
    </View>
    <View style={styles.tableCol}>
      <Text style={styles.tableCell}>{unitPrice}</Text>
    </View>
    <View style={styles.tableCol}>
      <Text style={styles.tableCell}>{subTotal}</Text>
    </View>
  </View>
);

type DailyTradePDFProps = {
  itemList: any[],
  totalPrice: number,
  warehouse?: any,
};

const DailyTradePDF: FC<DailyTradePDFProps> = React.memo(({ itemList, totalPrice, warehouse = null }) => (
  <Document>
    <Page style={styles.body}>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>Napi elszámolás</Text>
        <Text style={styles.headerSubtitle}>{moment().format('YYYY-MM-DD')}</Text>
      </View>
      <View style={styles.summaryBlockContainer}>
        <View style={styles.summaryBlock}>
          <View style={styles.summaryRow}>
            <Text style={styles.summaryLabel}>Eladási helyszín:</Text>
            <Text style={styles.summaryValue}>{warehouse ? warehouse.title : 'Minden helyszín'}</Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={styles.summaryLabel}>Összes bevétel:</Text>
            <Text style={styles.summaryValue}>{`${totalPrice} Ft`}</Text>
          </View>
        </View>
        <View style={styles.summaryBlock}>
          <View style={styles.summaryRow}>
            <Text style={styles.summaryLabel}>Eladások száma:</Text>
            <Text style={styles.summaryValue}>{`${itemList.length} alkalom`}</Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={styles.summaryLabel}>Eladott termékek:</Text>
            <Text style={styles.summaryValue}>
              {`${itemList.map(({ quantity }) => (quantity)).reduce((a, b) => (a + b), 0)} darab`}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol1Header}>
            <Text style={styles.tableCellHeader}>Termék</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Mennyiség</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Egységár (Ft)</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Részösszeg (Ft)</Text>
          </View>
        </View>
        {
          itemList.map((item) => {
            const { id, productName, quantity, unitPrice, subTotal } = item;
            return (
              <TableRow
                key={`${Math.random()}-${id}`}
                id={id}
                productName={productName}
                quantity={quantity}
                unitPrice={unitPrice}
                subTotal={subTotal}
              />
            );
          })
        }
      </View>
      <View style={styles.total}>
        <Text style={styles.totalText}>{`TOTAL: ${totalPrice} Ft`}</Text>
      </View>
    </Page>
  </Document>
));

export default DailyTradePDF;
