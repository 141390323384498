import React, { useEffect, useState, useRef, FC } from 'react';
import moment from 'moment';
import {
  VictoryArea, VictoryAxis, VictoryChart,
  VictoryVoronoiContainer, VictoryTooltip,
} from 'victory';
import { useTranslation } from 'react-i18next';
import customVictoryTheme from 'services/victoryChartTheme';
import { getTotalPrice, numberWithCommas } from 'services/services';
import './daily-sales-income.scss';

type DailySalesIncomeProps = {
  sales: any[],
};

const DailySalesIncome: FC<DailySalesIncomeProps> = ({ sales = [] }) => {
  const { t } = useTranslation();
  const containerRef = useRef<any>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const updateContainerWidth = () => {
    if (containerRef.current.offsetWidth > 500) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);

    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  const hours: { [hour: string]: any } = {
    6: { value: 0 },
    7: { value: 0 },
    8: { value: 0 },
    9: { value: 0 },
    10: { value: 0 },
    11: { value: 0 },
    12: { value: 0 },
    13: { value: 0 },
    14: { value: 0 },
    15: { value: 0 },
    16: { value: 0 },
    17: { value: 0 },
    18: { value: 0 },
    19: { value: 0 },
    20: { value: 0 },
    21: { value: 0 },
  };

  if (sales && sales.length !== 0) {
    sales.forEach(({ createdAt, itemList }) => {
      const hour = moment(createdAt).format('H');
      hours[hour].value += getTotalPrice(itemList);
    });
  }

  let total = 0;
  const data2 = Object.entries(hours).map(([key, { value }]) => {
    total += value;
    return { x: key, y: total };
  });

  const getStepper = (): number => {
    if (total > 100000) {
      return 10000;
    }
    if (total > 10000) {
      return 1000;
    }
    return 100;
  };

  const stepValue = (Math.ceil((total) / getStepper()) * getStepper()) / 10;

  return (
    <div className="daily-sales-income" ref={containerRef}>
      <div className="daily-sales-income-title">{t('pages.sales.incomeIncreasingPerHour')}</div>
      <VictoryChart
        height={400}
        width={containerWidth}
        padding={{ top: 30, right: 40, bottom: 60, left: 100 }}
        theme={customVictoryTheme}
        animate={{
          onLoad: { duration: 1000 },
          duration: 1000,
        }}
        containerComponent={(
          <VictoryVoronoiContainer
            labels={({ datum }) => (`${numberWithCommas(datum.y)} Ft`)}
            labelComponent={(
              <VictoryTooltip
                constrainToVisibleArea
                style={{ fill: '#5c5c5c', fontSize: 24 }}
                dy={-7}
                pointerLength={0}
                flyoutPadding={{ left: 7, right: 7, top: 2, bottom: 2 }}
                flyoutStyle={{ strokeWidth: 0, fill: 'gainsboro' }}
              />
            )}
          />
        )}
      >
        <VictoryAxis
          tickValues={Object.keys(hours)}
          label={t('pages.sales.hour')}
        />
        <VictoryAxis
          dependentAxis
          tickValues={[...Array(Math.ceil(total / stepValue) + 1).keys()].map((n) => (n * stepValue))}
          tickFormat={(value) => `${numberWithCommas(Math.round(value))} Ft`}
        />
        <VictoryArea
          interpolation="monotoneX"
          style={{ data: { fill: '#6645E0' } }}
          data={data2}
        />
      </VictoryChart>
    </div>
  );
};

export default DailySalesIncome;
