export type Address = {
  address: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
};

export type GeoLocation = {
  lat: number;
  lng: number;
};

export type Location = {
  address: Address;
  createdAt: string;
  deprecated: boolean;
  parent: null;
  root: null;
  title: string;
  updatedAt: string;
  userId: string;
  value: number;
  __v: number;
  _id: string;
};

export type LocationState = {
  locations: Location[],
  createDrawerIsVisible: boolean,
  title: string;
  creatingInProgress: boolean;
};

export const initialState: LocationState = {
  locations: [],
  createDrawerIsVisible: false,
  title: '',
  creatingInProgress: false,
};
