import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFingerprint } from '@fortawesome/pro-regular-svg-icons';
import RestorePassword from 'components/RestorePassword/RestorePassword';
import { ReactComponent as LogoWideInverse } from 'assets/logo-wide-inverse.svg';
import { ReactComponent as LogoWide } from 'assets/logo-wide-normal.svg';
import './restore-password-page.scss';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmail } from 'actions/userActions';
import { RootState } from 'reducers/rootReducer';
import { notification } from 'antd';

export interface RestorePasswordPageProps {
  location?: {
    search: string;
  };
}

const RestorePasswordPage: FC<RestorePasswordPageProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const param = new URLSearchParams(props.location?.search);
  const email = param.get('email');
  const { sendingEmailError, isEmailSent, forgotPasswordSucceeded } = useSelector(({ user }: RootState) => user);

  useEffect(() => {
    if (email) {
      dispatch(sendEmail(email || ''));
    }
  }, [dispatch, email]);

  useEffect(() => {
    if (sendingEmailError) {
      notification.error({
        message: t('pages.retorePassword.errors.emailSentErrorMessage'),
        description: t('pages.retorePassword.errors.emailSentErrorDescription'),
      });
    }
  }, [sendingEmailError]);

  useEffect(() => {
    if (isEmailSent) {
      notification.success({
        message: t('pages.retorePassword.success.emailMessage'),
        description: t('pages.retorePassword.success.emailDescription'),
      });
    }
  }, [isEmailSent]);

  useEffect(() => {
    if (forgotPasswordSucceeded) {
      notification.success({
        message: t('pages.retorePassword.success.forgotPasswordMessage'),
        description: t('pages.retorePassword.success.forgotPasswordDescription'),
      });
    }
  }, [forgotPasswordSucceeded]);

  return (
    <div className="page page-restore-password">
      <div className="logo">
        <LogoWide className="logo-normal" />
        <LogoWideInverse width="196" className="logo-inverse" />
      </div>
      <div className="restore-password-background">
        <div className="restore-password-box">
          <div className="header-container">
            <FontAwesomeIcon icon={faFingerprint} />
            <div>
              <div className="form-title">{t('pages.retorePassword.title')}</div>
              <div className="form-subtitle">{t('pages.retorePassword.subtitle')}</div>
            </div>
          </div>
          <RestorePassword email={email || ''} />
        </div>
      </div>

    </div>
  );
};

export default RestorePasswordPage;
