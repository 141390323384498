import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faReply } from '@fortawesome/pro-solid-svg-icons';
import { categoryReorder } from 'actions/productCategoriesActions';
import CategoryCard from './CategoryCard';

type CategoryCardsProps = {
  appLang: string,
  categories: any[],
  selectedCategory: any,
  selectCategory: any,
  openCreateCategoryDrawer: (visible: boolean) => void,
};

const reorder = (list: any, startIndex: any, endIndex: any): any => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const CategoryCards: FC<CategoryCardsProps> = ({
  categories, appLang, selectedCategory,
  selectCategory, openCreateCategoryDrawer,
}) => {
  const dispatch = useDispatch();
  const sortedCategories = categories.map((item) => ({ ...item, index: item.index || 0 }))
    .sort((a: any, b: any) => a.index - b.index);
  const [categoryList, setItems] = useState(sortedCategories);
  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    const reorderedList = reorder(categoryList, source.index, destination.index);
    const order = reorderedList.map((item: { _id: string }) => item._id);
    dispatch(categoryReorder(order));
    setItems(reorderedList);
  };

  useEffect(() => {
    setItems(sortedCategories);
  }, [categories]);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="category-reorderer" direction="horizontal">
          {(dropProvided) => (
            <div
              {...dropProvided.droppableProps}
              ref={dropProvided.innerRef}
              className="category-cards"
            >
              {
                categoryList.map((category, index) => (
                  <Draggable key={category._id} draggableId={category._id} index={index}>
                    {(draggableProvided) => (
                      <div
                        key={category._id}
                        className="category-card-container"
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <CategoryCard
                          appLang={appLang}
                          selectCategory={selectCategory}
                          category={category}
                        />
                      </div>
                    )}
                  </Draggable>
                ))
              }
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="category-cards">
        {
          selectedCategory !== null
          && (
            <div className="category-card-container">
              <button
                type="button"
                className="category-card category-card-back"
                onClick={() => {
                  selectCategory(selectedCategory.parent);
                }}
              >
                <span className="card-icon">
                  <FontAwesomeIcon icon={faReply} size="1x" />
                </span>
                <span>Vissza</span>
              </button>
            </div>
          )
        }
        <div className="category-card-container">
          <button
            type="button"
            className="category-card new-item"
            onClick={() => openCreateCategoryDrawer(true)}
          >
            <FontAwesomeIcon key="fa-plus" icon={faPlus} size="2x" />
          </button>
        </div>
      </div>
    </>
  );
};

export default CategoryCards;
