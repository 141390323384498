import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'services/config';
import { Header, BreadCrumbNavigator, Button, Switch } from '@flexboxapps/flbx-webapp-ui';
import { RootState } from 'reducers/rootReducer';
import { logout } from 'actions/userActions';
import { getProductCategories } from 'actions/productCategoriesActions';
import { listWhProducts } from 'actions/whProductsActions';
import { editProductField, setProductUploadDrawer, createPosProduct } from 'actions/posProductsActions';
import { getTaxrates } from 'actions/taxrateActions';
import { getSalePoints } from 'actions/salesActions';
import { showNotImplementedAlert } from 'components/NotImplemented/showNotImplementedAlert';
import { assortProductCategory } from 'services/services';
import UploadProductPictureDrawer from 'components/Product/UploadProductPictureDrawer/UploadProductPictureDrawer';
import ProductDetailsBlock from 'components/Product/Details/ProductDetails';
import TaxValueBlock from 'components/TaxValueBlock/TaxValueBlock';
import Spinner from 'components/Spinner/Spinner';
import AssignWarehouseProductBlock from 'components/AssignWarehouseProductBlock/AssignWarehouseProductBlock';
import ColumnLayoutBlock from 'layouts/ColumnLayout/ColumnLayoutBlock';
import './new-product.scss';

const NewProductPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useSelector(({ user }: RootState) => user);
  const posProductsStore = useSelector(({ posProducts }: RootState) => posProducts);
  const { data: productCategoriesStore } = useSelector(({ productCategories }: RootState) => productCategories);
  const { lang } = useSelector(({ app }: RootState) => app);
  const { taxrates } = useSelector(({ taxrate }: RootState) => taxrate);
  const { salePoints } = useSelector(({ sales }: RootState) => sales);
  const { productOptions, isFetching: isFetchingWhProducts } = useSelector(({ whProducts }: RootState) => whProducts);

  useEffect(() => {
    dispatch(getProductCategories());
    dispatch(getTaxrates());
    dispatch(getSalePoints());
    dispatch(listWhProducts());
  }, []);

  const usermenu = [{ label: t('app.common.buttons.logout'), action: () => dispatch(logout()) }];

  const renderCategoryChildren = (categoryLevels: any, categoryParents: any) => {
    const items: any[] = [];
    const titleLanguage = lang === 'en-US' ? 'en' : 'hu';
    const fallbackTitleLanguage = lang === 'en-US' ? 'hu' : 'en';

    Object.keys(categoryLevels).forEach((categoryId) => {
      const childItem: any = {
        title: categoryLevels[categoryId].titles[titleLanguage] || categoryLevels[categoryId].titles[fallbackTitleLanguage],
        key: categoryId,
        value: categoryId,
      };

      if (categoryParents[categoryId]) {
        childItem.children = renderCategoryChildren(categoryParents[categoryId], categoryParents);
      }

      items.push(childItem);
    });

    return items;
  };

  const { categoryLevels, categoryParents } = assortProductCategory(productCategoriesStore);
  const categoryTree = categoryLevels[0] ? renderCategoryChildren(categoryLevels[0], categoryParents) : [];

  return (
    <div className="page page-product">
      <Header
        userinfo={email || ''}
        onHamburgerMenuClick={showNotImplementedAlert}
        onLogoClick={() => { history.push('/dashboard'); }}
        usermenu={usermenu}
      />
      <div className="sales-header-container">
        <div className="sales-header-inner">
          <BreadCrumbNavigator
            breadcrumbs={[
              { title: 'Dashboard', link: '/dashboard' },
              { title: t('pages.categories.title'), link: '/categories' },
            ]}
          />
          <div className="sales-header-title">
            {t('pages.newProduct.title')}
          </div>
        </div>
      </div>
      <div className="product-page-body">
        {
          !salePoints || !taxrates.length
            ? (
              <Spinner />
            ) : (
              <>
                <UploadProductPictureDrawer drawerType="newProduct" />

                <ProductDetailsBlock
                  product={posProductsStore.newProduct}
                  categoryTree={categoryTree}
                  editProductField={(field, value) => {
                    dispatch(editProductField('newProduct', field, value));
                  }}
                  openUploadModal={() => dispatch(setProductUploadDrawer(true))}
                />

                <TaxValueBlock
                  taxValueExceptions={posProductsStore.newProduct.taxValueExceptions}
                  editProductField={(field, value) => {
                    dispatch(editProductField('newProduct', field, value));
                  }}
                  openUploadModal={() => dispatch(setProductUploadDrawer(true))}
                />

                {
                  !isFetchingWhProducts
                  && (
                    <AssignWarehouseProductBlock
                      productOptions={productOptions}
                      editProductField={(field, value) => {
                        dispatch(editProductField('newProduct', field, value));
                      }}
                      ingredients={posProductsStore.newProduct.ingredients}
                    />
                  )
                }
                <ColumnLayoutBlock title={t('pages.newProduct.other')}>
                  <Switch
                    onChange={(checkedValue) => { if (checkedValue !== undefined) dispatch(editProductField('newProduct', 'visible', checkedValue)); }}
                    checked={posProductsStore.newProduct.visible}
                    type="large"
                    title={t('pages.newProduct.showeable')}
                  />
                </ColumnLayoutBlock>
                <Button
                  label={t('pages.newProduct.title')}
                  type="ghost"
                  disabled={
                    (posProductsStore.newProduct.titles.hu.trim() === ''
                      && (posProductsStore.newProduct.titles.en === null || posProductsStore.newProduct.titles.en.trim() === ''))
                    || !posProductsStore.newProduct.takeaway || !posProductsStore.newProduct.dinein
                  }
                  onClick={() => dispatch(createPosProduct())}
                />
              </>
            )
          }
      </div>
    </div>
  );
};

export default NewProductPage;
