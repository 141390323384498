import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Table } from 'antd';
import EmptyPage from 'components/EmptyPage/EmptyPage';
import Spinner from 'components/Spinner/Spinner';
import { getTotalPrice, numberWithCommas } from 'services/services';
import { RootState } from 'reducers/rootReducer';
import './sales-list.scss';

const SalesTable: FC = () => {
  const { t } = useTranslation();
  const sales = useSelector(({ sales: salesStore }: RootState) => salesStore);
  const listTableColumns: any = [
    {
      title: t('pages.sales.dateOfSale'),
      dataIndex: 'dateTime',
      key: 'dateTime',
      sorter: (a: any, b: any) => (moment(a.dateTime).format('HH:mm:ss') > moment(b.dateTime).format('HH:mm:ss') ? 1 : -1),
      render: (date: any) => (moment(date).format('HH:mm')),
    },
    {
      title: t('pages.sales.numberOfItems'),
      dataIndex: 'itemCount',
      key: 'itemCount',
      align: 'center',
      sorter: (a: any, b: any) => (a.itemCount - b.itemCount),
      render: (itemCount: any) => (`${itemCount} ${t('pages.sales.item')}`),
    },
    {
      title: t('pages.sales.amountPaid'),
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      sorter: (a: any, b: any) => (a.price - b.price),
      render: (price: any) => (`${price} Ft`),
    },
  ];

  const dataSource = sales.data.map((salesItem: { _id: string, createdAt: string, itemList: any[] }) => ({
    key: salesItem._id,
    dateTime: salesItem.createdAt,
    itemCount: salesItem.itemList.length,
    price: numberWithCommas(getTotalPrice(salesItem.itemList)),
    itemList: salesItem.itemList,
  }));

  const expandableTableColumns: any = [
    { title: t('pages.sales.expandableColumns.displayName'), dataIndex: 'displayName' },
    { title: t('pages.sales.expandableColumns.quantity'), dataIndex: 'quantity', align: 'center' },
    { title: t('pages.sales.expandableColumns.unitPrice'), dataIndex: 'unitPrice', align: 'center', className: 'column-money' },
    { title: t('pages.sales.expandableColumns.subTotal'), dataIndex: 'subTotal', align: 'center', className: 'column-money' },
  ];

  const getExpandableDataSource = (record: any) => record.itemList.map((item: any) => ({
    key: `${record._id}-${item.posProduct._id}`,
    displayName: item.posProduct.titles.hu,
    unitPrice: numberWithCommas(item.price.currencies[0].value),
    quantity: item.quantity,
    subTotal: numberWithCommas(item.price.currencies[0].value * item.quantity),
  }));

  return (
    <div className="sales-list">
      {
        sales.data === null
        && (
          <Spinner />
        )
      }

      {
        sales.data && sales.data.length === 0
        && (
          <EmptyPage
            title={t('pages.sales.emptyPage.title')}
          />
        )
      }

      {
        sales.data && sales.data.length > 0
        && (
          <Table
            className="sales-list-table"
            columns={listTableColumns}
            dataSource={dataSource}
            expandable={{
              expandRowByClick: true,
              expandedRowRender: (record) => (
                <Table
                  key={record._id}
                  columns={expandableTableColumns}
                  dataSource={getExpandableDataSource(record)}
                  bordered
                  title={() => (`${t('pages.sales.dateOfPurchase')}: ${moment(record.dateTime).format('YYYY-MM-DD HH:mm:ss')}`)}
                  pagination={false}
                />
              ),
              rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
            showSorterTooltip={false}
            pagination={false}
            scroll={{ x: 624 }}
          />
        )
      }
    </div>
  );
};

export default SalesTable;
