import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  GET_LOCATION_R, GET_LOCATION_F, GET_LOCATION_S, LocationGetLocationRequested,
  CREATE_LOCATION_R, CREATE_LOCATION_F, CREATE_LOCATION_S,
} from 'consts/locationActionTypes';
import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import * as API from 'services/api';
import { RootState } from 'reducers/rootReducer';
import { Address, GeoLocation } from 'reducers/locations/initialState';

const getLocationStore = (state: RootState) => state.location;

function* getLocations(action: LocationGetLocationRequested) {
  try {
    const { value, key, term } = action.payload;
    const query = {
      $params: JSON.stringify({
        title: { $regex: term, $options: 'i' },
        deprecated: false,
      }),
      $sort: JSON.stringify({
        [key || 'createdAt']: value || Sorts.asc,
      }),
    };
    const { data } = yield call(API.findDataService, 'locations', '/locations', query);
    yield put({
      type: GET_LOCATION_S,
      payload: {
        locations: data,
      },
    });
  } catch (error) {
    yield put({
      type: GET_LOCATION_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

function* createLocation() {
  try {
    const { title } = yield select(getLocationStore);
    const mockAddress: Address = {
      state: 'MockAddress',
      city: 'MockAddress',
      postalCode: 'MockAddress',
      address: 'MockAddress',
      country: 'MockAddress',
    };
    const mockGeoLocation: GeoLocation = {
      lat: 0,
      lng: 0,
    };
    const updatedData = {
      title,
      address: mockAddress,
      geoLocation: mockGeoLocation,
    };
    yield call(API.createDataService, 'locations', '/locations', updatedData);
    yield put({
      type: CREATE_LOCATION_S,
    });
    yield put({
      type: GET_LOCATION_R,
      payload: {
        value: Sorts.asc,
        key: 'createdAt',
        term: '',
      },
    });
  } catch (error) {
    yield put({
      type: CREATE_LOCATION_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export const locationSagas = [
  takeEvery(GET_LOCATION_R, getLocations),
  takeEvery(CREATE_LOCATION_R, createLocation),
];
