import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  GET_TAXRATE_R, GET_TAXRATE_F, GET_TAXRATE_S, TaxrateGetTaxrateRequested,
  CREATE_TAXRATE_R, CREATE_TAXRATE_F, CREATE_TAXRATE_S,
} from 'consts/taxRateActionTypes';
import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import * as API from 'services/api';
import { RootState } from 'reducers/rootReducer';

const getTaxrateStore = (state: RootState) => state.taxrate;

function* getLocations(action: TaxrateGetTaxrateRequested) {
  try {
    const { value, key } = action.payload;
    const query = {
      $params: JSON.stringify({
        deprecated: false,
      }),
      $sort: JSON.stringify({
        [key || 'createdAt']: value || Sorts.asc,
      }),
    };
    const { data } = yield call(API.findDataService, 'tax-rates', '/tax-rates', query);
    yield put({
      type: GET_TAXRATE_S,
      payload: {
        taxrates: data,
      },
    });
  } catch (error) {
    yield put({
      type: GET_TAXRATE_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

function* createLocation() {
  try {
    const { title } = yield select(getTaxrateStore);
    const updatedData = {
      title,
    };
    yield call(API.createDataService, 'tax-rates', '/tax-rates', updatedData);
    yield put({
      type: CREATE_TAXRATE_S,
    });
    yield put({
      type: GET_TAXRATE_R,
      payload: {
        value: Sorts.asc,
        key: 'createdAt',
        term: '',
      },
    });
  } catch (error) {
    yield put({
      type: CREATE_TAXRATE_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export const taxrateSagas = [
  takeEvery(GET_TAXRATE_R, getLocations),
  takeEvery(CREATE_TAXRATE_R, createLocation),
];
