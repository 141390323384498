import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'actions/userActions';
import { history } from 'services/config';
import { RootState } from 'reducers/rootReducer';
import { getProductCategories } from 'actions/productCategoriesActions';
import { getTaxrates } from 'actions/taxrateActions';
import { getSalePoints } from 'actions/salesActions';
import { assortProductCategory } from 'services/services';
import { Header } from '@flexboxapps/flbx-webapp-ui';
import CategoryEditor from 'components/Category/CategoryEditor/CategoryEditor';
import { showNotImplementedAlert } from 'components/NotImplemented/showNotImplementedAlert';
import './categories.scss';

const Categories: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lang } = useSelector(({ app }: RootState) => app);
  const { data: productCategoriesStore } = useSelector(({ productCategories }: RootState) => productCategories);
  const { email } = useSelector(({ user }: RootState) => user);

  useEffect(() => {
    dispatch(getProductCategories());
    dispatch(getTaxrates());
    dispatch(getSalePoints());
  }, []);

  const renderCategoryChildren = (categoryLevels: any, categoryParents: any) => {
    const items: any[] = [];
    const titleLanguage = lang === 'en-US' ? 'en' : 'hu';
    const fallbackTitleLanguage = lang === 'en-US' ? 'hu' : 'en';

    Object.keys(categoryLevels).forEach((categoryId) => {
      const childItem: any = {
        title: categoryLevels[categoryId].titles[titleLanguage] || categoryLevels[categoryId].titles[fallbackTitleLanguage],
        key: categoryId,
        value: categoryId,
      };

      if (categoryParents[categoryId]) {
        childItem.children = renderCategoryChildren(categoryParents[categoryId], categoryParents);
      }

      items.push(childItem);
    });

    return items;
  };

  const { categoryLevels, categoryParents } = assortProductCategory(productCategoriesStore);
  const categoryTree = categoryLevels[0] ? renderCategoryChildren(categoryLevels[0], categoryParents) : [];
  const usermenu = [{ label: t('app.common.buttons.logout'), action: () => dispatch(logout()) }];
  return (
    <div className="page page-product">
      <Header
        userinfo={email || ''}
        onHamburgerMenuClick={showNotImplementedAlert}
        onLogoClick={() => { history.push('/dashboard'); }}
        usermenu={usermenu}
        large
      />
      <div className="product-page-body">
        <div className="page page-categories">
          <CategoryEditor categoryTree={categoryTree} />
        </div>
      </div>
    </div>
  );
};

export default Categories;
