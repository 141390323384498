export interface UserState {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  hasAuthenticatingError: boolean;
  errorMessage: string;
  email?: string;
  isSendingEmail: boolean;
  isEmailSent: boolean;
  sendingEmailError: boolean;
  forgotPasswordSucceeded: boolean;
  forgotPasswordError: boolean;
}

export const initialState: UserState = {
  isAuthenticating: false,
  isAuthenticated: false,
  hasAuthenticatingError: false,
  errorMessage: '',
  email: undefined,
  isSendingEmail: false,
  isEmailSent: false,
  sendingEmailError: false,
  forgotPasswordSucceeded: false,
  forgotPasswordError: false,
};
