import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@flexboxapps/flbx-webapp-ui';
import { setProductUploadDrawer, editProductField } from 'actions/posProductsActions';
import { RootState } from 'reducers/rootReducer';
import ProductPictureDND from 'components/Product/ProductPictureDND/ProductPictureDND';
import '../ProductProfileDrawer/product-profile-drawer.scss';

type UploadProductPictureDrawerProps = {
  drawerType: string,
};

const UploadProductPictureDrawer: FC<UploadProductPictureDrawerProps> = ({ drawerType }) => {
  const dispatch = useDispatch();
  const [removeImage, setRemoveImage] = useState(false);
  const { uploadDrawerIsVisible } = useSelector(({ posProducts }: RootState) => posProducts);
  return (
    <Drawer
      className="app-drawer drawer-product-profile"
      title="Termékkép feltöltése"
      onClose={() => {
        dispatch(setProductUploadDrawer(false));
        setRemoveImage(!removeImage);
      }}
      visible={uploadDrawerIsVisible}
    >
      <ProductPictureDND
        closeModal={() => dispatch(setProductUploadDrawer(false))}
        setPictureId={(id: string, url: string) => {
          dispatch(editProductField(drawerType, 'image', { id, url }));
          dispatch(setProductUploadDrawer(false));
          setRemoveImage(!removeImage);
        }}
        removeImage={removeImage}
      />
    </Drawer>
  );
};

export default UploadProductPictureDrawer;
