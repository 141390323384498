import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import { OptionProps, SelectValue } from 'antd/lib/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import { TaxRate } from 'reducers/taxrates/initialState';
import { Select, Button } from '@flexboxapps/flbx-webapp-ui';
import ColumnLayoutBlock from 'layouts/ColumnLayout/ColumnLayoutBlock';
import ColumnLayout from 'layouts/ColumnLayout/ColumnLayout';

type TaxValueBlockProps = {
  takeaway?: string,
  dinein?: string,
  taxValueExceptions: any[],
  openUploadModal: () => void,
  editProductField: (key: string, value: string | string[] | SelectValue) => void,
};

const TaxValueBlock: FC<TaxValueBlockProps> = ({
  takeaway, dinein, taxValueExceptions,
  editProductField,
}) => {
  const { t } = useTranslation();

  const { taxrates } = useSelector((state: RootStateOrAny) => state.taxrate);
  const { salePoints } = useSelector((state: RootStateOrAny) => state.sales);

  const [taxValueExceptionsCount, setTaxValueExceptionsCount] = useState(taxValueExceptions.length || 0);
  const exceptionSalePoints = taxValueExceptions.map((exception) => exception?.salepoint);
  const availableSalePoints = salePoints.map((item: TaxRate) => ({
    label: item.title,
    value: item._id,
  }))
    .map((salePointItem: OptionProps) => (!exceptionSalePoints.includes(salePointItem.value) ? salePointItem : {
      ...salePointItem,
      disabled: true,
    }));

  return (
    <div>
      <ColumnLayoutBlock title={t('pages.newProduct.taxKeys')}>
        <ColumnLayout>
          <Select
            label={t('pages.products.inputs.takeaway')}
            placeholder={t('pages.products.inputs.taxratePlaceholder')}
            value={takeaway}
            options={taxrates.map((item: TaxRate) => ({
              label: item.title,
              value: item._id,
            }))}
            onChange={(value) => { editProductField('takeaway', value); }}
            size="large"
          />
          <Select
            label={t('pages.products.inputs.dinein')}
            placeholder={t('pages.products.inputs.taxratePlaceholder')}
            value={dinein}
            options={taxrates.map((item: TaxRate) => ({
              label: item.title,
              value: item._id,
            }))}
            onChange={(value) => editProductField('dinein', value)}
            size="large"
          />
        </ColumnLayout>

        {
          [...Array(taxValueExceptionsCount)].length === 0 && salePoints.length > 1
          && (
            <ColumnLayout>
              <Button
                label={t('pages.products.buttons.addException')}
                onClick={() => {
                  setTaxValueExceptionsCount(taxValueExceptionsCount + 1);
                  editProductField('taxValueExceptions', [...Array(taxValueExceptionsCount + 1)].map((_, index) => taxValueExceptions[index]));
                }}
                type="ghost"
              />
            </ColumnLayout>
          )
        }
      </ColumnLayoutBlock>

      {
        [...Array(taxValueExceptionsCount)].length > 0
        && (
          <ColumnLayoutBlock title={t('pages.newProduct.availableTaxKeys')}>
            {
              [...Array(taxValueExceptionsCount)].map((_, index) => (
                <ColumnLayout lastItemWidth={30} key={index.toString()}>
                  <Select
                    label={t('pages.products.inputs.salepoint')}
                    placeholder={t('pages.products.inputs.salepointPlaceholder')}
                    value={taxValueExceptions[index]?.salepoint}
                    options={availableSalePoints}
                    onChange={(value) => {
                      editProductField('taxValueExceptions', taxValueExceptions
                        .map((exception, exceptionIndex) => (index === exceptionIndex ? { ...exception, salepoint: value } : exception)));
                    }}
                    size="large"
                  />
                  <Select
                    label={t('pages.products.inputs.takeaway')}
                    placeholder={t('pages.products.inputs.taxratePlaceholder')}
                    value={taxValueExceptions[index]?.takeaway}
                    options={taxrates.map((item: TaxRate) => ({
                      label: item.title,
                      value: item._id,
                    }))}
                    onChange={(value) => {
                      editProductField('taxValueExceptions', taxValueExceptions
                        .map((exception, exceptionIndex) => (index === exceptionIndex ? { ...exception, takeaway: value } : exception)));
                    }}
                    size="large"
                  />
                  <Select
                    label={t('pages.products.inputs.dinein')}
                    placeholder={t('pages.products.inputs.taxratePlaceholder')}
                    value={taxValueExceptions[index]?.dinein}
                    options={taxrates.map((item: TaxRate) => ({
                      label: item.title,
                      value: item._id,
                    }))}
                    onChange={(value) => {
                      editProductField('taxValueExceptions', taxValueExceptions
                        .map((exception, exceptionIndex) => (index === exceptionIndex ? { ...exception, dinein: value } : exception)));
                    }}
                    size="large"
                  />

                  <button
                    type="button"
                    onClick={() => {
                      setTaxValueExceptionsCount(taxValueExceptionsCount - 1);
                      editProductField('taxValueExceptions', taxValueExceptions.filter((exception, exceptionIndex) => index !== exceptionIndex));
                    }}
                  >
                    <FontAwesomeIcon icon={faMinusCircle} style={{ fontSize: 22 }} color="red" />
                  </button>

                </ColumnLayout>
              ))
            }

            {
              salePoints && salePoints.length > taxValueExceptionsCount && (
                <ColumnLayout>
                  <Button
                    label={t('pages.products.buttons.addMoreException')}
                    onClick={() => {
                      setTaxValueExceptionsCount(taxValueExceptionsCount + 1);
                      editProductField('taxValueExceptions', [...Array(taxValueExceptionsCount + 1)].map((_, index) => taxValueExceptions[index]));
                    }}
                    type="ghost"
                  />
                </ColumnLayout>
              )
            }
          </ColumnLayoutBlock>
        )
      }
    </div>
  );
};

export default TaxValueBlock;
