import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setProductEditorDrawer, editProductField, updateEditedProduct, setProductUploadDrawer } from 'actions/posProductsActions';
import { Button, Drawer } from '@flexboxapps/flbx-webapp-ui';
import ProductEditor from 'components/Product/ProductEditForm/ProductEditForm';
import UploadProductPictureDrawer from 'components/Product/UploadProductPictureDrawer/UploadProductPictureDrawer';
import { RootState } from 'reducers/rootReducer';

type EditProductDrawerProps = {
  categoryTree: any[],
};

const EditProductDrawer: FC<EditProductDrawerProps> = ({ categoryTree }) => {
  const posProductsStore = useSelector(({ posProducts }: RootState) => posProducts);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Drawer
      className="app-drawer drawer-add-category"
      title={t('drawers.addProduct.editProduct')}
      onClose={() => dispatch(setProductEditorDrawer(false))}
      visible={posProductsStore.editorDrawerIsVisible}
    >
      <div className="drawer-inner-container">
        <UploadProductPictureDrawer drawerType="editedProduct" />
        <ProductEditor
          product={posProductsStore.editedProduct}
          editProductField={(field: string, value: any) => {
            dispatch(editProductField('editedProduct', field, value));
          }}
          categoryTree={categoryTree}
          openUploadModal={() => dispatch(setProductUploadDrawer(true))}
        />
      </div>
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={posProductsStore.editingInProgress}
          onClick={() => dispatch(setProductEditorDrawer(false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.save')}
          type="primary"
          disabled={
            (posProductsStore.editedProduct.titles.hu.trim() === ''
              && (posProductsStore.editedProduct.printTitles.hu === null || posProductsStore.editedProduct.printTitles.hu.trim() === ''))
            || (
              posProductsStore.editedProduct.categories[0] === posProductsStore.selectedProduct.categories[0]
              && posProductsStore.editedProduct.titles.hu === posProductsStore.selectedProduct.titles.hu
              && posProductsStore.editedProduct.printTitles.hu === posProductsStore.selectedProduct.printTitles.hu
              && posProductsStore.editedProduct.price.HUF === posProductsStore.selectedProduct.price.HUF
              && posProductsStore.editedProduct.image === posProductsStore.selectedProduct.image
            )
          }
          loading={posProductsStore.editingInProgress}
          onClick={() => dispatch(updateEditedProduct())}
        />
      </div>
    </Drawer>
  );
};

export default EditProductDrawer;
