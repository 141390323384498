import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Drawer } from '@flexboxapps/flbx-webapp-ui';
import { setCreateCategoryDrawer, updateNewCategory, createCategory } from 'actions/productCategoriesActions';
import CategoryEditor from 'components/Category/CategoryEditForm/CategoryEditForm';
import { RootState } from 'reducers/rootReducer';

type AddCategoryDrawerProps = {
  categoryTree: any[],
};

const AddCategoryDrawer: FC<AddCategoryDrawerProps> = ({ categoryTree }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productCategoriesStore = useSelector(({ productCategories }: RootState) => productCategories);

  return (
    <Drawer
      className="app-drawer drawer-add-category"
      title={t('drawers.addCategory.title')}
      onClose={() => dispatch(setCreateCategoryDrawer(false))}
      visible={productCategoriesStore.createDrawerIsVisible}
    >
      <CategoryEditor
        titles={productCategoriesStore.newCategory.titles}
        updateCategory={(field, value) => dispatch(updateNewCategory(field, value))}
        categoryTree={categoryTree}
        selectedParent={productCategoriesStore.newCategory.parent}
        color={productCategoriesStore.newCategory.color}
      />
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={productCategoriesStore.creatingInProgress}
          onClick={() => dispatch(setCreateCategoryDrawer(false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.create')}
          type="primary"
          disabled={productCategoriesStore.newCategory.titles.hu.trim() === '' && productCategoriesStore.newCategory.titles.en.trim() === ''}
          loading={productCategoriesStore.creatingInProgress}
          onClick={() => dispatch(createCategory())}
        />
      </div>
    </Drawer>
  );
};

export default AddCategoryDrawer;
