import { combineReducers } from 'redux';
import app from 'reducers/app/appReducer';
import user from 'reducers/user/userReducer';
import productCategories from 'reducers/productCategories/productCategoriesReducer';
import posProducts from 'reducers/posProducts/posProductsReducer';
import sales from 'reducers/sales/salesReducer';
import discount from 'reducers/discounts/discountReducer';
import location from 'reducers/locations/locationReducer';
import taxrate from 'reducers/taxrates/taxrateReducer';
import whProducts from 'reducers/whProducts/whProductsReducer';

const rootReducer = combineReducers({
  app,
  user,
  productCategories,
  posProducts,
  sales,
  discount,
  location,
  taxrate,
  whProducts,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
