import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import {
  GET_DISCOUNT_R, DiscountGetDiscountRequested,
  SET_DISCOUNT_DRAWER, DiscountSetDrawer,
  SET_DISCOUNT_INPUT, DiscountSetInput,
  CREATE_DISCOUNT_R, DiscountCreateDiscountRequested,
} from 'consts/discountActionTypes';

export const getDiscounts = (value?: Sorts | null, key?: string, term?: string): DiscountGetDiscountRequested => ({
  type: GET_DISCOUNT_R,
  payload: {
    value,
    key,
    term,
  },
});

export const setCreateCategoryDrawer = (value: boolean): DiscountSetDrawer => ({
  type: SET_DISCOUNT_DRAWER,
  payload: {
    value,
  },
});

export const setDiscountInput = (key: string, value?: string | number | boolean): DiscountSetInput => ({
  type: SET_DISCOUNT_INPUT,
  payload: {
    key,
    value,
  },
});

export const createDiscount = (): DiscountCreateDiscountRequested => ({
  type: CREATE_DISCOUNT_R,
});
