import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { DragDropContext } from 'react-beautiful-dnd';
import { productReorder } from 'actions/posProductsActions';
import Column, { Columns } from './Column';
import './product-cards.scss';

type ProductCardsProps = {
  categoryColor: string,
  categoryProducts: any[],
  openDrawer: () => void,
  handleClick: (key: string) => void,
};

const reorder = (list: any, startIndex: any, endIndex: any): any => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const ProductCards: FC<ProductCardsProps> = ({ categoryColor = '#6744E0', categoryProducts, handleClick, openDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const columnOrder = [Columns.priority, Columns.notPriority];
  const priorityItems = Object.keys(categoryProducts).filter((id: any) => categoryProducts[id].priority)
    .sort((a: any, b: any) => categoryProducts[a].index - categoryProducts[b].index);
  const notProiortyItems = Object.keys(categoryProducts).filter((id: any) => !categoryProducts[id].priority)
    .sort((a: any, b: any) => categoryProducts[a].titles.hu.localeCompare(categoryProducts[b].titles.hu));
  const [columns, setColumns] = useState<any>({
    [Columns.priority]: {
      id: Columns.priority,
      items: priorityItems,
    },
    [Columns.notPriority]: {
      id: Columns.notPriority,
      items: notProiortyItems,
    },
  });

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;
    let out = null;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    // not move if its notPriority column
    if (start.id === Columns.notPriority && finish.id === Columns.notPriority) {
      return;
    }

    if (start.id === Columns.priority && finish.id === Columns.notPriority) {
      out = draggableId;
    }

    // reorder if its priority column
    if (start === finish) {
      const reorderedList = reorder(start.items, source.index, destination.index);
      const newColumn = {
        ...start,
        items: reorderedList,
      };

      const newColumns = {
        ...columns,
        [newColumn.id]: newColumn,
      };

      setColumns(newColumns);
      dispatch(productReorder(newColumns.priority.items, out));
      return;
    }

    // Moving from one list to another
    const startItems = Array.from(start.items);
    startItems.splice(source.index, 1);
    const newStart = {
      ...start,
      items: start.id === Columns.notPriority
        ? startItems.sort((a: any, b: any) => categoryProducts[a].titles.hu.localeCompare(categoryProducts[b].titles.hu))
        : startItems,
    };

    const finishItems = Array.from(finish.items);
    finishItems.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      items: finish.id === Columns.notPriority
        ? finishItems.sort((a: any, b: any) => categoryProducts[a].titles.hu.localeCompare(categoryProducts[b].titles.hu))
        : finishItems,
    };

    const newColumns = {
      ...columns,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish,
    };

    dispatch(productReorder(newColumns.priority.items, out));
    setColumns(newColumns);
  };

  return (
    <div className="pos-category-product-cards">
      <DragDropContext onDragEnd={onDragEnd}>
        {columnOrder.map((columnId) => {
          const column = columns[columnId];
          const columnItems = column.items.map((id: any) => categoryProducts[id]);
          return (
            <Column
              key={column.id}
              columnId={column.id}
              items={columnItems}
              categoryColor={categoryColor}
              handleClick={handleClick}
            />
          );
        })}
      </DragDropContext>
      <div className="pos-category-product-card-container">
        <button
          type="button"
          className="new-item-button"
          onClick={() => openDrawer()}
        >
          <FontAwesomeIcon key="fa-plus" icon={faPlus} size="2x" />
          <div className="new-item-button-label">
            {t('pages.products.buttons.addProductToCategory')}
          </div>
        </button>
      </div>
    </div>
  );
};

export default ProductCards;
