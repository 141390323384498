import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import DailySalesCounts from 'components/Sales/DailySalesCounts/DailySalesCounts';
import DailySalesIncome from 'components/Sales/DailySalesIncome/DailySalesIncome';
import { SwitchSelector } from '@flexboxapps/flbx-webapp-ui';
import EmptyPage from 'components/EmptyPage/EmptyPage';
import SalesTable from 'components/Sales/SalesTable/SalesTable';
import { getSalePoints } from 'actions/salesActions';
import { findWarehouses } from 'actions/warehousesActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { useTranslation } from 'react-i18next';
import './sales-table-container.scss';
import useResize from 'services/useWindowResize';
import { createDailyTrade, numberWithCommas } from 'services/services';

const SalesItems = () => {
  const [tableView, setTableView] = useState<'list' | 'group'>('list');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useResize();
  const {
    salePoints,
    data: salesData,
    dailyTradeData,
    filters: { selectedDate, selectedSalePoint },
    isFetchingDailyTrade,
  } = useSelector(({ sales }: RootState) => (sales));

  useEffect(() => {
    dispatch(findWarehouses());
    dispatch(getSalePoints());
  }, []);

  if (selectedDate === null) {
    return null;
  }

  const createdDailyTradeData = createDailyTrade(dailyTradeData, salePoints, selectedSalePoint);

  const groupColumns: any = [
    {
      title: t('pages.sales.groupColumns.product'),
      dataIndex: 'productName',
      key: 'productName',
      width: width && width < 568 ? 180 : 220,
      sorter: (a: any, b: any) => (a.productName > b.productName ? 1 : -1),
    },
    {
      title: t('pages.sales.groupColumns.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      sorter: (a: any, b: any) => (a.quantity - b.quantity),
    },
    {
      title: t('pages.sales.groupColumns.unitPrice'),
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 200,
      align: 'right',
      sorter: (a: any, b: any) => (a.subTotal - b.subTotal),
    },
  ];

  const groupTableFooter = () => (
    <div className="table-footer-sum-container">
      <span>
        <strong>{t('pages.sales.summa').toUpperCase()}</strong>
      </span>
      <div className="table-footer-sum-content">
        <span className="sum-per-piece">
          {`${createdDailyTradeData.itemList.length} ${t('pages.sales.product')}`}
        </span>
        <span>
          <strong>{`${numberWithCommas(createdDailyTradeData.totalPrice)} Ft`}</strong>
        </span>
      </div>
    </div>
  );

  return (
    <div className="content-panel-container">
      {
        isFetchingDailyTrade
          ? (
            <EmptyPage
              title={t('pages.sales.emptyPage.loading')}
              icon={<FontAwesomeIcon key="fa-pencil" icon={faSpinnerThird} size="8x" spin />}
            />
          )
          : (
            <>
              {
                createdDailyTradeData.itemList.length === 0
                && (
                  <EmptyPage
                    title={t('pages.sales.emptyPage.title')}
                  />
                )
              }
              {
                createdDailyTradeData.itemList.length !== 0
                && (
                  <>
                    <div className="daily-sales-charts">
                      <DailySalesCounts sales={salesData} />
                      <DailySalesIncome sales={salesData} />
                    </div>
                    <div className="daily-sales-list">
                      <div className="sales-action-container">
                        <div className="list-main-title">{t('pages.sales.salesDatas')}</div>
                        <div className="sales-switch-selector-container">
                          <SwitchSelector
                            options={[
                              {
                                label: `${t('pages.sales.list')}`,
                                value: 'list',
                              },
                              {
                                label: `${t('pages.sales.group')}`,
                                value: 'group',
                              },
                            ]}
                            onChange={(value) => { setTableView(value); }}
                          />
                        </div>
                      </div>
                      {
                        {
                          list: <SalesTable />,
                          group: (
                            <Table
                              rowKey="id"
                              className="daily-sales-table"
                              columns={groupColumns}
                              dataSource={createdDailyTradeData.itemList}
                              pagination={false}
                              loading={isFetchingDailyTrade}
                              footer={groupTableFooter}
                              scroll={{ x: 624 }}
                            />
                          ),
                        }[tableView]
                      }
                    </div>
                  </>
                )
              }
            </>
          )
      }
    </div>
  );
};

export default SalesItems;
