import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Input, InputNumber, Switch } from '@flexboxapps/flbx-webapp-ui';
import { setCreateCategoryDrawer, setDiscountInput, createDiscount } from 'actions/discountActions';
import { RootState } from 'reducers/rootReducer';
import './add-discount-drawer.scss';

const AddDiscountDrawer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    createDrawerIsVisible, title, value, creatingInProgress, checked,
  } = useSelector(({ discount }: RootState) => discount);

  return (
    <Drawer
      className="app-drawer drawer-add-category flbx-app-drawer"
      title={t('drawers.addDiscount.title')}
      onClose={() => dispatch(setCreateCategoryDrawer(false))}
      visible={createDrawerIsVisible}
    >
      <div className="drawer-inner-container">
        <div className="drawer-input-container">
          <Input
            label={t('drawers.addDiscount.discountName').toUpperCase()}
            placeholder={t('drawers.addDiscount.discountNamePlaceholder')}
            value={title}
            onChange={(event) => { dispatch(setDiscountInput('title', event.target.value)); }}
          />
        </div>
        <div className="discount-drawer-switch-container">
          <InputNumber
            label={t('drawers.addDiscount.discountValue')}
            value={value}
            onChange={(inputValue) => { dispatch(setDiscountInput('value', inputValue)); }}
            prefix="%"
          />
          <Switch
            onChange={(checkedValue) => { dispatch(setDiscountInput('checked', checkedValue)); }}
            checked={checked}
            type="large"
            title={t('drawers.addDiscount.deprecatedTitle')}
          />
        </div>
      </div>
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={creatingInProgress}
          onClick={() => dispatch(setCreateCategoryDrawer(false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.create')}
          type="primary"
          disabled={title === '' || value === 0}
          loading={creatingInProgress}
          onClick={() => { dispatch(createDiscount()); }}
        />
      </div>
    </Drawer>
  );
};

export default AddDiscountDrawer;
