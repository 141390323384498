export enum StorageType {
  WHOLE = 'WHOLE',
  UNFOLDABLE = 'UNFOLDABLE',
  MEASURED = 'MEASURED',
}

export type Unit = {
  titles: {
    hu: string;
    en: string;
    [lang: string]: string;
  },
  _id: string;
  shortName: string;
  category: string;
};

export type WhProductOptions = {
  displayName: string;
  storageType?: StorageType;
  storageUnit?: Unit;
  storageQuantity?: number;
  perPieceUnit?: Unit;
  perPieceQuantity?: number;
  defaultQuantity?: number;
  hasVariations?: boolean;
  variationParent?: string;
  userId: string;
  createdAt: string;
  deprecated: boolean;
  updatedAt: string;
  parent: null;
  root: null;
  __v: number;
  _id: string;
};

export type WhProductState = {
  productOptions: WhProductOptions[],
  isFetching: boolean,
};

export const initialState: WhProductState = {
  productOptions: [],
  isFetching: false,
};
