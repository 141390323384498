import React, { FC, useEffect, useState, useRef } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { faUser, faKey } from '@fortawesome/pro-duotone-svg-icons';
import { IconInput, Button } from '@flexboxapps/flbx-webapp-ui';
import { login } from 'actions/userActions';
import { validatePassword, validateEmail } from 'services/services';

const LoginForm: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({
    emailError: false,
    passwordError: false,
  });
  const emailRef = useRef(email);
  const passwordRef = useRef(password);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { hasAuthenticatingError, isAuthenticating } = useSelector((store: RootStateOrAny) => store.user);

  const setEmailError = (emailValue: string) => {
    if (validateEmail(emailValue)) {
      setError({ ...error, emailError: validateEmail(emailValue) });
      return true;
    }
    setError({ ...error, emailError: false });
    return null;
  };

  const setPasswordError = (passwordValue: string) => {
    if (validatePassword(passwordValue)) {
      setError({ ...error, passwordError: validatePassword(passwordValue) });
      return true;
    }
    setError({ ...error, passwordError: false });
    return null;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      dispatch(login(emailRef.current, passwordRef.current, '/dashboard'));
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);
    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleLogin = () => {
    if (email && password && !setEmailError(email) && !setPasswordError(password)) {
      dispatch(login(email, password, '/dashboard'));
    }
  };

  return (
    <div className="login-container">
      <div className="input-container">
        {
          error.emailError && (
            <div className="error-message">
              {t('pages.login.errors.emailError')}
            </div>
          )
        }
        <IconInput
          value={email}
          placeholder={t('pages.login.inputs.email')}
          iconName={faUser}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (error.emailError) {
              setEmailError(e.target.value);
            }
            setEmail(e.target.value);
            emailRef.current = e.target.value;
          }}
          onBlur={() => { setEmailError(email); }}
          name="username"
        />
      </div>
      <div className="input-container">
        {
          error.passwordError && (
            <div className="error-message">
              {t('pages.login.errors.passwordError')}
            </div>
          )
        }
        <IconInput
          value={password}
          placeholder={t('pages.login.inputs.password')}
          iconName={faKey}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (error.passwordError) {
              setPasswordError(e.target.value);
            }
            setPassword(e.target.value);
            passwordRef.current = e.target.value;
          }}
          onBlur={() => { setPasswordError(password); }}
          type="password"
          name="password"
        />
      </div>
      <div className="login-button-container">
        {
          hasAuthenticatingError && (
            <div className="error-message">
              {t('pages.login.errors.badCredentials')}
            </div>
          )
        }
        <Button
          label={t('pages.login.inputs.login')}
          onClick={handleLogin}
          type="primary"
          loading={isAuthenticating}
          disabled={error.emailError || error.passwordError}
        />
      </div>
    </div>
  );
};

export default LoginForm;
