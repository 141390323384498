import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { ActionButton, Input, InputNumber, TreeSelect } from '@flexboxapps/flbx-webapp-ui';
import ProductPicture from 'components/Product/ProductPicture/ProductPicture';
import ColumnLayoutBlock from 'layouts/ColumnLayout/ColumnLayoutBlock';
import ColumnLayout from 'layouts/ColumnLayout/ColumnLayout';
import './product-details.scss';

type ProductDetailsBlockProps = {
  categoryTree: any[],
  product: {
    titles: { hu: string, en: string },
    printTitles: { hu: string, en: string },
    price: {
      HUF: number,
    },
    categories: any[],
    image: any,
    takeaway?: string,
    dinein?: string,
    taxValueExceptions: any[],
  },
  openUploadModal: () => void,
  editProductField: (key: string, value: string | string[] | object | null | boolean) => void,
};

const ProductDetailsBlock: FC<ProductDetailsBlockProps> = ({
  categoryTree,
  product: { titles, printTitles, price, categories, image },
  openUploadModal, editProductField,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <ColumnLayoutBlock title={t('pages.newProduct.basicData')}>
        <ColumnLayout>
          <div className="image-details-block">
            <ProductPicture image={image} />
            <div className="image-details-image-block">
              {
                image
                  ? (
                    <ActionButton
                      icon={<FontAwesomeIcon key="fa-trash-alt" icon={faTrashAlt} color="#e74c3c" />}
                      label={t('pages.newProduct.removePicture')}
                      onClick={() => editProductField('image', null)}
                      sticky
                    />
                  )
                  : (
                    <ActionButton
                      icon={<FontAwesomeIcon key="fa-plus" icon={faPlus} color="#34495e" />}
                      label={t('pages.newProduct.addPicture')}
                      onClick={openUploadModal}
                      sticky
                    />
                  )
              }
            </div>
          </div>
        </ColumnLayout>
        <ColumnLayout>
          <Input
            label={t('pages.products.labels.titles.hu').toUpperCase()}
            placeholder={t('pages.products.inputs.titles.hu')}
            value={titles.hu}
            onChange={(e) => editProductField('titles', {
              ...titles,
              hu: e.target.value,
            })}
          />
          <Input
            label={t('pages.products.labels.printTitles.hu').toUpperCase()}
            placeholder={t('pages.products.inputs.printTitles.hu')}
            value={printTitles.hu}
            onChange={(e) => editProductField('printTitles', {
              ...printTitles,
              hu: e.target.value,
            })}
          />
          <InputNumber
            label={t('pages.products.inputs.price').toUpperCase()}
            value={price.HUF}
            onChange={(value) => editProductField('price', { HUF: value })}
            max={10000000}
            step={1}
            min={0}
            prefix="Ft"
          />
          <TreeSelect
            label={t('pages.products.inputs.selectedCategory').toUpperCase()}
            placeholder={t('pages.products.inputs.selectedCategory')}
            value={categories[0] || 'root'}
            treeData={[{
              title: t('drawers.addProduct.mainCategory'),
              value: 'root',
              children: categoryTree,
            }]}
            onChange={(value) => editProductField('categories', [value])}
            size="large"
          />
        </ColumnLayout>
      </ColumnLayoutBlock>
    </div>
  );
};

export default ProductDetailsBlock;
