import { WhProductOptions } from 'reducers/whProducts/initialState';

export const LIST_WH_PRODUCTS_R = 'LIST_WH_PRODUCTS_R';
export interface ListWhProductsRequested {
  type: typeof LIST_WH_PRODUCTS_R,
}

export const LIST_WH_PRODUCTS_S = 'LIST_WH_PRODUCTS_S';
export interface ListWhProductsSucceeded {
  type: typeof LIST_WH_PRODUCTS_S,
  payload: {
    productOptions: WhProductOptions[],
  },
}

export const LIST_WH_PRODUCTS_F = 'LIST_WH_PRODUCTS_F';
export interface ListWhProductsFailed {
  type: typeof LIST_WH_PRODUCTS_F,
}

export type WhProductActionTypes =
  | ListWhProductsRequested
  | ListWhProductsSucceeded
  | ListWhProductsFailed;
