import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './assign-type.scss';

export type AssignTypeProps = {
  assignKey: string,
  icon: IconProp,
  title: string,
  onClick: () => void,
};

const AssignType: FC<AssignTypeProps> = ({ icon, title, onClick }) => (
  <div className="assign-type" role="button" tabIndex={0} onClick={onClick}>
    <div className="assign-type-icon">
      <FontAwesomeIcon icon={icon} size="3x" color="#6945e0" />
    </div>
    <div className="assign-type-title">{title}</div>
  </div>
);

export default AssignType;
