import React, { FC } from 'react';
import './column-layout-props.scss';

type ColumnLayoutProps = {
  lastItemWidth?: number,
  children: React.ReactNode | React.ReactNode[],
};

const ColumnLayout: FC<ColumnLayoutProps> = ({ lastItemWidth = 'unset', children }) => {
  const isElementArray = Array.isArray(children);

  return (
    <>
      {
        children && !isElementArray
        && (
          <div className="column-layout-body">{children}</div>
        )
      }

      {
        children && Array.isArray(children)
        && (
          <div className="column-layout-body">
            {
              children.map((childItem: React.ReactNode, index: number) => (
                <div
                  className="column-layout-body-child"
                  style={{
                    maxWidth: index === children.length - 1 ? lastItemWidth : 'unset',
                  }}
                >
                  {childItem}
                </div>
              ))
            }
          </div>
        )
      }
    </>
  );
};

export default ColumnLayout;
