import React, { FC } from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons';
import './empty-page.scss';

type EmptyPageProps = {
  title: string,
  icon?: JSX.Element,
  description?: string,
  actionTitle?: string | null,
  handleClick?: () => void
};

const EmptyPage: FC<EmptyPageProps> = ({
  title,
  icon = <FontAwesomeIcon icon={faEmptySet} size="8x" />,
  description = '',
  actionTitle = null,
  handleClick = () => { },
}) => (
  <div className="empty-page">
    <div className="empty-page-icon">{icon}</div>
    <div className="empty-page-title">{title}</div>
    <div className="empty-page-description">{description}</div>
    {
      actionTitle
      && (
        <div className="empty-page-action">
          <Button type="primary" onClick={handleClick}>{actionTitle}</Button>
        </div>
      )
    }
  </div>
);

export default EmptyPage;
