export const POS_CAT_LIST_R = 'POS_CAT_LIST_R';
export interface POSCategoryListRequested {
  type: typeof POS_CAT_LIST_R,
}

export const POS_CAT_LIST_S = 'POS_CAT_LIST_S';
export interface POSCategoryListSucceed {
  type: typeof POS_CAT_LIST_S,
  payload: { data: any },
}

export const POS_CAT_LIST_F = 'POS_CAT_LIST_F';

export const POS_CAT_TOGGLE_CREATE_MODAL = 'POS_CAT_TOGGLE_CREATE_MODAL';
export interface POSCategoryToggleCreateModal {
  type: typeof POS_CAT_TOGGLE_CREATE_MODAL,
  payload: {
    createDrawerIsVisible: boolean,
  },
}

export const POS_CAT_TOGGLE_EDIT_MODAL = 'POS_CAT_TOGGLE_EDIT_MODAL';
export interface POSCategoryToggleEditModal {
  type: typeof POS_CAT_TOGGLE_EDIT_MODAL,
  payload: {
    editorDrawerIsVisible: boolean,
  },
}

export const POS_CAT_SELECT_CATEGORY = 'POS_CAT_SELECT_CATEGORY';
export interface POSCategorySelectCategory {
  type: typeof POS_CAT_SELECT_CATEGORY,
  payload: {
    key: string,
  },
}

export const POS_CAT_EDIT_CATEGORY = 'POS_CAT_EDIT_CATEGORY';
export interface POSCategoryEditCategory {
  type: typeof POS_CAT_EDIT_CATEGORY,
  payload: {
    key: string,
    field: string,
    value: string | number | any[],
  },
}

export const POS_CAT_EDIT_NEW_CATEGORY = 'POS_CAT_EDIT_NEW_CATEGORY';
export interface POSCategoryEditNewCategory {
  type: typeof POS_CAT_EDIT_NEW_CATEGORY,
  payload: {
    field: string,
    value: string | number | any[],
  },
}

export const POS_CAT_UPDATE_CATEGORY_R = 'POS_CAT_UPDATE_CATEGORY_R';
export interface POSCategoryUpdateCategoryRequested {
  type: typeof POS_CAT_UPDATE_CATEGORY_R,
  payload: {
    key: string,
  },
}

export const POS_CAT_UPDATE_CATEGORY_S = 'POS_CAT_UPDATE_CATEGORY_S';
export interface POSCategoryUpdateCategorySucceeded {
  type: typeof POS_CAT_UPDATE_CATEGORY_S,
  payload: { key: string, data: any },
}

export const POS_CAT_UPDATE_CATEGORY_F = 'POS_CAT_UPDATE_CATEGORY_F';
export interface POSCategoryUpdateCategoryFailed {
  type: typeof POS_CAT_UPDATE_CATEGORY_F,
}

export const POS_CAT_CREATE_CATEGORY_R = 'POS_CAT_CREATE_CATEGORY_R';
export interface POSCategoryCreateCategoryRequested {
  type: typeof POS_CAT_CREATE_CATEGORY_R,
}

export const POS_CAT_CREATE_CATEGORY_S = 'POS_CAT_CREATE_CATEGORY_S';
export interface POSCategoryCreateCategorySucceeded {
  type: typeof POS_CAT_CREATE_CATEGORY_S,
  payload: { newCategoryItem: any }
}

export const POS_CAT_CREATE_CATEGORY_F = 'POS_CAT_CREATE_CATEGORY_F';
export interface POSCategoryCreateCategoryFailed {
  type: typeof POS_CAT_CREATE_CATEGORY_F,
}

export const POS_CAT_REORDER_R = 'POS_CAT_REORDER_R';
export interface POSCategoryReorder {
  type: typeof POS_CAT_REORDER_R,
  payload: {
    order: string[];
  },
}

export const POS_CAT_REORDER_S = 'POS_CAT_REORDER_S';
export interface POSCategoryReorderSucceeded {
  type: typeof POS_CAT_REORDER_S,
}

export const POS_CAT_REORDER_F = 'POS_CAT_REORDER_F';
export interface POSCategoryReorderFailed {
  type: typeof POS_CAT_REORDER_F,
}

export type CategoryActionTypes = POSCategoryListRequested | POSCategoryToggleCreateModal
| POSCategoryToggleEditModal | POSCategorySelectCategory
| POSCategoryEditCategory | POSCategoryUpdateCategoryRequested
| POSCategoryEditNewCategory | POSCategoryCreateCategoryRequested
| POSCategoryListSucceed | POSCategoryToggleCreateModal
| POSCategoryCreateCategorySucceeded | POSCategoryCreateCategoryFailed
| POSCategoryUpdateCategorySucceeded | POSCategoryUpdateCategoryFailed
| POSCategoryReorder | POSCategoryReorderSucceeded
| POSCategoryReorderFailed;
