import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RightArrow } from 'assets/right-arrow.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket } from '@fortawesome/pro-duotone-svg-icons';
import { numberWithCommas } from 'services/services';
import './sales-card.scss';

type SalesCardProps = {
  title: string,
  occasion: number,
  income: number,
  onClick: () => void,
  currency: string;
};

const SalesCard: FC<SalesCardProps> = ({ title, occasion, income, onClick, currency }) => {
  const { t } = useTranslation();
  return (
    <div className="flbx-sales-card">
      <div
        role="button"
        tabIndex={0}
        onClick={onClick}
        className="flbx-sales-card-button"
      >
        {title}
        <RightArrow />
      </div>
      <div className="flbx-sales-content-container">
        <div className="flbx-sales-content-title-container">
          <div className="flbx-sales-icon-container">
            <FontAwesomeIcon icon={faShoppingBasket} color="white" />
          </div>
          {t('pages.sales.numberOfPurchases')}
        </div>
        <div className="flbx-sales-content">
          {`${occasion} ${t('pages.sales.occasion')}`}
        </div>
        <div className="flbx-sales-content-title-container bordered">
          <div className="flbx-sales-icon-container income">
            <FontAwesomeIcon icon={faShoppingBasket} color="white" />
          </div>
          {t('pages.sales.allIncome')}
        </div>
        <div className="flbx-sales-content income">
          {`${numberWithCommas(income)} ${currency}`}
        </div>
      </div>
    </div>
  );
};

export default SalesCard;
