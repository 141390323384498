import { takeEvery, call, put, select, delay } from 'redux-saga/effects';
import {
  POS_CAT_LIST_R, POS_CAT_LIST_S, POS_CAT_LIST_F, POSCategoryListRequested,
  POS_CAT_UPDATE_CATEGORY_R, POS_CAT_UPDATE_CATEGORY_S, POS_CAT_UPDATE_CATEGORY_F, POSCategoryUpdateCategoryRequested,
  POS_CAT_CREATE_CATEGORY_R, POS_CAT_CREATE_CATEGORY_S, POS_CAT_CREATE_CATEGORY_F, POSCategoryCreateCategoryRequested,
  POS_CAT_REORDER_R, POS_CAT_REORDER_S, POS_CAT_REORDER_F, POSCategoryReorder,
} from 'consts/productCategoriesActionTypes';
import * as API from 'services/api';
import { RootState } from 'reducers/rootReducer';

const getProductCategoriesStore = (state: RootState) => state.productCategories;

function* findProductCategories(action: POSCategoryListRequested) {
  try {
    const query = {
      $params: JSON.stringify({
        deprecated: false,
      }),
      $sort: JSON.stringify({
        createdAt: 1,
      }),
    };
    const { data } = yield call(API.findDataService, 'pos-categories', '/pos-categories', query);
    yield put({
      type: POS_CAT_LIST_S,
      payload: {
        data,
      },
    });
  } catch (error) {
    if (error.response.status === 502) {
      yield put(action);
    } else {
      console.log(error); // eslint-disable-line
      yield put({
        type: POS_CAT_LIST_F,
      });
    }
  }
}

export function* updateProductCategory(action: POSCategoryUpdateCategoryRequested): any {
  try {
    const store = yield select(getProductCategoriesStore);
    const isRootElement = store.editableData[action.payload.key].parent === null;
    const parentElement = isRootElement
      ? null
      : store.data[store.editableData[action.payload.key].parent];
    const data = {
      titles: store.editableData[action.payload.key].titles,
      parent: isRootElement ? null : store.editableData[action.payload.key].parent,
      color: store.editableData[action.payload.key].color,
      level: isRootElement ? 0 : parentElement.level + 1,
      index: isRootElement
        ? 0
        : Object.values(store.data).filter((category: any) => (category.parent === parentElement._id)).length,
    };
    const response = yield call(API.patchDataService, 'pos-categories', '/pos-categories', action.payload.key, data);
    yield put({
      type: POS_CAT_UPDATE_CATEGORY_S,
      payload: {
        key: action.payload.key,
        data: response.data,
      },
    });
  } catch (error) {
    if (error.response.status === 502) {
      yield put(action);
    } else {
      console.log(error); // eslint-disable-line
      yield put({
        type: POS_CAT_UPDATE_CATEGORY_F,
      });
    }
  }
}

export function* createProductCategory(action: POSCategoryCreateCategoryRequested): any {
  try {
    const store = yield select(getProductCategoriesStore);
    const titles: { [key: string]: any } = {};
    Object.keys(store.newCategory.titles).forEach((key: string) => {
      titles[key] = store.newCategory.titles[key].trim();
    });
    const isRootElement = store.newCategory.parent === 'root' || store.newCategory.parent === null;
    const data = {
      parent: isRootElement ? null : store.newCategory.parent,
      level: isRootElement ? 0 : store.data[store.newCategory.parent].level + 1,
      color: store.newCategory.color,
      index: store.selectedCategory
        ? Math.max(...Object.keys(store.data)
          .filter((key) => store.data[key].parent === store.selectedCategory._id)
          .map((key) => store.data[key].index), 0) + 1
        : Math.max(...Object.keys(store.data)
          .filter((key) => store.data[key].parent === null)
          .map((key) => store.data[key].index), 0) + 1,
      titles,
    };

    const newCategoryItem = yield call(API.createDataService, 'pos-categories', '/pos-categories', data);

    yield delay(500);
    yield put({
      type: POS_CAT_CREATE_CATEGORY_S,
      payload: {
        newCategoryItem,
      },
    });
    yield put({
      type: POS_CAT_LIST_R,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    if (error.response.status === 502) {
      yield put(action);
    } else {
      yield put({
        type: POS_CAT_CREATE_CATEGORY_F,
      });
    }
  }
}

function* categoryReorder(action: POSCategoryReorder) {
  try {
    const { order } = action.payload;
    yield call(API.patchWithoutIdService, 'pos-categories-order', '/pos-categories-order', { order });
    yield put({
      type: POS_CAT_LIST_R,
    });
    yield put({
      type: POS_CAT_REORDER_S,
    });
  } catch (error) {
    if (error.response.status === 502) {
      yield put(action);
    } else {
      console.log(error); // eslint-disable-line
      yield put({
        type: POS_CAT_REORDER_F,
      });
    }
  }
}

export const productCategoriesSagas = [
  takeEvery(POS_CAT_LIST_R, findProductCategories),
  takeEvery(POS_CAT_UPDATE_CATEGORY_R, updateProductCategory),
  takeEvery(POS_CAT_CREATE_CATEGORY_R, createProductCategory),
  takeEvery(POS_CAT_REORDER_R, categoryReorder),
];
