import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Button, Drawer, ActionButton } from '@flexboxapps/flbx-webapp-ui';
import ProductPicture from 'components/Product/ProductPicture/ProductPicture';
import EditProductDrawer from 'components/Product/EditProductDrawer/EditProductDrawer';
import { setProductProfileDrawer } from 'actions/posProductsActions';
import { RootState } from 'reducers/rootReducer';
import { history } from 'services/config';
import './product-profile-drawer.scss';

type ProductProfileDrawerProps = {
  categoryTree: any[],
};

const ProductProfileDrawer: FC<ProductProfileDrawerProps> = ({ categoryTree }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const posProductsStore = useSelector(({ posProducts }: RootState) => posProducts);
  const { selectedCategory: { color: categoryColor } } = useSelector(({ productCategories }: RootState) => productCategories);
  return (
    <Drawer
      className="app-drawer drawer-product-profile drawer-add-category"
      title={t('drawers.addProduct.productDataSheet')}
      onClose={() => dispatch(setProductProfileDrawer(false))}
      visible={posProductsStore.profileDrawerIsVisible}
    >
      <>
        {
          posProductsStore.selectedProduct
            ? (
              <>
                {
                  posProductsStore.editedProduct
                  && (
                    <EditProductDrawer categoryTree={categoryTree} />
                  )
                }
                <div
                  className="product-profile-header"
                  style={{
                    background: `linear-gradient(-3deg, ${categoryColor} 40%, white 41%)`,
                  }}
                >
                  <ProductPicture image={posProductsStore.selectedProduct.image} bordered />
                  <div className="product-details">
                    <div className="product-title">
                      {posProductsStore.selectedProduct.titles.hu}
                    </div>
                    <div className="product-price">
                      <div className="price-tag">
                        {posProductsStore.selectedProduct.price ? `${posProductsStore.selectedProduct.price.HUF} Ft` : '???'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="products-action-header" style={{ backgroundColor: categoryColor }}>
                  <ActionButton
                    icon={<FontAwesomeIcon key="fa-pencil" icon={faPencil} color="#34495e" />}
                    label={t('app.common.buttons.edit')}
                    onClick={() => history.push(`/products/${posProductsStore.selectedProduct._id}/edit`)}
                  />
                </div>
                <div className="drawer-actions">
                  <Button
                    label={t('app.common.buttons.close')}
                    disabled={posProductsStore.isProcessing}
                    onClick={() => dispatch(setProductProfileDrawer(false))}
                    type="ghost"
                  />
                </div>
              </>
            )
            : null
        }
      </>
    </Drawer>
  );
};

export default ProductProfileDrawer;
