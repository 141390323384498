import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons';

type ProductCardProps = {
  index: number;
  item: any;
  categoryColor: string;
  handleClick: (id: string) => void;
};

const ProductCard: FC<ProductCardProps> = (props) => {
  const { index, item, categoryColor, handleClick } = props;
  return (
    <div
      className="pos-category-product-card"
      tabIndex={index + 1}
      role="button"
      data-category={categoryColor}
      onClick={() => handleClick(item._id)}
    >
      <div className="pos-category-color" style={{ backgroundColor: categoryColor }} />
      <div
        className="product-card-image"
        style={{ backgroundImage: item.image ? `url(${item.image.url})` : 'none' }}
      >
        {
          !item.image
          && (
            <FontAwesomeIcon key="fa-empty-set" icon={faEmptySet} size="2x" />
          )
        }
      </div>
      <div className="product-card-content">
        <div className="product-card-content-row title">{item.titles.hu}</div>
        <div className="product-card-content-row price">
          <div className="price-tag">
            {item.price ? `${item.price.HUF} Ft` : '#ERROR#'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
