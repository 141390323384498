import {
  TaxrateActionTypes,
  GET_TAXRATE_S,
  SET_TAXRATE_INPUT,
  CREATE_TAXRATE_R,
  CREATE_TAXRATE_S,
  CREATE_TAXRATE_F,
} from 'consts/taxRateActionTypes';
import { initialState, TaxRateState } from './initialState';

const taxrateReducer = (state = initialState, action: TaxrateActionTypes): TaxRateState => {
  switch (action.type) {
    case GET_TAXRATE_S: {
      const { taxrates } = action.payload;
      return {
        ...state,
        taxrates,
      };
    }

    case SET_TAXRATE_INPUT: {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }

    case CREATE_TAXRATE_R:
      return {
        ...state,
        creatingInProgress: true,
      };

    case CREATE_TAXRATE_S:
      return {
        ...state,
        creatingInProgress: false,
        createDrawerIsVisible: false,
      };

    case CREATE_TAXRATE_F:
      return {
        ...state,
        creatingInProgress: false,
      };

    default:
      return state;
  }
};

export default taxrateReducer;
