import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Input } from '@flexboxapps/flbx-webapp-ui';
import { setCreateLocationDrawer, setLocationInput, createLocation } from 'actions/locationActions';
import { RootState } from 'reducers/rootReducer';
import './add-location-drawer.scss';

const AddLocationDrawer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    createDrawerIsVisible, title, creatingInProgress,
  } = useSelector(({ location }: RootState) => location);

  return (
    <Drawer
      className="app-drawer drawer-add-category"
      title={t('drawers.addLocation.title')}
      onClose={() => dispatch(setCreateLocationDrawer(false))}
      visible={createDrawerIsVisible}
    >
      <div className="drawer-inner-container">
        <div className="location-drawer-input-container">
          <Input
            label={t('drawers.addLocation.locationName').toUpperCase()}
            placeholder={t('drawers.addLocation.locationNamePlaceholder')}
            value={title}
            onChange={(event) => { dispatch(setLocationInput('title', event.target.value)); }}
          />
        </div>
      </div>
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={creatingInProgress}
          onClick={() => dispatch(setCreateLocationDrawer(false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.create')}
          type="primary"
          disabled={title === ''}
          loading={creatingInProgress}
          onClick={() => { dispatch(createLocation()); }}
        />
      </div>
    </Drawer>
  );
};

export default AddLocationDrawer;
