import {
  POS_CAT_LIST_R, POSCategoryListRequested,
  POS_CAT_SELECT_CATEGORY, POSCategorySelectCategory,
  POS_CAT_EDIT_CATEGORY, POSCategoryEditCategory,
  POS_CAT_EDIT_NEW_CATEGORY, POSCategoryEditNewCategory,
  POS_CAT_UPDATE_CATEGORY_R, POSCategoryUpdateCategoryRequested,
  POS_CAT_CREATE_CATEGORY_R, POSCategoryCreateCategoryRequested,
  POS_CAT_TOGGLE_CREATE_MODAL, POSCategoryToggleCreateModal,
  POS_CAT_TOGGLE_EDIT_MODAL, POSCategoryToggleEditModal,
  POS_CAT_REORDER_R, POSCategoryReorder,
} from 'consts/productCategoriesActionTypes';

export const getProductCategories = (): POSCategoryListRequested => ({
  type: POS_CAT_LIST_R,
});

export const setCreateCategoryDrawer = (createDrawerIsVisible = true): POSCategoryToggleCreateModal => ({
  type: POS_CAT_TOGGLE_CREATE_MODAL,
  payload: {
    createDrawerIsVisible,
  },
});

export const setEditCategoryDrawer = (editorDrawerIsVisible = true): POSCategoryToggleEditModal => ({
  type: POS_CAT_TOGGLE_EDIT_MODAL,
  payload: {
    editorDrawerIsVisible,
  },
});

export const selectProductCategory = (key: any): POSCategorySelectCategory => ({
  type: POS_CAT_SELECT_CATEGORY,
  payload: {
    key,
  },
});

export const editProductCategory = (key: string, field: string, value: string): POSCategoryEditCategory => ({
  type: POS_CAT_EDIT_CATEGORY,
  payload: {
    key,
    field,
    value,
  },
});

export const updateCategoryTitles = (key: string): POSCategoryUpdateCategoryRequested => ({
  type: POS_CAT_UPDATE_CATEGORY_R,
  payload: {
    key,
  },
});

export const updateNewCategory = (field: string, value: string): POSCategoryEditNewCategory => ({
  type: POS_CAT_EDIT_NEW_CATEGORY,
  payload: {
    field,
    value,
  },
});

export const createCategory = (): POSCategoryCreateCategoryRequested => ({
  type: POS_CAT_CREATE_CATEGORY_R,
});

export const categoryReorder = (order: string[]): POSCategoryReorder => ({
  type: POS_CAT_REORDER_R,
  payload: {
    order,
  },
});
