import React, { FC, useState } from 'react';
import { SelectValue } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import { faPlus, faBoxAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import { Select, InputNumber } from '@flexboxapps/flbx-webapp-ui';
import { StorageType, WhProductOptions } from 'reducers/whProducts/initialState';
import ColumnLayoutBlock from 'layouts/ColumnLayout/ColumnLayoutBlock';
import ColumnLayout from 'layouts/ColumnLayout/ColumnLayout';
import AssignTypeSelector from 'components/AssignTypeSelector/AssignTypeSelector';
import './assign-warehouse-product-block.scss';

export enum AssignType {
  ASSIGN_TYPE_NEW = 'ASSIGN_TYPE_NEW',
  ASSIGN_TYPE_EXIST = 'ASSIGN_TYPE_EXIST',
}

interface AssignWarehouseProductBlockProps {
  productOptions: WhProductOptions[],
  editProductField: (key: string, value: string | string[] | SelectValue) => void,
  ingredients: any,
}

const AssignWarehouseProductBlock: FC<AssignWarehouseProductBlockProps> = ({ productOptions = [], editProductField, ingredients }) => {
  const { t } = useTranslation();
  const [assignmentType, setAssignmentType] = useState<AssignType>(AssignType.ASSIGN_TYPE_EXIST);
  const [inputValue, setValue] = useState<undefined | string>(undefined);
  const renderStoregeTypeOptions = (storageType: string) => {
    if (storageType === StorageType.WHOLE) {
      return [
        { label: t(`pages.newProduct.type.${StorageType.WHOLE}`), value: StorageType.WHOLE },
      ];
    }

    if (storageType === StorageType.MEASURED) {
      return [
        { label: t(`pages.newProduct.type.${StorageType.MEASURED}`), value: StorageType.MEASURED },
      ];
    }

    if (storageType === StorageType.UNFOLDABLE) {
      return [
        { label: t(`pages.newProduct.type.${StorageType.WHOLE}`), value: StorageType.WHOLE },
        { label: t(`pages.newProduct.type.${StorageType.UNFOLDABLE}`), value: StorageType.UNFOLDABLE },
      ];
    }

    return [{ label: t(`pages.newProduct.type.${StorageType.WHOLE}`), value: StorageType.WHOLE }];
  };

  const getUnitLabel = (productId: string) => {
    const currentProduct = ingredients[productId];

    if (currentProduct.selectedStorageType === StorageType.MEASURED) {
      return currentProduct.perPieceUnit.shortName;
    }

    if (currentProduct.selectedStorageType === StorageType.WHOLE) {
      return currentProduct.storageUnit.shortName;
    }

    if (currentProduct.selectedStorageType === StorageType.UNFOLDABLE) {
      return currentProduct.perPieceUnit.shortName;
    }

    return '';
  };

  const handleSelectIngredient = (value: SelectValue) => {
    const selectedItem: WhProductOptions | undefined = productOptions.find(({ _id }) => (_id === value));

    if (selectedItem) {
      editProductField('ingredients', {
        ...ingredients,
        [selectedItem._id]: {
          ...selectedItem,
          amount: 1,
          selectedStorageType: selectedItem.storageType,
        },
      });
    }
  };

  const handleRemoveIngredient = (productId: string) => {
    const currentIngredients: any = { ...ingredients };
    delete currentIngredients[productId];

    editProductField('ingredients', currentIngredients);
  };

  const handleSelectTypeNew = () => {
    setAssignmentType(AssignType.ASSIGN_TYPE_NEW);
  };

  const handleSelectTypeExist = () => {
    setAssignmentType(AssignType.ASSIGN_TYPE_EXIST);
  };

  const handleSelectedValueChange = (productId: string, field: string, value?: string | number | undefined) => {
    const selectedItem: WhProductOptions | undefined = ingredients[productId];

    if (selectedItem) {
      editProductField('ingredients', {
        ...ingredients,
        [selectedItem._id]: {
          ...selectedItem,
          [field]: value,
        },
      });
    }
  };

  const parentItems = productOptions && productOptions
    .filter((optionItem) => !optionItem.hasVariations && !optionItem.variationParent)
    .map((childItem) => ({
      label: childItem.displayName,
      value: childItem._id,
      disabled: Object.entries(ingredients).map(([, values]: any) => values._id).includes(childItem._id),
    }));

  const parents = productOptions.filter((parentItem) => parentItem.hasVariations);
  const groupedOptions: any[] = parents.map((parentItem) => ({
    label: parentItem.displayName,
    value: parentItem._id,
    group: productOptions.filter((productItem) => parentItem.root === productItem.variationParent).map((childItem) => ({
      label: childItem.displayName,
      value: childItem._id,
      disabled: Object.entries(ingredients).map(([, values]: any) => values._id).includes(childItem._id),
    })),
  }));

  const options = parentItems.concat(groupedOptions);
  return (
    <div className="assign-warehouse-product-block">
      {
        !assignmentType
        && (
          <AssignTypeSelector
            types={[
              { assignKey: AssignType.ASSIGN_TYPE_NEW, icon: faPlus, title: t('pages.newProduct.newStockItem'), onClick: handleSelectTypeNew },
              { assignKey: AssignType.ASSIGN_TYPE_EXIST, icon: faBoxAlt, title: t('pages.newProduct.selectStockItem'), onClick: handleSelectTypeExist },
            ]}
          />
        )
      }

      {
        assignmentType === AssignType.ASSIGN_TYPE_EXIST
        && (
          <ColumnLayoutBlock title={t('pages.newProduct.handleStock')}>
            {
              Object.entries(ingredients).map(([, item]: any, index) => (
                <ColumnLayout lastItemWidth={30} key={index.toString()}>
                  <div className="ingredient-container">
                    <div className="ingredient-divider">
                      <img src={item.productImageUrl} className="ingredient-image" alt="ingredient-img" />
                    </div>
                    <div>{item.displayName}</div>
                  </div>

                  <Select
                    label={t('pages.newProduct.stockType')}
                    options={renderStoregeTypeOptions(item.storageType)}
                    onChange={(value) => {
                      handleSelectedValueChange(item._id, 'selectedStorageType', value ? value.toString() : value);
                    }}
                    value={item.selectedStorageType}
                    size="large"
                  />

                  <InputNumber
                    label={t('pages.newProduct.quantity')}
                    disabled={!item}
                    value={item.amount}
                    min={1}
                    onChange={(value) => {
                      if (item && typeof value === 'number') {
                        handleSelectedValueChange(item._id, 'amount', value);
                      }
                    }}
                    prefix={` ${getUnitLabel(item._id)}`}
                  />

                  <button
                    type="button"
                    onClick={() => handleRemoveIngredient(item._id)}
                    style={{ float: 'right' }}
                  >
                    <FontAwesomeIcon icon={faMinusCircle} style={{ fontSize: 22 }} color="red" />
                  </button>
                </ColumnLayout>
              ))
            }

            <Select
              label={t('pages.newProduct.chooseProduct')}
              placeholder={t('pages.newProduct.chooseProductPlaceholder')}
              options={options}
              onChange={(value) => {
                handleSelectIngredient(value);
                setValue('');
              }}
              value={inputValue}
              size="large"
              isGroup
              showSearch
            />
          </ColumnLayoutBlock>
        )
      }
    </div>
  );
};

export default AssignWarehouseProductBlock;
