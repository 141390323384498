import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons';
import './product-picture.scss';

type ProductPictureProps = {
  image?: any,
  bordered?: boolean,
};

const ProductPicture: FC<ProductPictureProps> = ({ image = null, bordered = false }) => (
  <div
    className={`product-picture${bordered ? ' bordered' : ''}`}
    style={{ backgroundImage: image && image.url ? `url(${image.url})` : 'none' }}
  >
    {
      !image
      && (
        <FontAwesomeIcon key="fa-empty-set" icon={faEmptySet} size="3x" />
      )
    }
  </div>
);

export default ProductPicture;
