import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { ActionButton } from '@flexboxapps/flbx-webapp-ui';
import { RootState } from 'reducers/rootReducer';
import { selectProductCategory, setCreateCategoryDrawer, setEditCategoryDrawer } from 'actions/productCategoriesActions';
import { setProductProfileDrawer } from 'actions/posProductsActions';
import CategoryCards from 'components/Category/CategoryCards/CategoryCards';
import ProductCards from 'components/Category/ProductCards/ProductCards';
import AddCategoryDrawer from 'components/Category/AddCategoryDrawer/AddCategoryDrawer';
import EditCategoryDrawer from 'components/Category/EditCategoryDrawer/EditCategoryDrawer';
import AddProductDrawer from 'components/Product/AddProductDrawer/AddProductDrawer';
import ProductProfileDrawer from 'components/Product/ProductProfileDrawer/ProductProfileDrawer';
import './category-editor.scss';
import { history } from 'services/config';

type CategoryEditorProps = {
  categoryTree: any[],
};

const CategoryEditor: FC<CategoryEditorProps> = ({ categoryTree }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const renderBreadcrumbItems = (selectedItem: any, categories: any): any[] => {
    const breadcrumbs = [];
    let currentKey = selectedItem ? selectedItem._id : null;

    while (currentKey !== null) {
      breadcrumbs.push(categories[currentKey]);
      currentKey = categories[currentKey].parent;
    }

    return breadcrumbs.reverse();
  };

  const { lang: appLang } = useSelector(({ app }: RootState) => app);
  const { data, selectedCategory } = useSelector(({ productCategories }: RootState) => productCategories);
  const { productCategories: categoryProducts } = useSelector(({ posProducts }: RootState) => posProducts);
  const parentId = selectedCategory ? selectedCategory._id : null;
  const childCategories = data ? Object.keys(data).filter((key) => data[key].parent === parentId) : [];
  return (
    <div className="category-editor">
      <AddCategoryDrawer categoryTree={categoryTree} />
      <AddProductDrawer categoryTree={categoryTree} />
      {
        selectedCategory
        && (
          <>
            <EditCategoryDrawer categoryTree={categoryTree} />
            <ProductProfileDrawer categoryTree={categoryTree} />
          </>
        )
      }
      <div className="category-editor-header-container">
        <div className="category-editor-header">
          <Breadcrumb>
            <Breadcrumb.Item key="root-categories">
              <button
                className="breadcrumb-button"
                type="button"
                onClick={() => {
                  dispatch(selectProductCategory(null));
                }}
              >
                {t('pages.products.labels.mainCategories')}
              </button>
            </Breadcrumb.Item>
            {
              renderBreadcrumbItems(selectedCategory, data).map((item) => (
                <Breadcrumb.Item key={item._id}>
                  <button
                    className="breadcrumb-button"
                    type="button"
                    onClick={() => {
                      dispatch(selectProductCategory([item._id]));
                    }}
                  >
                    {
                      appLang === 'en-US'
                        ? item.titles.en || item.titles.hu
                        : item.titles.hu || item.titles.en
                    }
                  </button>
                </Breadcrumb.Item>
              ))
            }
          </Breadcrumb>
          <div className="category-editor-header-actions">
            {
              selectedCategory
              && (
                <ActionButton
                  icon={<FontAwesomeIcon key="fa-pencil" icon={faPencil} />}
                  label="Kategória szerkesztése"
                  onClick={() => dispatch(setEditCategoryDrawer(true))}
                  sticky
                  primary
                />
              )
            }
          </div>
        </div>
        {
          childCategories
          && (
            <CategoryCards
              selectedCategory={selectedCategory}
              categories={data ? Object.values(data).filter((value: any) => value.parent === parentId) : []}
              appLang={appLang}
              selectCategory={(key: any) => dispatch(selectProductCategory(key))}
              openCreateCategoryDrawer={(visible: boolean) => dispatch(setCreateCategoryDrawer(visible))}
            />
          )
        }
      </div>
      <div className="category-editor-body">
        {
          selectedCategory && categoryProducts[selectedCategory._id]
          && (
            <ProductCards
              categoryColor={selectedCategory.color}
              categoryProducts={categoryProducts[selectedCategory._id]}
              handleClick={(productId: string) => dispatch(setProductProfileDrawer(true, categoryProducts[selectedCategory._id][productId]))}
              openDrawer={() => history.push('/products/new')}
            />
          )
        }
      </div>
    </div>
  );
};

export default CategoryEditor;
