import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import {
  GET_TAXRATE_R, TaxrateGetTaxrateRequested,
  SET_TAXRATE_INPUT, TaxrateSetInput,
  CREATE_TAXRATE_R, TaxrateCreateTaxrateRequested,
} from 'consts/taxRateActionTypes';

export const getTaxrates = (value?: Sorts | null, key?: string, term?: string): TaxrateGetTaxrateRequested => ({
  type: GET_TAXRATE_R,
  payload: {
    value,
    key,
    term,
  },
});

export const setTaxrateInput = (key: string, value?: string | number | boolean): TaxrateSetInput => ({
  type: SET_TAXRATE_INPUT,
  payload: {
    key,
    value,
  },
});

export const createTaxrate = (): TaxrateCreateTaxrateRequested => ({
  type: CREATE_TAXRATE_R,
});
