import React, { FC } from 'react';
import { BarLoader } from 'react-spinners';
import './spinner.scss';

type SpinnerProps = {
  color?: string,
};

const Spinner: FC<SpinnerProps> = ({ color = '#764BA2' }) => (
  <div className="app-spinner">
    <BarLoader color={color} loading />
  </div>
);

export default Spinner;
