import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import { TaxRate } from 'reducers/taxrates/initialState';

export const GET_TAXRATE_R = 'GET_TAXRATE_R';
export interface TaxrateGetTaxrateRequested {
  type: typeof GET_TAXRATE_R,
  payload: {
    value?: Sorts | null,
    key?: string,
    term?: string,
  },
}

export const GET_TAXRATE_S = 'GET_TAXRATE_S';
export interface TaxrateGetTaxrateSucceeded {
  type: typeof GET_TAXRATE_S,
  payload: {
    taxrates: TaxRate[],
  },
}

export const GET_TAXRATE_F = 'GET_TAXRATE_F';

export const SET_TAXRATE_INPUT = 'SET_TAXRATE_INPUT';
export interface TaxrateSetInput {
  type: typeof SET_TAXRATE_INPUT,
  payload: {
    key: string,
    value?: string | number | boolean,
  },
}

export const CREATE_TAXRATE_R = 'CREATE_TAXRATE_R';
export interface TaxrateCreateTaxrateRequested {
  type: typeof CREATE_TAXRATE_R,
}

export const CREATE_TAXRATE_S = 'CREATE_TAXRATE_S';
export interface TaxrateCreateTaxrateSucceeded {
  type: typeof CREATE_TAXRATE_S,
}

export const CREATE_TAXRATE_F = 'CREATE_TAXRATE_F';
export interface TaxrateCreateTaxrateFailed {
  type: typeof CREATE_TAXRATE_F,
}

export type TaxrateActionTypes =
  | TaxrateGetTaxrateRequested
  | TaxrateGetTaxrateSucceeded
  | TaxrateSetInput
  | TaxrateCreateTaxrateRequested
  | TaxrateCreateTaxrateSucceeded
  | TaxrateCreateTaxrateFailed;
