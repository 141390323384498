import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import { Discount } from 'reducers/discounts/initialState';

export const GET_DISCOUNT_R = 'GET_DISCOUNT_R';
export interface DiscountGetDiscountRequested {
  type: typeof GET_DISCOUNT_R,
  payload: {
    value?: Sorts | null,
    key?: string,
    term?: string,
  },
}

export const GET_DISCOUNT_S = 'GET_DISCOUNT_S';
export interface DiscountGetDiscountSucceeded {
  type: typeof GET_DISCOUNT_S,
  payload: {
    discounts: Discount[],
  },
}

export const GET_DISCOUNT_F = 'GET_DISCOUNT_F';

export const SET_DISCOUNT_DRAWER = 'SET_DISCOUNT_DRAWER';
export interface DiscountSetDrawer {
  type: typeof SET_DISCOUNT_DRAWER,
  payload: {
    value: boolean,
  },
}

export const SET_DISCOUNT_INPUT = 'SET_DISCOUNT_INPUT';
export interface DiscountSetInput {
  type: typeof SET_DISCOUNT_INPUT,
  payload: {
    key: string,
    value?: string | number | boolean,
  },
}

export const CREATE_DISCOUNT_R = 'CREATE_DISCOUNT_R';
export interface DiscountCreateDiscountRequested {
  type: typeof CREATE_DISCOUNT_R,
}

export const CREATE_DISCOUNT_S = 'CREATE_DISCOUNT_S';
export interface DiscountCreateDiscountSucceeded {
  type: typeof CREATE_DISCOUNT_S,
}

export const CREATE_DISCOUNT_F = 'CREATE_DISCOUNT_F';
export interface DiscountCreateDiscountFailed {
  type: typeof CREATE_DISCOUNT_F,
}

export type DiscountActionTypes =
| DiscountGetDiscountRequested
| DiscountGetDiscountSucceeded
| DiscountSetDrawer
| DiscountSetInput
| DiscountCreateDiscountRequested
| DiscountCreateDiscountSucceeded
| DiscountCreateDiscountFailed;
