import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  GET_DISCOUNT_R, GET_DISCOUNT_F, GET_DISCOUNT_S, DiscountGetDiscountRequested,
  CREATE_DISCOUNT_R, CREATE_DISCOUNT_F, CREATE_DISCOUNT_S,
} from 'consts/discountActionTypes';
import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import * as API from 'services/api';
import { RootState } from 'reducers/rootReducer';

const getDiscountStore = (state: RootState) => state.discount;

function* getDiscounts(action: DiscountGetDiscountRequested) {
  try {
    const { value, key, term } = action.payload;
    const query = {
      $params: JSON.stringify({
        title: { $regex: term, $options: 'i' },
        deprecated: false,
      }),
      $sort: JSON.stringify({
        [key || 'createdAt']: value || Sorts.asc,
      }),
    };
    const { data } = yield call(API.findDataService, 'pos-discounts', '/pos-discounts', query);
    yield put({
      type: GET_DISCOUNT_S,
      payload: {
        discounts: data,
      },
    });
  } catch (error) {
    yield put({
      type: GET_DISCOUNT_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

function* createDiscount() {
  try {
    const { value, title, checked } = yield select(getDiscountStore);
    const updatedData = {
      title,
      value: +value,
      deprecated: checked,
    };
    yield call(API.createDataService, 'pos-discounts', '/pos-discounts', updatedData);
    yield put({
      type: CREATE_DISCOUNT_S,
    });
    yield put({
      type: GET_DISCOUNT_R,
      payload: {
        value: Sorts.asc,
        key: 'createdAt',
        term: '',
      },
    });
  } catch (error) {
    yield put({
      type: CREATE_DISCOUNT_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export const discountSagas = [
  takeEvery(GET_DISCOUNT_R, getDiscounts),
  takeEvery(CREATE_DISCOUNT_R, createDiscount),
];
