import React, { FC } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import ProductCard from './ProductCard';

export interface ColumnProps {
  columnId: any;
  items: any;
  categoryColor: any;
  handleClick: any;
}

export enum Columns {
  priority = 'priority',
  notPriority = 'notPriority',
}

const getStyle = (style: any, snapshot: any) => {
  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    transitionDuration: '0.001s',
  };
};

const Column: FC<ColumnProps> = (props) => {
  const { columnId, items, categoryColor, handleClick } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="drag-and-drop-column-title">{t(`dragAndDrop.${columnId}`)}</div>
      <Droppable droppableId={columnId} direction="horizontal">
        {(dropProvided) => (
          <div
            {...dropProvided.droppableProps}
            ref={dropProvided.innerRef}
            className="droppable-container"
          >
            {
              items.map((item: any, index: number) => (item ? (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(draggableProvided, snapshot) => (
                    <div
                      key={item._id}
                      className="pos-category-product-card-container"
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      style={
                        columnId === Columns.notPriority
                          ? getStyle(draggableProvided.draggableProps.style, snapshot)
                          : draggableProvided.draggableProps.style
                      }
                    >
                      <ProductCard
                        index={index}
                        item={item}
                        categoryColor={categoryColor}
                        handleClick={handleClick}
                      />
                    </div>
                  )}
                </Draggable>
              ) : null))
            }
            {columnId === Columns.notPriority && (
              <span style={{ display: 'none' }}>
                {dropProvided.placeholder}
              </span>
            )}
          </div>
        )}
      </Droppable>
    </>
  );
};

export default Column;
