import {
  USER_LOGIN_R, UserLoginRequestedType,
  RESTORE_USER_BY_TOKEN_R, RestoreUserByTokenRequestedType,
  USER_LOGOUT_R, UserLogoutRequestedType,
  USER_FORGOT_PASSWORD_R, UserForgotPasswordRequestedType,
  USER_SEND_EMAIL_R, UserSendEmailRequestedType,
} from 'consts/userActionTypes';

export const login = (email: string, password: string, next: string): UserLoginRequestedType => ({
  type: USER_LOGIN_R,
  payload: {
    email,
    password,
    next,
  },
});

export const restoreUserByToken = (next?: string): RestoreUserByTokenRequestedType => ({
  type: RESTORE_USER_BY_TOKEN_R,
  payload: {
    next,
  },
});

export const logout = (): UserLogoutRequestedType => ({
  type: USER_LOGOUT_R,
});

export const forgotPassword = (password: string, code: string, email: string): UserForgotPasswordRequestedType => ({
  type: USER_FORGOT_PASSWORD_R,
  payload: {
    password,
    code,
    email,
  },
});

export const sendEmail = (email: string): UserSendEmailRequestedType => ({
  type: USER_SEND_EMAIL_R,
  payload: {
    email,
  },
});
