import {
  POS_PROD_LIST_S,
  POS_PROD_LIST_BY_CATEGORY_S,
  POS_PROD_TOGGLE_PROFILE_MODAL,
  POS_PROD_TOGGLE_EDITOR_MODAL,
  POS_PROD_TOGGLE_CREATE_MODAL,
  POS_PROD_TOGGLE_UPLOAD_MODAL,
  POS_PROD_EDIT_FIELD,
  POS_PROD_UPDATE_R,
  POS_PROD_UPDATE_S,
  POS_PROD_UPDATE_F,
  POS_PROD_CREATE_R,
  POS_PROD_CREATE_S,
  POS_PROD_CREATE_F,
  POS_PROD_GET_R,
  POS_PROD_GET_S,
  POS_PROD_GET_F,
  ProductActionTypes,
} from 'consts/productActionTypes';
import { POS_CAT_SELECT_CATEGORY } from 'consts/productCategoriesActionTypes';
import initialState, { PosProductStateType } from './initialState';

const posProductsReducer = (state = initialState, action: ProductActionTypes): PosProductStateType => {
  switch (action.type) {
    case POS_PROD_LIST_S: {
      const { data, meta } = action.payload;
      return {
        ...state,
        data,
        meta,
      };
    }

    case POS_PROD_LIST_BY_CATEGORY_S: {
      const { selectedCategory, posProducts } = action.payload;
      return {
        ...state,
        productCategories: {
          ...state.productCategories,
          [selectedCategory]: posProducts,
        },
      };
    }

    case POS_PROD_TOGGLE_PROFILE_MODAL: {
      const { selectedProduct, profileDrawerIsVisible } = action.payload;

      return {
        ...state,
        selectedProduct,
        profileDrawerIsVisible,
      };
    }

    case POS_CAT_SELECT_CATEGORY: {
      const { key } = action.payload;

      return {
        ...state,
        newProduct: {
          ...state.newProduct,
          categories: [key],
        },
      };
    }

    case POS_PROD_TOGGLE_UPLOAD_MODAL: {
      const { uploadDrawerIsVisible } = action.payload;

      return {
        ...state,
        uploadDrawerIsVisible,
      };
    }

    case POS_PROD_TOGGLE_CREATE_MODAL: {
      const { createDrawerIsVisible } = action.payload;

      return {
        ...state,
        createDrawerIsVisible,
      };
    }

    case POS_PROD_TOGGLE_EDITOR_MODAL: {
      const { editorDrawerIsVisible } = action.payload;

      return {
        ...state,
        editorDrawerIsVisible,
        editedProduct: state.selectedProduct ? { ...state.selectedProduct } : {},
      };
    }

    case POS_PROD_EDIT_FIELD: {
      const { drawerType, field, value } = action.payload;
      return {
        ...state,
        [drawerType]: {
          ...state[drawerType],
          [field]: value,
        },
      };
    }

    case POS_PROD_UPDATE_R: {
      return {
        ...state,
        updateSucceeded: false,
        editingInProgress: true,
      };
    }

    case POS_PROD_UPDATE_S: {
      const { selectedProduct } = action.payload;
      return {
        ...state,
        editingInProgress: false,
        editorDrawerIsVisible: false,
        updateSucceeded: true,
        selectedProduct,
      };
    }

    case POS_PROD_UPDATE_F: {
      return {
        ...state,
        updateSucceeded: false,
        editingInProgress: false,
      };
    }

    case POS_PROD_CREATE_R: {
      return {
        ...state,
        creatingInProgress: true,
      };
    }

    case POS_PROD_CREATE_S: {
      return {
        ...state,
        creatingInProgress: false,
        createDrawerIsVisible: false,
        newProduct: {
          ...state.newProduct,
          titles: initialState.newProduct.titles,
          printTitles: initialState.newProduct.printTitles,
          price: {
            HUF: 0,
          },
          image: null,
          takeaway: undefined,
          dinein: undefined,
          taxValueExceptions: [],
        },
      };
    }

    case POS_PROD_CREATE_F: {
      return {
        ...state,
        creatingInProgress: false,
      };
    }

    case POS_PROD_GET_R:
      return {
        ...state,
        selectedProductForEdit: null,
      };

    case POS_PROD_GET_S:
      return {
        ...state,
        selectedProductForEdit: action.payload.selectedProductForEdit,
      };

    case POS_PROD_GET_F:
      return {
        ...state,
        selectedProductForEdit: null,
      };

    default:
      return state;
  }
};

export default posProductsReducer;
