import { POSCategorySelectCategory } from './productCategoriesActionTypes';

export const POS_PROD_LIST_R = 'POS_PROD_LIST_R';
export interface POSProductListRequested {
  type: typeof POS_PROD_LIST_R,
  payload: {
    selectedCategory?: any;
    selectedProduct?: any;
    afterlistAction?: any;
  },
}

export const POS_PROD_LIST_S = 'POS_PROD_LIST_S';
export interface POSProductListSucceeded {
  type: typeof POS_PROD_LIST_S,
  payload: { data: any, meta: any },
}

export const POS_PROD_LIST_F = 'POS_PROD_LIST_F';
export const POS_PROD_LIST_BY_CATEGORY_R = 'POS_PROD_LIST_BY_CATEGORY_R';
export const POS_PROD_LIST_BY_CATEGORY_S = 'POS_PROD_LIST_BY_CATEGORY_S';
export interface POSProductListByCategorySucceeded {
  type: typeof POS_PROD_LIST_BY_CATEGORY_S,
  payload: {
    selectedCategory: any,
    posProducts: any,
    selectedProduct: any,
  },
}

export const POS_PROD_LIST_BY_CATEGORY_F = 'POS_PROD_LIST_BY_CATEGORY_F';
export const POS_PROD_CREATE_SET_FIELD = 'POS_PROD_CREATE_SET_FIELD';
export interface POSProductCreateSetField {
  type: typeof POS_PROD_CREATE_SET_FIELD,
  payload: { key: string, value: any },
}

export const POS_PROD_CREATE_RESET = 'POS_PROD_CREATE_RESET';
export interface POSProductCreateReset {
  type: typeof POS_PROD_CREATE_RESET,
}

export const POS_PROD_CREATE_SET_TABLE = 'POS_PROD_CREATE_SET_TABLE';
export const POS_PROD_CREATE_SET_DATA_ROW = 'POS_PROD_CREATE_SET_DATA_ROW';
export interface POSProductCreateSetDataRow {
  type: typeof POS_PROD_CREATE_SET_DATA_ROW,
  payload: {
    rowId: string,
    fieldName: string,
    fieldData: any,
  }
}

export const POS_PROD_CREATE_ADD_DATA_ROW = 'POS_PROD_CREATE_ADD_DATA_ROW';
export interface POSProductCreateAddDataRow {
  type: typeof POS_PROD_CREATE_ADD_DATA_ROW,
}

export const POS_PROD_CREATE_REMOVE_DATA_ROW = 'POS_PROD_CREATE_REMOVE_DATA_ROW';
export interface POSProductCreateRemoveDataRow {
  type: typeof POS_PROD_CREATE_REMOVE_DATA_ROW,
  payload: {
    rowId: string,
  },
}

export const POS_PROD_CREATE_TRIM_TABLE = 'POS_PROD_CREATE_TRIM_TABLE';
export interface POSProductCreateTrimTable {
  type: typeof POS_PROD_CREATE_TRIM_TABLE,
}

export const POS_PROD_CREATE_SELECT_INGREDIENT = 'POS_PROD_CREATE_SELECT_INGREDIENT';
export interface POSProductCreateSelectIngredient {
  type: typeof POS_PROD_CREATE_SELECT_INGREDIENT,
  payload: {
    rowId: string,
    selectedOption: any,
  },
}

export const POS_PROD_CREATE_SET_INGREDIENT = 'POS_PROD_CREATE_SET_INGREDIENT';
export interface POSProductCreateSetIngredient {
  type: typeof POS_PROD_CREATE_SET_INGREDIENT,
  payload: { rowId: string, ingredientId: string, fieldName: string, fieldData: any },
}

export const POS_PROD_CREATE_REMOVE_INGREDIENT = 'POS_PROD_CREATE_REMOVE_INGREDIENT';
export interface POSProductCreateRemoveIngredient {
  type: typeof POS_PROD_CREATE_REMOVE_INGREDIENT,
  payload: { rowId: string, ingredientId: string },
}

export const POS_PROD_CREATE_IMPORT_BY_CREATE = 'POS_PROD_CREATE_IMPORT_BY_CREATE';
export interface POSProductCreateImportByCreate {
  type: typeof POS_PROD_CREATE_IMPORT_BY_CREATE,
}

export const POS_PROD_CREATE_IMPORT_BY_CLIPBOARD = 'POS_PROD_CREATE_IMPORT_BY_CLIPBOARD';
export interface POSProductCreateImportByClipBoard {
  type: typeof POS_PROD_CREATE_IMPORT_BY_CLIPBOARD,
  payload: { clipboardData: any },
}

export const POS_PROD_CREATE_SET_COLUMN_DEFINITIONS = 'POS_PROD_CREATE_SET_COLUMN_DEFINITIONS';
export interface POSProductCreateSetColumnDefinitions {
  type: typeof POS_PROD_CREATE_SET_COLUMN_DEFINITIONS,
  payload: {
    titleIndex: string[] | number[],
    priceIndex: string[] | number[],
  },
}

export const POS_PROD_CREATE_R = 'POS_PROD_CREATE_R';
export interface POSProductCreateRequested {
  type: typeof POS_PROD_CREATE_R,
}

export const POS_PROD_CREATE_S = 'POS_PROD_CREATE_S';
export interface POSProductCreateSucceeded {
  type: typeof POS_PROD_CREATE_S,
}

export const POS_PROD_CREATE_F = 'POS_PROD_CREATE_F';
export interface POSProductCreateFailed {
  type: typeof POS_PROD_CREATE_F,
}

export const POS_PROD_UPDATE_R = 'POS_PROD_UPDATE_R';
export interface POSProductUpdateRequested {
  type: typeof POS_PROD_UPDATE_R,
}

export const POS_PROD_UPDATE_S = 'POS_PROD_UPDATE_S';
export interface POSProductUpdateSucceeded {
  type: typeof POS_PROD_UPDATE_S,
  payload: { selectedProduct: any },
}

export const POS_PROD_UPDATE_F = 'POS_PROD_UPDATE_F';
export interface POSProductUpdateFailed {
  type: typeof POS_PROD_UPDATE_F,
}

export const POS_PROD_TOGGLE_PROFILE_MODAL = 'POS_PROD_TOGGLE_PROFILE_MODAL';
export interface POSProductToggleProfileModal {
  type: typeof POS_PROD_TOGGLE_PROFILE_MODAL,
  payload: { profileDrawerIsVisible: boolean, selectedProduct: any },
}

export const POS_PROD_TOGGLE_CREATE_MODAL = 'POS_PROD_TOGGLE_CREATE_MODAL';
export interface POSProductToggleCreateModal {
  type: typeof POS_PROD_TOGGLE_CREATE_MODAL,
  payload: { createDrawerIsVisible: boolean },
}

export const POS_PROD_TOGGLE_EDITOR_MODAL = 'POS_PROD_TOGGLE_EDITOR_MODAL';
export interface POSProductToggleEditorModal {
  type: typeof POS_PROD_TOGGLE_EDITOR_MODAL,
  payload: { editorDrawerIsVisible: boolean },
}

export const POS_PROD_TOGGLE_UPLOAD_MODAL = 'POS_PROD_TOGGLE_UPLOAD_MODAL';
export interface POSProductToggleUploadModal {
  type: typeof POS_PROD_TOGGLE_UPLOAD_MODAL,
  payload: { uploadDrawerIsVisible: boolean },
}

export const POS_PROD_EDIT_FIELD = 'POS_PROD_EDIT_FIELD';
export interface POSProductEditField {
  type: typeof POS_PROD_EDIT_FIELD,
  payload: { drawerType: any, field: string, value: any },
}

export const POS_PRODUCT_REORDER_R = 'POS_PRODUCT_REORDER_R';
export interface POSProductReorder {
  type: typeof POS_PRODUCT_REORDER_R,
  payload: {
    order: string[];
    out: string | null;
  },
}

export const POS_PRODUCT_REORDER_S = 'POS_PRODUCT_REORDER_S';
export interface POSProductReorderSucceeded {
  type: typeof POS_PRODUCT_REORDER_S,
}

export const POS_PRODUCT_REORDER_F = 'POS_PRODUCT_REORDER_F';
export interface POSProductReorderFailed {
  type: typeof POS_PRODUCT_REORDER_F,
}

export const POS_PROD_GET_R = 'POS_PROD_GET_R';
export interface POSProductGetRequested {
  type: typeof POS_PROD_GET_R,
  payload: { id: string },
}

export const POS_PROD_GET_S = 'POS_PROD_GET_S';
export interface POSProductGetSucceeded {
  type: typeof POS_PROD_GET_S,
  payload: { selectedProductForEdit: any },
}

export const POS_PROD_GET_F = 'POS_PROD_GET_F';
export interface POSProductGetFailed {
  type: typeof POS_PROD_GET_F,
}

export type ProductActionTypes = POSProductListRequested | POSProductCreateSetField
| POSProductCreateReset | POSProductCreateSetDataRow
| POSProductCreateTrimTable | POSProductCreateAddDataRow
| POSProductCreateRemoveDataRow | POSProductCreateImportByCreate
| POSProductCreateImportByClipBoard | POSProductCreateSetColumnDefinitions
| POSProductCreateSelectIngredient | POSProductCreateSetIngredient
| POSProductCreateRemoveIngredient | POSProductCreateRequested
| POSProductToggleProfileModal | POSProductToggleEditorModal
| POSProductToggleUploadModal | POSProductToggleCreateModal
| POSProductUpdateRequested | POSProductListSucceeded
| POSProductListByCategorySucceeded | POSCategorySelectCategory
| POSProductEditField | POSProductUpdateSucceeded
| POSProductUpdateFailed | POSProductCreateSucceeded
| POSProductCreateFailed | POSProductReorder
| POSProductReorderSucceeded | POSProductReorderFailed
| POSProductCreateFailed | POSProductGetRequested
| POSProductGetSucceeded | POSProductGetFailed;
