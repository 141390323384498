import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  POS_PROD_LIST_R, POSProductListRequested,
  POS_PROD_LIST_BY_CATEGORY_S,
  POS_PROD_CREATE_R, POS_PROD_CREATE_S, POS_PROD_CREATE_F,
  POS_PROD_CREATE_SET_FIELD,
  POS_PROD_CREATE_SET_TABLE,
  POS_PROD_CREATE_IMPORT_BY_CREATE,
  POS_PROD_CREATE_IMPORT_BY_CLIPBOARD, POSProductCreateImportByClipBoard,
  POS_PROD_UPDATE_R, POS_PROD_UPDATE_S, POS_PROD_UPDATE_F, POSProductUpdateRequested,
  POS_PRODUCT_REORDER_R, POS_PRODUCT_REORDER_S, POS_PRODUCT_REORDER_F, POSProductReorder,
  POS_PROD_GET_R, POS_PROD_GET_S, POS_PROD_GET_F, POSProductGetRequested,
} from 'consts/productActionTypes';
import {
  POS_CAT_SELECT_CATEGORY, POSCategorySelectCategory,
} from 'consts/productCategoriesActionTypes';
import * as API from 'services/api';
import { RootState } from 'reducers/rootReducer';
import { history } from 'services/config';

const getPosproductsStore = (state: RootState) => state.posProducts;
const getProductCategoriesStore = (state: RootState) => state.productCategories;

function* findPosProducts(action: POSProductListRequested) {
  const { selectedCategory, selectedProduct, afterlistAction } = action.payload;
  if (selectedCategory === null) {
    yield put({
      type: POS_PROD_LIST_BY_CATEGORY_S,
      payload: {
        selectedCategory,
        selectedProduct,
        posProducts: null,
      },
    });
    return;
  }

  try {
    const query = {
      $params: JSON.stringify({
        categories: selectedCategory,
        deprecated: false,
      }),
    };
    const { data, meta } = yield API.findDataService('pos-products', '/pos-products', query);
    const posProductObject: { [_id: string]: any } = {};
    data.forEach((posProduct: any) => {
      posProductObject[posProduct._id] = posProduct;
    });
    Object.keys(meta.prices).forEach((key) => {
      const productId = meta.prices[key].posProduct;
      const price: { [name: string]: any } = {};
      meta.prices[key].currencies.forEach((priceItem: any) => {
        price[priceItem.name] = priceItem.value;
      });

      posProductObject[productId] = {
        ...posProductObject[productId],
        price,
        priority: posProductObject[productId].priority || false,
      };
    });
    Object.keys(meta.profilePictures).forEach((key) => {
      posProductObject[key].image = meta.profilePictures[key];
    });

    yield put({
      type: POS_PROD_LIST_BY_CATEGORY_S,
      payload: {
        selectedCategory,
        selectedProduct,
        posProducts: posProductObject,
      },
    });
    if (afterlistAction) {
      afterlistAction();
    }
  } catch (error) {
    yield put({
      type: 'PRODUCTS_LIST_BY_CATEGORY_F',
      payload: {
        selectedCategory,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* createPosProductsByCreate() {
  yield put({
    type: POS_PROD_CREATE_SET_TABLE,
    payload: {
      data: {
        1: { key: 1, productName: '', price: '', categories: [], products: [] },
      },
    },
  });

  yield put({
    type: POS_PROD_CREATE_SET_FIELD,
    payload: {
      key: 'currentStep',
      value: 2,
    },
  });
}

export function* createPosProductsByClipboard(action: POSProductCreateImportByClipBoard) {
  const { clipboardData } = action.payload;

  yield put({
    type: POS_PROD_CREATE_SET_FIELD,
    payload: {
      key: 'rawData',
      value: clipboardData.split(/\r?\n/).map((row: string) => row.split(/\t/)),
    },
  });

  yield put({
    type: POS_PROD_CREATE_SET_FIELD,
    payload: {
      key: 'currentStep',
      value: 1,
    },
  });
}

export function* createPosProducts() {
  try {
    const {
      newProduct: {
        titles, printTitles,
        categories, price,
        image, takeaway,
        dinein, taxValueExceptions,
        ingredients: ingredientsFromStore,
      },
    } = yield select(getPosproductsStore);
    const { selectedCategory } = yield select(getProductCategoriesStore);
    const productIngredients = Object.keys(ingredientsFromStore).map((id: any) => ({
      _id: id,
      amount: ingredientsFromStore[id].amount,
      selectedStorageType: ingredientsFromStore[id].selectedStorageType,
    }));
    let data: any = {
      titles,
      printTitles,
      categories,
      defaultTaxValue: {
        takeaway,
        dinein,
      },
      currencies: [{ name: 'HUF', value: price.HUF }],
      image: image ? image.id : null,
      productIngredients,
    };
    const filteredException = taxValueExceptions
      .filter((item: { salepoint: string, takeaway: string, dinein: string }) => item.salepoint && item.dinein && item.takeaway)
      .map((item: { salepoint: string, takeaway: string, dinein: string }) => ({
        salePoint: item.salepoint,
        values: {
          takeaway: item.takeaway,
          dinein: item.dinein,
        },
      }));

    if (filteredException.length) {
      data = {
        ...data,
        taxValueExceptions: filteredException,
      };
    }

    yield call(API.createDataService, 'pos-products', '/pos-products', data);
    yield put({
      type: POS_PROD_LIST_R,
      payload: {
        selectedCategory: selectedCategory._id,
        afterlistAction: () => history.push('/categories'),
      },
    });
    yield put({
      type: POS_PROD_CREATE_S,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({
      type: POS_PROD_CREATE_F,
    });
  }
}

export function* listProducts(action: POSCategorySelectCategory) {
  yield put({
    type: POS_PROD_LIST_R,
    payload: {
      selectedCategory: action.payload.key,
    },
  });
}

export function* updateProduct(action: POSProductUpdateRequested): any {
  try {
    const { selectedProductForEdit: {
      _id: id,
      titles,
      printTitles,
      parent,
      categories,
      price,
      image,
      takeaway,
      dinein,
      taxValueExceptions,
      ingredients,
      imageId,
    } } = yield select(getPosproductsStore); // eslint-disable-line
    const productIngredients = Object.keys(ingredients).map((ingredientId: any) => ({
      _id: ingredientId,
      amount: ingredients[ingredientId].amount,
      selectedStorageType: ingredients[ingredientId].selectedStorageType,
    }));

    const filteredException = taxValueExceptions
      .filter((item: { salepoint: string, takeaway: string, dinein: string }) => item.salepoint && item.dinein && item.takeaway)
      .map((item: { salepoint: string, takeaway: string, dinein: string }) => ({
        salePoint: item.salepoint,
        values: {
          takeaway: item.takeaway,
          dinein: item.dinein,
        },
      }));

    let productData: any = {
      titles,
      printTitles,
      parent,
      categories,
      currencies: [{ name: 'HUF', value: price.HUF }],
      image: imageId || (image ? image.id : null),
      defaultTaxValue: {
        takeaway,
        dinein,
      },
      productIngredients,
    };

    if (filteredException.length) {
      productData = {
        ...productData,
        taxValueExceptions: filteredException,
      };
    }

    const updatedProduct = yield call(API.patchDataService, 'pos-products', '/pos-products', id, productData);
    yield put({
      type: POS_PROD_LIST_R,
      payload: {
        selectedCategory: categories[0],
        afterlistAction: () => history.push('/categories'),
      },
    });
    yield put({
      type: POS_PROD_UPDATE_S,
      payload: {
        selectedProduct: {
          ...updatedProduct.data,
          price: { HUF: updatedProduct.meta.posProductPrice[0].currencies[0].value },
          image: updatedProduct.meta.profilePicture,
        },
      },
    });
  } catch (error) {
    if (error.response.status === 502) {
      yield put(action);
    } else {
      console.log(error); // eslint-disable-line
      yield put({
        type: POS_PROD_UPDATE_F,
      });
    }
  }
}

export function* productReorder(action: POSProductReorder): any {
  try {
    const { order, out } = action.payload;
    const { selectedCategory } = yield select(getProductCategoriesStore);
    yield call(API.patchWithoutIdService, 'pos-products-order', '/pos-products-order', { order, out });
    yield put({
      type: POS_PROD_LIST_R,
      payload: {
        selectedCategory: selectedCategory._id,
      },
    });
    yield put({
      type: POS_PRODUCT_REORDER_S,
    });
  } catch (error) {
    if (error.response.status === 502) {
      yield put(action);
    } else {
      console.log(error); // eslint-disable-line
      yield put({
        type: POS_PRODUCT_REORDER_F,
      });
    }
  }
}

function* getPosProduct(action: POSProductGetRequested) {
  const { id } = action.payload;
  try {
    const query = {
      $params: JSON.stringify({
        deprecated: false,
      }),
    };
    const { data } = yield API.findDataService('pos-products', `/pos-products/${id}`, query);
    const { data: prices } = yield API.findDataService('pos-products-prices', '/pos-products-prices', query);
    const price = prices.find((priceItem: any) => priceItem.posProduct === id).currencies
      .reduce((acc: any, cur: any) => ({ ...acc, [cur.name]: cur.value }), {});
    yield put({
      type: POS_PROD_GET_S,
      payload: {
        selectedProductForEdit: {
          ...data,
          price,
          ingredients: data.productIngredients ? data.productIngredients.reduce((acc: any, curr: any) => ({
            ...acc,
            [curr.product._id]: {
              ...curr.product,
              amount: curr.amount,
              _id: curr.product._id,
              selectedStorageType: curr.product.storageType,
              productImageUrl:
                `https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3-eu-west-1.amazonaws.com/uploads/${curr.product.userId}/${curr.product.productImage}`,
            },
          }), {})
            : [],
          takeaway: data.defaultTaxValue.takeaway,
          dinein: data.defaultTaxValue.dinein,
          taxValueExceptions: data.taxValueExceptions.length ? data.taxValueExceptions.map((item: any) => ({
            salepoint: item.salePoint,
            takeaway: item.values.takeaway,
            dinein: item.values.dinein,
          })) : [],
          imageId: data.profileImage,
          image: data.profileImage ? {
            url: `https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3-eu-west-1.amazonaws.com/uploads/${data.userId}/${data.profileImage}`,
          } : null,
        },
      },
    });
  } catch (error) {
    yield put({
      type: POS_PROD_GET_F,
    });
    console.log(error); // eslint-disable-line
  }
}

export const posProductsSagas = [
  takeEvery(POS_PROD_LIST_R, findPosProducts),
  takeEvery(POS_PROD_CREATE_IMPORT_BY_CREATE, createPosProductsByCreate),
  takeEvery(POS_PROD_CREATE_IMPORT_BY_CLIPBOARD, createPosProductsByClipboard),
  takeEvery(POS_PROD_CREATE_R, createPosProducts),
  takeEvery(POS_CAT_SELECT_CATEGORY, listProducts),
  takeEvery(POS_PROD_UPDATE_R, updateProduct),
  takeEvery(POS_PRODUCT_REORDER_R, productReorder),
  takeEvery(POS_PROD_GET_R, getPosProduct),
];
