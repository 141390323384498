import React, { FC } from 'react';

export interface CategoryCardProps {
  appLang: string;
  selectCategory: (id: string) => void;
  category: any;
}

const CategoryCard: FC<CategoryCardProps> = ({ appLang, selectCategory, category }) => (
  <div
    className="category-card"
    style={{
      color: category.color,
      backgroundColor: `${category.color}0d`,
      border: `1px solid ${category.color}`,
    }}
    onClick={() => {
      selectCategory(category._id);
    }}
    role="button"
    tabIndex={0}
  >
    {
      appLang === 'en-US'
        ? category.titles.en || category.titles.hu
        : category.titles.hu || category.titles.en
    }
  </div>
);

export default CategoryCard;
