export default {
  translation: {
    app: {
      common: {
        buttons: {
          logout: 'Kijelentkezés',
          back: 'Vissza',
          restart: 'Újrakezdés',
          reset: 'Visszaállítás',
          yes: 'Igen',
          no: 'Nem',
          add: 'Hozzáadás',
          cancel: 'Mégsem',
          close: 'Bezár',
          edit: 'Szerkesztés',
          create: 'Létrehozás',
          save: 'Mentés',
          upload: 'Feltöltés',
          cut: 'Vágás',
        },
        colors: {
          green: 'zöld',
          blue: 'kék',
          purple: 'lila',
          yellow: 'sárga',
          orange: 'narancs',
          red: 'piros',
        },
      },
      languages: {
        hu: 'magyar',
        en: 'angol',
      },
    },
    pages: {
      update: {
        availableUpdate: 'Elérhető egy új frissítés!',
        updateApp: 'App frissítése',
      },
      login: {
        title: 'Bejelentkezés',
        subtitle: 'Adja meg a bejelentkezási adatait.',
        inputs: {
          email: 'Email cím',
          password: 'Jelszó',
          login: 'Bejelentkezés',
          code: 'Kód',
        },
        errors: {
          badCredentials: 'Hibás email cím vagy jelszó',
          emailError: 'Nem megfelelő email cím!',
          passwordError: 'A jelszónak minimum 8 karakternek kell lennie és tartalmaznia kell kis és nagybetűt illetve számot is!',
        },
      },
      dashboard: {
        title: 'Üdvözöljük',
        next: 'Tovább',
        welcomeSubtitle: 'Készen állunk, vágjunk bele!',
        infocards: {
          categories: 'Termékek \nés kategóriák',
          categorysub: 'Minden egy helyen',
          sales: 'Napi eladások',
          salessub: 'Adatok percről-percre',
          locations: 'Helyszínek',
          locationssub: 'Nem kell útbaigazítás',
          discounts: 'Kedvezmények',
          discountssub: 'Kitüntetett figyelem',
        },
      },
      categories: {
        title: 'Kategóriák \nés termékek',
        labels: {
          titles: {
            hu: 'név',
            en: 'angol név',
          },
        },
        inputs: {
          titles: {
            hu: 'magyar nyelvű elnevezés',
            en: 'angol nyelvű elnevezés',
          },
          parentCategory: 'szülő kategória',
          colorCode: 'színkód',
        },
      },
      products: {
        buttons: {
          addProductToCategory: 'új termék hozzáadása\na kategóriához',
          addException: 'kivétel hozzáadása',
          addMoreException: 'további kivétel hozzáadása',
        },
        labels: {
          titles: {
            hu: 'név',
            en: 'angol név',
          },
          printTitles: {
            hu: 'nyomtatási elnevezés',
            en: 'angol nyomtatási elnevezés',
          },
          mainCategories: 'Kategóriák',
        },
        inputs: {
          titles: {
            hu: 'termék elnevezése',
            en: 'termék elnevezése',
          },
          printTitles: {
            hu: 'nyomtatási név',
            en: 'nyomtatási név',
          },
          selectedCategory: 'kategória',
          price: 'ár',
          takeaway: 'ÁFA-kulcs elvitelre',
          dinein: 'ÁFA-kulcs helyben fogyasztásra',
          taxratePlaceholder: 'Válasszon áfa-kulcsot',
          salepoint: 'Eladási pont',
          salepointPlaceholder: 'Válasszon eladási pontot',
        },
      },
      sales: {
        title: 'Eladások',
        headerTitle: 'Napi forgalom',
        emptyPage: {
          title: 'Nincs megjeleníthető eladási tétel',
          loading: 'Eladási adatok betöltése...',
        },
        numberOfPurchases: 'Vásárlások száma',
        occasion: 'alkalom',
        allIncome: 'Teljes bevétel',
        sum: 'Összesítés',
        numberOfSalesPerHour: 'Eladási darabszámok (óránként)',
        incomeIncreasingPerHour: 'Bevétel növekedése (óránként)',
        summa: 'Összesen:',
        product: 'termék',
        salesDatas: 'Eladási adatok',
        list: 'Tételes',
        group: 'Összesített',
        groupColumns: {
          product: 'Termék',
          quantity: 'Mennyiség (darab)',
          unitPrice: 'Részösszeg (Ft)',
        },
        dailyTradeDownload: 'Napi elszámolások letöltése',
        sellings: 'eladasok',
        date: 'Dátum',
        hour: 'óra',
        dateOfSale: 'Eladás időpontja',
        numberOfItems: 'Tételek száma',
        amountPaid: 'Fizetett összeg',
        item: 'tétel',
        expandableColumns: {
          displayName: 'Megnevezés',
          quantity: 'Darabszám',
          unitPrice: 'Egységár (Ft)',
          subTotal: 'Összeg (Ft)',
        },
        dateOfPurchase: 'Vásárlás időpontja',
        loadingDocument: 'Letöltés előkészítése...',
      },
      locations: {
        title: 'Helyszínek',
        subtitle: 'Minden egy helyen',
        buttonTitle: 'Új helyszín létrehozása',
      },
      discounts: {
        title: 'Kedvezmények',
        subtitle: 'Minden egy helyen',
        buttonTitle: 'Kedvezmény létrehozása',
      },
      newProduct: {
        title: 'Termék létrehozása',
        basicData: 'Alapadatok',
        removePicture: 'Kép törlése',
        addPicture: 'Kép hozzáadása',
        taxKeys: 'Alapértelmezett ÁFA kulcsok',
        availableTaxKeys: 'Eltérő ÁFA kulcsok',
        type: {
          WHOLE: 'Egész',
          UNFOLDABLE: 'Megbontható',
          MEASURED: 'Kimért/halmozott',
        },
        newStockItem: 'Új raktárcikk létrehozása',
        selectStockItem: 'Raktárcikk kiválasztása',
        handleStock: 'Raktárkezelés',
        stockType: 'Készlet típusa',
        quantity: 'Mennyiség',
        chooseProduct: 'Termék választása',
        chooseProductPlaceholder: 'Válasszon terméket',
        other: 'Egyéb beállítások',
        showeable: 'Legyen listázva az elérhető termékek között?',
      },
      editedProduct: {
        title: 'Termék szerkesztése',
        basicData: 'Alapadatok',
        removePicture: 'Kép törlése',
        addPicture: 'Kép hozzáadása',
        taxKeys: 'Alapértelmezett ÁFA kulcsok',
        availableTaxKeys: 'Eltérő ÁFA kulcsok',
        type: {
          WHOLE: 'Egész',
          UNFOLDABLE: 'Megbontható',
          MEASURED: 'Kimért/halmozott',
        },
        newStockItem: 'Új raktárcikk létrehozása',
        selectStockItem: 'Raktárcikk kiválasztása',
        handleStock: 'Raktárkezelés',
        stockType: 'Készlet típusa',
        quantity: 'Mennyiség',
        chooseProduct: 'Termék választása',
        chooseProductPlaceholder: 'Válasszon terméket',
      },
      retorePassword: {
        title: 'Jelszó visszaállítás',
        subtitle: 'Adja meg új jelszavát!',
        inputs: {
          password: 'Jelszó',
          button: 'Jelszó visszaállítása',
        },
        success: {
          emailMessage: 'Email küldés sikerült!',
          emailDescription: 'Az email-ben kapott kódot felhasználva új jelszót hozhat létre!',
          forgotPasswordMessage: 'Jelszó változtatás sikerült!',
          forgotPasswordDescription: 'Új jelszava elkészült, mostmár bejelentkezhet!',
        },
        errors: {
          emptyPassword: 'A mező kitöltése kötelező!',
          notEqualPassword: 'A két jelszó nem egyezik meg!',
          wrongPassword: 'A jelszónak minimum 8 karakternek kell lennie és tartalmaznia kell kis és nagybetűt illetve számot is!',
          emailSentErrorMessage: 'Email küldési hiba!',
          emailSentErrorDescription: 'Az email küldés nem sikerült!',
        },
      },
    },
    drawers: {
      addCategory: {
        title: 'Új kategória létrehozása',
        subtitle: 'Adja meg az elnevezéseket',
      },
      addDiscount: {
        title: 'Kedvezmény létrehozása',
        subtitle: 'Adja meg a kedvezmény adatait',
        discountName: 'Kedvezmény neve',
        discountNamePlaceholder: 'Adja meg a kedvezmény nevét',
        discountValue: 'Kedvezmény',
        discountValuePlaceholder: 'Adja meg a kedvezmény értékét',
        deprecatedTitle: 'Legyen listázva?',
      },
      addLocation: {
        title: 'Helyszín létrehozása',
        subtitle: 'Adja meg a helyszín adatait',
        locationName: 'Helyszín neve',
        locationNamePlaceholder: 'Adja meg a helyszín nevét',
      },
      addProduct: {
        imageDimensionErrorMessage: 'Nem megfelelő képméret!',
        imageDimensionErrorDescription: 'Minimum 400x400-as méretű képet lehet feltölteni!',
        dropHere: 'Dobja ide a fájlokat vagy kattintson a keretre a fájlböngészőhöz',
        mainCategory: 'Főkategória',
        createProduct: 'Termék létrehozása',
        editProduct: 'Termék szerkesztése',
        productDataSheet: 'Termék adatlap',
      },
    },
    dragAndDrop: {
      priority: 'Kiemelt termékek',
      notPriority: 'Többi termék',
    },
  },
};
