import { Auth, API } from 'aws-amplify';

export const login = (email: string, password: string) => Auth.signIn(email, password);

export const verifyUser = () => Auth.currentSession();

export const logout = () => Auth.signOut();

export const createDataService = (service: string, endpoint: string, data: any) => API.post(service, endpoint, { body: data });

export const findDataService = (service: string, endpoint: string, queryStringParameters: any = {}) => API.get(service, endpoint, { queryStringParameters });

export const patchDataService = (service: string, endpoint: string, id: number | string, data: any) => API.patch(service, `${endpoint}/${id}`, { body: data });

export const patchWithoutIdService = (service: string, endpoint: string, data: any) => API.patch(service, endpoint, { body: data });

export const forgotPasswordSendEmail = (email: string) => Auth.forgotPassword(email);

export const restorePassword = ({ email, code, password }: { email: string, code: string, password: string }) => Auth
  .forgotPasswordSubmit(email, code, password);
