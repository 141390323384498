import React, { FC } from 'react';
import AssignType, { AssignTypeProps } from './AssignType';
import './assign-type.scss';

type AssignTypeSelectorProps = {
  types: AssignTypeProps[],
};

const AssignTypeSelector: FC<AssignTypeSelectorProps> = ({ types }) => (
  <div className="assign-type-selector">
    {
      types.map((props) => (
        <AssignType key={props.assignKey} {...props} />
      ))
    }
  </div>
);

export default AssignTypeSelector;
