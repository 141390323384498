import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import { ActionButton, Input, InputNumber, TreeSelect, Select, Button } from '@flexboxapps/flbx-webapp-ui';
import ProductPicture from 'components/Product/ProductPicture/ProductPicture';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { TaxRate } from 'reducers/taxrates/initialState';
import { OptionProps } from 'antd/lib/select';
import './product-editor.scss';
import { getSalePoints } from 'actions/salesActions';
import { getTaxrates } from 'actions/taxrateActions';

type ProductEditorProps = {
  categoryTree: any[],
  product: {
    titles: { hu: string, en: string },
    printTitles: { hu: string, en: string },
    price: {
      HUF: number,
    },
    categories: any[],
    image: any,
    takeaway?: string,
    dinein?: string,
    taxValueExceptions: any[],
  },
  openUploadModal: () => void,
  editProductField: (key: string, value: any) => void,
};

const ProductEditor: FC<ProductEditorProps> = ({
  categoryTree,
  product: { titles, printTitles, price, categories, image, takeaway, dinein, taxValueExceptions },
  openUploadModal, editProductField,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { taxrates } = useSelector((state: RootStateOrAny) => state.taxrate);
  const { salePoints } = useSelector((state: RootStateOrAny) => state.sales);

  if (!salePoints || !salePoints.length || !taxrates.length) {
    return null;
  }

  useEffect(() => {
    if (!salePoints || !salePoints.length || !taxrates.length) {
      dispatch(getSalePoints());
      dispatch(getTaxrates());
    }
  }, []);

  const [taxValueExceptionsCount, setTaxValueExceptionsCount] = useState(0);
  const exceptionSalePoints = taxValueExceptions.map((exception) => exception?.salepoint);
  const availableSalePoints = salePoints.map((item: TaxRate) => ({
    label: item.title,
    value: item._id,
  }))
    .map((salePointItem: OptionProps) => (!exceptionSalePoints.includes(salePointItem.value) ? salePointItem : {
      ...salePointItem,
      disabled: true,
    }));

  return (
    <div className="product-editor">
      <div className="product-image-container">
        <ProductPicture image={image} />
        <div className="product-image-actions">
          {
            image
              ? (
                <ActionButton
                  icon={<FontAwesomeIcon key="fa-trash-alt" icon={faTrashAlt} color="#e74c3c" />}
                  label="Kép törlése"
                  onClick={() => editProductField('image', null)}
                  sticky
                />
              )
              : (
                <ActionButton
                  icon={<FontAwesomeIcon key="fa-plus" icon={faPlus} color="#34495e" />}
                  label="Kép hozzáadása"
                  onClick={openUploadModal}
                  sticky
                />
              )
          }
        </div>
      </div>
      <div className="drawer-input-container">
        <Input
          label={t('pages.products.labels.titles.hu').toUpperCase()}
          placeholder={t('pages.products.inputs.titles.hu')}
          value={titles.hu}
          onChange={(e) => editProductField('titles', {
            ...titles,
            hu: e.target.value,
          })}
        />
        <Input
          label={t('pages.products.labels.printTitles.hu').toUpperCase()}
          placeholder={t('pages.products.inputs.printTitles.hu')}
          value={printTitles.hu}
          onChange={(e) => editProductField('printTitles', {
            ...printTitles,
            hu: e.target.value,
          })}
        />
        <InputNumber
          label={t('pages.products.inputs.price').toUpperCase()}
          value={price.HUF || 0}
          onChange={(value) => { editProductField('price', { HUF: value }); }}
          max={10000000}
          step={1}
          min={0}
        />
        <TreeSelect
          label={t('pages.products.inputs.selectedCategory').toUpperCase()}
          placeholder={t('pages.products.inputs.selectedCategory')}
          value={categories[0] || 'root'}
          treeData={[{
            title: t('drawers.addProduct.mainCategory'),
            value: 'root',
            children: categoryTree,
          }]}
          onChange={(value) => editProductField('categories', [value])}
          size="large"
        />
        <Select
          label={t('pages.products.inputs.takeaway')}
          placeholder={t('pages.products.inputs.taxratePlaceholder')}
          value={takeaway}
          options={taxrates.map((item: TaxRate) => ({
            label: item.title,
            value: item._id,
          }))}
          onChange={(value) => { editProductField('takeaway', value); }}
          size="large"
        />
        <Select
          label={t('pages.products.inputs.dinein')}
          placeholder={t('pages.products.inputs.taxratePlaceholder')}
          value={dinein}
          options={taxrates.map((item: TaxRate) => ({
            label: item.title,
            value: item._id,
          }))}
          onChange={(value) => editProductField('dinein', value)}
          size="large"
        />
        {
          [...Array(taxValueExceptionsCount)].map((_, index) => (
            <div className="exception-container" key={index.toString()}>
              <div className="exception-select-container">
                <Select
                  label={t('pages.products.inputs.salepoint')}
                  placeholder={t('pages.products.inputs.salepointPlaceholder')}
                  value={taxValueExceptions[index]?.salepoint}
                  options={availableSalePoints}
                  onChange={(value) => {
                    editProductField('taxValueExceptions', taxValueExceptions
                      .map((exception, exceptionIndex) => (index === exceptionIndex ? { ...exception, salepoint: value } : exception)));
                  }}
                  size="large"
                />
                <Select
                  label={t('pages.products.inputs.takeaway')}
                  placeholder={t('pages.products.inputs.taxratePlaceholder')}
                  value={taxValueExceptions[index]?.takeaway}
                  options={taxrates.map((item: TaxRate) => ({
                    label: item.title,
                    value: item._id,
                  }))}
                  onChange={(value) => {
                    editProductField('taxValueExceptions', taxValueExceptions
                      .map((exception, exceptionIndex) => (index === exceptionIndex ? { ...exception, takeaway: value } : exception)));
                  }}
                  size="large"
                />
                <Select
                  label={t('pages.products.inputs.dinein')}
                  placeholder={t('pages.products.inputs.taxratePlaceholder')}
                  value={taxValueExceptions[index]?.dinein}
                  options={taxrates.map((item: TaxRate) => ({
                    label: item.title,
                    value: item._id,
                  }))}
                  onChange={(value) => {
                    editProductField('taxValueExceptions', taxValueExceptions
                      .map((exception, exceptionIndex) => (index === exceptionIndex ? { ...exception, dinein: value } : exception)));
                  }}
                  size="large"
                />
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faMinusCircle}
                  onClick={() => {
                    setTaxValueExceptionsCount(taxValueExceptionsCount - 1);
                    editProductField('taxValueExceptions', taxValueExceptions.filter((exception, exceptionIndex) => index !== exceptionIndex));
                  }}
                />
              </div>
            </div>
          ))
        }
        <Button
          label={t('pages.products.buttons.addException')}
          onClick={() => {
            setTaxValueExceptionsCount(taxValueExceptionsCount + 1);
            editProductField('taxValueExceptions', [...Array(taxValueExceptionsCount + 1)].map((_, index) => taxValueExceptions[index]));
          }}
          type="ghost"
        />
        {
          salePoints && salePoints.length > taxValueExceptionsCount && (
            <Button
              label={t('pages.products.buttons.addException')}
              onClick={() => {
                setTaxValueExceptionsCount(taxValueExceptionsCount + 1);
                editProductField('taxValueExceptions', [...Array(taxValueExceptionsCount + 1)].map((_, index) => taxValueExceptions[index]));
              }}
              type="ghost"
            />
          )
        }
      </div>
    </div>
  );
};

export default ProductEditor;
